import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

class Dropdown extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			selected: this.props.initial ? this.props.initial : undefined
		};
	}

	showDropdown = () => {
		this.setState({
			open: true
		}, () => {
			document.addEventListener('click', this.hideDropdown);
		});
	}

	hideDropdown = () => {
		this.setState({
			open: false
		}, () => {
			document.removeEventListener('click', this.hideDropdown);
		});
	}

	handleOptionClick = option => {
		this.setState({
			selected: option
		});

		if (this.props.onChange) {
			this.props.onChange(option);
		}
	}

	render() {
		const { className, options, placeholder } = this.props;
		const { open, selected } = this.state;

		return (
			<div className={`${className} dropdown${open ? ' dropdown--open' : ''}`} onClick={this.showDropdown}>
				<div className="dropdown__selected">
					<div className="dropdown__text">{selected ? options.find(option => option.value === selected).name : placeholder}</div>
					<FontAwesomeIcon className="dropdown__icon" icon={open ? faAngleUp: faAngleDown} />
				</div>

				<div className="dropdown__options">
					{options.map(option => (
						<div key={option.value} className="dropdown__option" onClick={() => this.handleOptionClick(option.value)}>{option.name}</div>
					))}
				</div>
			</div>
		);
	}

}

// options[{value,name}], initial, placeholder, onChange

export default Dropdown;
