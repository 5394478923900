import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { reportExceptionToSentry } from '../utils/Utils';

class CategoryStore {

	loading = false;
	categories = [];
	allCategories = []; // just to make sure we always have all categories and not have them overwritten

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	async loadAllCategories() {
		this.loading = true;

		try {
			const response = await FazaaAPI.Categories.all();

			runInAction(() => {
				this.categories = response.data;
				this.allCategories = response.data;
			});
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async loadFeaturedCategories() {
		this.loading = true;

		try {
			const response = await FazaaAPI.Categories.featured();

			runInAction(() => {
				this.categories = response.data;
			});
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

}

decorate(CategoryStore, {
	loading: observable,
	categories: observable,
	allCategories: observable,
	loadAllCategories: action,
	loadFeaturedCategories: action
});

export default CategoryStore;
