import React, { PureComponent } from 'react';
import Localized from '../i18n/Localized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

class ProductRating extends PureComponent {

	render() {
		const { rating, reviewCount } = this.props;

		return (
			<div className="estore-page__product-rating">
				<div className="estore-page__product-rating-stars">
					<FontAwesomeIcon className={`estore-page__product-rating-star${rating > 0 ? ' estore-page__product-rating-star--active' : ''}`} icon={faStar} />
					<FontAwesomeIcon className={`estore-page__product-rating-star${rating > 1 ? ' estore-page__product-rating-star--active' : ''}`} icon={faStar} />
					<FontAwesomeIcon className={`estore-page__product-rating-star${rating > 2 ? ' estore-page__product-rating-star--active' : ''}`} icon={faStar} />
					<FontAwesomeIcon className={`estore-page__product-rating-star${rating > 3 ? ' estore-page__product-rating-star--active' : ''}`} icon={faStar} />
					<FontAwesomeIcon className={`estore-page__product-rating-star${rating > 4 ? ' estore-page__product-rating-star--active' : ''}`} icon={faStar} />
				</div>

				<p className="estore-page__product-review-count">
					<Localized code="estore.text.reviewCount" count={reviewCount} />
				</p>
			</div>
		);
	}

}

export default ProductRating;
