import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const ServiceLink = inject('rootStore')(observer(class ServiceLink extends Component {

	render() {
		const { locale } = this.props.rootStore.commonStore;
		// rootStore extracted to prevent it from being passed to the element as a prop!
		const { service, children, slugField, noBlank = false, fromSearch = false, ssoToken, rootStore, ...rest } = this.props;

		if (parseInt(service.serviceType) === 2) {
			// Use localized or fallback to legacy service link
			const serviceLink = fromSearch ? 
				(service.localizedServiceLink ? service.localizedServiceLink : service.serviceLink) :
				(service.localData[locale].localizedServiceLink ? service.localData[locale].localizedServiceLink : service.serviceLink);

			if (serviceLink.startsWith('http')) {
				return (
					<a href={serviceLink + (ssoToken ? '?token=' + ssoToken : '')} target={noBlank ? '_self' : '_blank' } rel="noopener noreferrer" {...rest}>
						{children}
					</a>
				);
			} else {
				return (
					<Link target={noBlank ? '_self' : '_blank' } to={serviceLink} {...rest}>
						{children}
					</Link>
				);
			}
		} else {
			if (service.serviceSubType && service.serviceSubType === 'CARS') {
				return (
					<Link target={noBlank ? '_self' : '_blank' } to={`/services/view/long-term-car-lease/${service[slugField]}`} {...rest}>
						{children}
					</Link>
				);
			} else {
				return (
					<Link target={noBlank ? '_self' : '_blank' } to={`/services/view/${service[slugField]}`} {...rest}>
						{children}
					</Link>
				);
			}
		}
	}

}));

ServiceLink.defaultProps = {
	slugField: 'serviceSlug'
};

export default ServiceLink;
