import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../base/i18n/Localized';
import { autorun } from 'mobx';

const UAEPASSUnlink = inject('rootStore')(observer(
	class UAEPASSunlink extends Component {

		constructor(props) {
			super(props);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.unlink.title');
			});
		}

		componentDidMount() {
      const { userStore } = this.props.rootStore;

			// Get params from URL and call API
      const { number, code } = this.props.match.params;

      userStore.unlinkMembership(number, code);
		}s

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		render() {
			const { userStore } = this.props.rootStore;
      const { success, error } = userStore;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.unlink.title" />
					</h2>

					<div className="section__wrapper">
						<div className="unlink">
							<div className="unlink__content">
                {!!success ? (
									<p className="form__text form__text--center" style={{ marginBottom: 0 }}>{success}</p>
								) : null}

                {!!error ? (
									<div className="form__message form__text--center form__message--error" style={{ marginBottom: 0 }}>{error}</div>
								) : null}
              </div>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default UAEPASSUnlink;
