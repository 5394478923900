import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Swiper from '@eredessil/react-id-swiper';
import HTTP404 from '../base/error/HTTP404';
import Config from '../../config/Config';
import Localized from '../base/i18n/Localized';
import Accordion from '../base/ui/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMap, faDownload } from '@fortawesome/free-solid-svg-icons';

import imgSliderIconArrowLeft from '../../assets/img/slider-icon-arrow-left.png'
import imgSliderIconArrowRight from '../../assets/img/slider-icon-arrow-right.png'

const UsedCar = inject('rootStore')(withRouter(observer(
	class UsedCar extends Component {

		constructor(props) {
			super(props);

			this.state = {
				usedCar: null
			};

			this.swiperRef = React.createRef();
		}

		componentDidMount() {
			const { commonStore, usedCarStore } = this.props.rootStore;
			const { locale, pageTitlePrefix } = commonStore;
			const { usedCarSlug } = this.props.match.params;

			usedCarStore.loadUsedCar(usedCarSlug).then(usedCar => {
				this.setState({ usedCar });

				if (usedCar) {
					document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + usedCar.localData[locale].name;
				} else {
					document.title = pageTitlePrefix;
				}
			});
		}

		render() {
			const { commonStore, usedCarStore } = this.props.rootStore;
			const { locale } = commonStore;
			const { loading } = usedCarStore;
			const { usedCar } = this.state;
			const swiperParams = {
				slidesPerView: 1,
				navigation: {
					prevEl: '.service-page__gallery-left',
					nextEl: '.service-page__gallery-right'
				},
				renderPrevButton: () => (
					<div className="service-page__gallery-left">
						<img className="service-page__gallery-arrow" src={imgSliderIconArrowLeft} alt="Slide left" />
					</div>
				),
  				renderNextButton: () => (
					<div className="service-page__gallery-right">
						<img className="service-page__gallery-arrow" src={imgSliderIconArrowRight} alt="Slide right" />
					</div>
				)
			};

			return (
				!loading ? (
					usedCar ? (
						<Fragment>
							<section className="section section--gray">
								<div className="section__wrapper">
									<div className="service-page">
										<div className="service-page__gallery">
											{usedCar.images.length > 0 ? (
												<Swiper {...swiperParams} ref={this.swiperRef} rebuildOnUpdate>
													{usedCar.images.map(image => (
														<div key={image.id} className="service-page__slide">
															<img className="service-page__image" src={`${Config.UPLOADS_ENDPOINT}${image.imageUri}`} alt="" />
														</div>
													))}
												</Swiper>
											) : (
												<img className="service-page__image" src={`${Config.UPLOADS_ENDPOINT}${usedCar.carImageUri}`} alt={usedCar.localData[locale].name} />
											)}
										</div>

										<div className="service-page__description">
											<h2 className="service-page__title">{usedCar.localData[locale].name}</h2>

											<p className="section__text">
												<span className="section__text-highlight section__text-highlight--blue">{new Intl.NumberFormat().format(usedCar.price)} AED</span>
												<br />
												<Localized code="page.usedCars.text.availability" />: <Localized code={`page.usedCars.text.availability.${usedCar.carStatus}`} />
												<br />
												<Localized code="page.usedCars.text.mileage" />: {new Intl.NumberFormat().format(usedCar.mileage)} km
											</p>

											<Accordion locale={locale} title={
												<Localized code="page.usedCars.text.featuresAndSpecification" />
											} content={
												<div className="cms-generated cms-generated--no-margin" dangerouslySetInnerHTML={{__html: usedCar.localData[locale].description}} />
											} />

											{false ? (
												<Accordion locale={locale} title={
													<Localized code="page.usedCars.text.specification" />
												} content={
													<div className="cms-generated cms-generated--no-margin" dangerouslySetInnerHTML={{__html: usedCar.localData[locale].features}} />
												} />
											) : null}

											<Accordion locale={locale} title={
												<Localized code="page.usedCars.text.warranty" />
											} content={
												<div className="cms-generated cms-generated--no-margin" dangerouslySetInnerHTML={{__html: usedCar.localData[locale].warranty}} />
											} />

											{usedCar.inspectionCertificate ? (
												<Accordion locale={locale} title={
													<Localized code="page.usedCars.text.inspectionCertificate" />
												} content={
													<Fragment>
														<p className="section__text"><Localized code="page.usedCars.text.inspectionCertificateText" /></p>

														<p className="section__text">
															<a className="button button--small button--blue button--icon" target="_blank" href={usedCar.inspectionCertificate}>
																<FontAwesomeIcon className="button__icon" icon={faDownload} />
																<Localized code="page.usedCars.text.downloadCertificate" />
															</a>
														</p>
													</Fragment>
												} />
											) : null}

											<Accordion locale={locale} title={
												<Localized code="page.usedCars.text.contact" />
											} content={
												<Fragment>
													<div className="cms-generated" dangerouslySetInnerHTML={{__html: usedCar.localData[locale].contact}} />

													<p className="section__text">
														<a className="button button--small button--blue button--icon" href={`tel:${usedCar.phone}`}>
															<FontAwesomeIcon className="button__icon" icon={faPhone} />
															{usedCar.phone}
														</a>
													</p>

													<p className="section__text">
														<a className="button button--small button--blue button--icon" target="_blank" href={usedCar.location}>
															<FontAwesomeIcon className="button__icon" icon={faMap} />
															<Localized code="page.usedCars.text.openMap" />
														</a>
													</p>
												</Fragment>
											} />
										</div>
									</div>
								</div>
							</section>
						</Fragment>
					) : (
						<HTTP404 />
					)
				) : null
			);
		}

	}
)));

export default UsedCar;
