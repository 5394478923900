import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { reportExceptionToSentry } from '../utils/Utils';

class VoucherStore {

	loading = false;
	vouchers = new Map();
	loadingPackage = false;
	voucherPackages = new Map();

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	setLoading(loading) {
		this.loading = loading;
	}

	setLoadingPackage(loadingPackage) {
		this.loadingPackage = loadingPackage;
	}

	async loadAllVouchers() {
		this.loading = true;

		this.vouchers.clear();

		try {
			const response = await FazaaAPI.Vouchers.all();

			runInAction(() => {
				response.data.forEach(voucher => this.vouchers.set(voucher.voucherSlug, voucher));
			});
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async loadAllVoucherPackages() {
		this.loadingPackage = true;

		this.voucherPackages.clear();

		try {
			const response = await FazaaAPI.Vouchers.packages();

			runInAction(() => {
				response.data.forEach(voucherPackage => this.voucherPackages.set(voucherPackage.voucherPackage.packageSlug, voucherPackage));
			});
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			runInAction(() => {
				this.loadingPackage = false;
			});
		}
	}

	async loadFeaturedVouchers() {
		this.loading = true;

		this.vouchers.clear();

		try {
			const response = await FazaaAPI.Vouchers.featured();

			runInAction(() => {
				response.data.forEach(voucher => this.vouchers.set(voucher.voucherSlug, voucher));
			});
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async loadVoucher(slug) {
		if (this.vouchers.has(slug)) {
			//this.loading = false;

			//return;
		}

		this.loading = true;

		try {
			const response = await FazaaAPI.Vouchers.get(slug);
			const voucher = response.data;

			runInAction(() => {
				this.vouchers.set(voucher.voucherSlug, voucher);
			});
		} catch (e) {
			reportExceptionToSentry(e, [404]);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async loadVoucherPackage(slug) {
		if (this.voucherPackages.has(slug)) {
			//this.loadingPackage = false;

			//return;
		}

		this.loadingPackage = true;

		try {
			const response = await FazaaAPI.Vouchers.package(slug);
			const voucherPackage = response.data;

			runInAction(() => {
				this.voucherPackages.set(voucherPackage.voucherPackage.packageSlug, voucherPackage);
			});
		} catch (e) {
			reportExceptionToSentry(e, [404]);
		} finally {
			runInAction(() => {
				this.loadingPackage = false;
			});
		}
	}

	async loadLimits(id) {
		try {
			const response = await FazaaAPI.Vouchers.limits(id);

			return response.data;
		} catch (e) {
			reportExceptionToSentry(e);
		}
	}

	getVoucher(slug) {
		return this.vouchers.get(slug);
	}

	getVoucherPackage(slug) {
		return this.voucherPackages.get(slug);
	}

	setVoucher(slug, voucher) {
		this.vouchers.set(slug, voucher);
	}

	setVoucherPackage(slug, voucherPackage) {
		this.voucherPackages.set(slug, voucherPackage);
	}

	clearVouchers() {
		this.vouchers.clear();
	}

	clearVoucherPackages() {
		this.voucherPackages.clear();
	}

}

decorate(VoucherStore, {
	loading: observable,
	loadingPackage: observable,
	vouchers: observable,
	voucherPackages: observable,
	setLoading: action,
	setLoadingPackage: action,
	loadAllVouchers: action,
	loadAllVoucherPackages: action,
	loadFeaturedVouchers: action,
	loadVoucher: action,
	loadVoucherPackage: action,
	loadLimits: action,
	setVoucher: action,
	setVoucherPackage: action,
	clearVouchers: action,
	clearVoucherPackages: action
});

export default VoucherStore;
