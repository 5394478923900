import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../base/i18n/Localized';
import { autorun, runInAction } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import CreditCardInput from 'react-credit-card-input';
import { Link } from 'react-router-dom';
import Config from '../../config/Config';
import FormError from '../base/form/FormError';
import moment from 'moment-timezone';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';

moment.tz.setDefault('Asia/Dubai');

//const VALID_EMAIL = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
const VALID_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Cart = inject('rootStore')(observer(
	class Cart extends Component {

		constructor(props) {
			super(props);

			this.cartRef = React.createRef();

			this.checkoutInProgress = false;

			this.getImage = this.getImage.bind(this);
			this.getName = this.getName.bind(this);
			this.getPrice = this.getPrice.bind(this);
			this.getSubtotal = this.getSubtotal.bind(this);
			this.handleQtyChange = this.handleQtyChange.bind(this);
			this.handleRemoveClick = this.handleRemoveClick.bind(this);
			this.handleEmptyCart = this.handleEmptyCart.bind(this);
			this.handleCheckoutClick = this.handleCheckoutClick.bind(this);
			this.scrollToTop = this.scrollToTop.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.cart.title');
			});
		}

		componentDidMount() {
			const { userStore, cartStore } = this.props.rootStore;

			cartStore.clearForm();

			userStore.loadCountries().then(result => {
				if (!result) {
					cartStore.setError('Unable to get list of countries/nationalities, please reload the page and try again.');
				}
			});

			if (userStore.authenticated) {
				userStore.loadLocations().then(result => {
					if (!result) {
						cartStore.setError('Unable to get list of locations, please reload the page and try again.');
					}
				});
			}

			if (userStore.authenticated) {
				userStore.getLastTransactionDetails().then(details => {
					if (details) {
						cartStore.setEmail(details.billingEmail);
						cartStore.setFirstName(details.billingFirstName);
						cartStore.setLastName(details.billingLastName);
						cartStore.setAddress(details.billingAddress);
						cartStore.setCity(details.billingCity);
						cartStore.setCountry(details.billingCountry);
						// cartStore.setPostalCode(details.billingPostalCode);
						cartStore.setPostalCode('00000');
					}
				});
			}
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		getImage(item) {
			switch (item.type) {
				case 'VOUCHER':
					return `${Config.UPLOADS_ENDPOINT}${item.product.voucherImageUri}`;
				case 'VOUCHER_PACKAGE':
					return `${Config.UPLOADS_ENDPOINT}${item.product.packageImage}`;
				case 'PRODUCT':
					return `${Config.UPLOADS_ENDPOINT}${item.product.productMainImageUri}`;
				default:
					return '';
			}
		}

		getName(item) {
			const { commonStore } = this.props.rootStore;
			const { locale } = commonStore;

			switch (item.type) {
				case 'VOUCHER':
					return item.product.localData[locale].title;
				case 'VOUCHER_PACKAGE':
					return item.product.localData[locale].name;
				case 'PRODUCT':
					return item.product.localData[locale].title;
				default:
					return '';
			}
		}

		getPrice(item) {
			switch (item.type) {
				case 'VOUCHER':
					return item.product.voucherPrice;
				case 'VOUCHER_PACKAGE':
					return item.product.packagePrice;
				case 'PRODUCT':
					return Math.min(item.product.productOriginalPrice, item.product.productDiscountPrice);
				default:
					return 0;
			}
		}

		getStock(item) {
			switch (item.type) {
				case 'VOUCHER':
					return item.product.stockAvailable;
				case 'VOUCHER_PACKAGE':
					let maxQty = item.product.limitNumber - (item.bought ? item.bought : 0);

					for (let i = 1; i <= maxQty; i++) {
						let voucherOutOfStock = false;

						voucherOutOfStock = item.vouchers.reduce((a, v) => a || (v.stockAvailable < item.voucherList.find(vl => vl.voucherId === v.id).voucherQty * i), false);

						if (voucherOutOfStock) {
							maxQty = i - 1;

							break;
						}
					}

					return maxQty;
				case 'PRODUCT':
					return item.product.productStock;
				default:
					return 0;
			}
		}

		getSubtotal(item) {
			switch (item.type) {
				case 'VOUCHER':
					return item.product.voucherPrice * item.qty;
				case 'VOUCHER_PACKAGE':
					return item.product.packagePrice * item.qty;
				case 'PRODUCT':
					return Config.PRODUCT_CREDIT_CARD_FEE * ((Math.min(item.product.productOriginalPrice, item.product.productDiscountPrice) * item.qty) + (item.product.productShippingCost * item.qty));
				default:
					return 0;
			}
		}

		handleQtyChange(e, item) {
			const { cartStore } = this.props.rootStore;
			let stockAvailable;

			if (item.type === 'VOUCHER') {
				stockAvailable = item.product.stockAvailable;
			}

			if (item.type === 'VOUCHER_PACKAGE') {
				stockAvailable = item.product.limitNumber - (item.bouught ? item.bought : 0);
			}

			if (item.type === 'PRODUCT') {
				stockAvailable = item.product.productStock;
			}

			if (e.target.value !== '' && e.target.validity.valid && e.target.value <= stockAvailable) {
				cartStore.updateCart(item.product.id, e.target.value);
			} else {
				cartStore.updateCart(item.product.id, 1);
			}
		}

		handleRemoveClick(id) {
			const { cartStore } = this.props.rootStore;

			cartStore.removeFromCart(id);
		}

		handleFirstNameChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.cartStore.setFirstName(e.target.value);
			}
		}
		handleLastNameChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.cartStore.setLastName(e.target.value);
			}
		}
		handleAddressChange = e => {
			if (new RegExp('^[a-zA-Z\\d\\-\\s/\\.]*$').test(e.target.value)) {
				this.props.rootStore.cartStore.setAddress(e.target.value);
			}
		}
		handlePostalCodeChange = e => {
			if (new RegExp('^[a-zA-Z\\d\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.cartStore.setPostalCode(e.target.value);
			}
		}
		handleCityChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.cartStore.setCity(e.target.value);
			}
		}
		handleCountryChange = e => this.props.rootStore.cartStore.setCountry(e.target.value);
		handleEmailChange = e => this.props.rootStore.cartStore.setEmail(e.target.value);
		handleCcNumberChange = e => this.props.rootStore.cartStore.setCcNumber(e.target.value);
		handleCcExpiryChange = e => this.props.rootStore.cartStore.setCcExpiry(e.target.value);
		handleCcCvvChange = e => this.props.rootStore.cartStore.setCcCvv(e.target.value);

		handleEmptyCart(e) {
			e.preventDefault();

			const { cartStore } = this.props.rootStore;

			cartStore.clearCart();

			this.scrollToTop();
		}

		handleShippingLocationChange = e => {
			const id = e.target.value;
			const { userStore, cartStore } = this.props.rootStore;

			userStore.clearLocationForm();

			if (id) {
				cartStore.setShippingLocationId(id);
			} else {
				cartStore.setShippingLocationId('');
			}
		}

		handleSetLocationDefault = e => {
			e.preventDefault();

			const { userStore, cartStore: { shippingLocationId } } = this.props.rootStore;

			userStore.markLocationDefault(shippingLocationId).then(() => {
				userStore.loadLocations();
			});
		}

		handleLocationFullNameChange = e => {
			runInAction(() => {
				this.props.rootStore.userStore.shippingLocations.fullName = e.target.value;
			});
		}

		handleLocationAddressChange = e => {
			runInAction(() => {
				this.props.rootStore.userStore.shippingLocations.address = e.target.value;
			});
		}

		handleLocationCityChange = e => {
			runInAction(() => {
				this.props.rootStore.userStore.shippingLocations.city = e.target.value;
			});
		}

		handleLocationMobileChange = e => {
			if (e.target.validity.valid) {
				runInAction(() => {
					this.props.rootStore.userStore.shippingLocations.mobile = e.target.value;
				});
			}
		}

		handleDeleteLocation = e => {
			e.preventDefault();
			
			const { userStore, cartStore: { shippingLocationId } } = this.props.rootStore;

			userStore.markLocationDeleted(shippingLocationId).then(() => {
				userStore.loadLocations();
			});
		}

		async handleCheckoutClick(e) {
			e.preventDefault();

			if (this.checkoutInProgress) return;

			const { commonStore, userStore, cartStore } = this.props.rootStore;
			const { authenticated } = userStore;
			const { cart, total, email } = cartStore;
			const { translateMessage } = commonStore;

			if (authenticated) {
				this.checkoutInProgress = true;

				cartStore.clearErrors();

				// Don't check valid e-mail when total is 0
				if (total > 0 && !VALID_EMAIL.test(email)) {
					cartStore.addError('email', translateMessage('generic.error.invalidEmail'));
				}

				if (cartStore.errors.length === 0) {
					// Add shipping location if needed
					if (cart.find(i => i.type === 'PRODUCT') && !cartStore.shippingLocationId) {
						const shippingLocationId = await userStore.addLocation();

						cartStore.setShippingLocationId(shippingLocationId);

						userStore.loadLocations();
					}

					if (await cartStore.verifyCart()) {
						await cartStore.doCheckout();
					} else {
						cartStore.setError(translateMessage('page.cart.error.someItemsRemoved'));
					}
				} else {
					cartStore.setError(translateMessage('generic.error.checkFields'));
				}

				this.scrollToTop();

				this.checkoutInProgress = false;
			} else {
				userStore.setRedirectTo('/cart');

				this.props.history.push('/account/signin');
			}
		}

		scrollToTop() {
			if (this.cartRef.current) {
				requestAnimationFrame(() => {
					this.cartRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				});
			}
		}

		render() {
			const { commonStore, userStore, cartStore } = this.props.rootStore;
			const { locale, translateMessage } = commonStore;
			const { authenticated, countries, me, shippingLocations } = userStore;
			const { loading } = cartStore;
			const { count, total, totalShipping, totalProductCreditCardFee: totalFee, cart, firstName, lastName, address, postalCode, city, country, email, ccNumber, ccExpiry, ccCvv, errors, error, success, shippingLocationId } = cartStore;
			const subscriptionExpired = me && moment(me.subscriptionExpireDate).isBefore();
			const productCheckoutDisabled = authenticated && !shippingLocationId && (!shippingLocations.fullName || !shippingLocations.address || !shippingLocations.city || !shippingLocations.mobile) && cart.find(i => i.type === 'PRODUCT');
			let isDefaultLocation = false;
			const selectedLocation = shippingLocations.locations.find(l => l.id === shippingLocationId);

			if (selectedLocation && selectedLocation.defaultAddress) {
				isDefaultLocation = true;
			}

			return (
				<section ref={this.cartRef} className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.cart.title" />
					</h2>

					<div className="section__wrapper">
						<div className="cart">
							{!!error ? (
								<div className="form__message form__message--error">{error}</div>
							) : null}

							{success ? (
								<div className="form__section">
									<h4 className="form__title">
										<Localized code="page.cart.subtitle.success" />
									</h4>

									<p className="form__text">
										<Localized code="page.cart.text.success" />
									</p>

									<p className="form__text">
										<Link to="/account/orders" className="button button--gold">
											<Localized code="page.cart.link.orders" />
										</Link>
									</p>
								</div>
							) : (
								count > 0 ? (
									<div className="cart__contents">
										<div className="cart__listing">
											<div className="cart__header">
												<div className="cart__header-product">
													<Localized code="page.cart.text.product" />
												</div>

												<div className="cart__header-price">
													<Localized code="page.cart.text.price" />
												</div>

												<div className="cart__header-qty">
													<Localized code="page.cart.text.qty" />
												</div>

												<div className="cart__header-subtotal">
													<Localized code="page.cart.text.subtotal" />
												</div>
											</div>

											{cart.map((i, index) => (
												<div key={index} className="cart__row">
													<div className="cart__image-wrapper">
														<img className="cart__image" src={this.getImage(i)} alt={this.getName(i)} />
													</div>

													<div className="cart__description">
														{i.type === 'VOUCHER' ? i.product.localData[locale].subTitle : null}
														{i.type === 'VOUCHER_PACKAGE' ? i.product.localData[locale].name : null}
														{i.type === 'PRODUCT' ? i.product.localData[locale].title : null}
													</div>

													<div className="cart__price">
														{this.getPrice(i)} AED
													</div>

													<div className="cart__qty">
														<input className="cart__qty-input" pattern="[1-9]|10" type="number" min="1" step={1} max={Math.min(this.getStock(i), Config.MAX_CART_ITEMS_ADD)} value={i.qty} onChange={e => this.handleQtyChange(e, i)} />
													</div>

													<div className="cart__subtotal">
														{Number(this.getSubtotal(i)).toFixed(2)} AED
													</div>

													<div className="cart__remove">
														<FontAwesomeIcon className="cart__remove-icon" icon={faTrashAlt} onClick={() => this.handleRemoveClick(i.product.id)} />
													</div>
												</div>
											))}
										</div>

										<div className="cart__total">
											{cart.find(i => i.type === 'PRODUCT') ? (
												<Fragment>
													<p className="cart__total-shipping">
														<Localized code="page.cart.text.totalShipping" total={Number(totalShipping).toFixed(2)} currency="AED" />
													</p>
													{false ? <p className="cart__total-fee">
														<Localized code="page.cart.text.totalFee" total={Number(totalFee).toFixed(2)} currency="AED" />
													</p> : null}
												</Fragment>
											) : null}
											<p className="cart__total-amount">
												<Localized code="page.cart.text.total" total={Number(total).toFixed(2)} currency="AED" />
											</p>
										</div>

										{authenticated && !subscriptionExpired && cart.find(i => i.type === 'PRODUCT') ? (
											<div className="cart__checkout">
												<h3 className="section__subtitle">
													<Localized code="page.cart.subtitle.shippingDetails" />
												</h3>

												{!shippingLocations.loading ? (
													<div className="form__row">
														<div className="form__column">
															<div className="form__group">
																<label className="form__label" htmlFor="shippingLocationId">
																	<Localized code="page.cart.label.shippingLocation" />
																</label>
																<div className="form__select-wrap">
																	<select className="form__select" id="shippingLocationId" value={shippingLocationId} onChange={this.handleShippingLocationChange}>
																		<option value="">{translateMessage('page.cart.label.newLocation')}</option>
																		{shippingLocations.locations.map(l => (
																			<option key={l.id} value={l.id}>{l.defaultAddress ? '* ' : ''}{l.fullName}, {l.address}, {l.city}, {l.mobile}</option>
																		))}
																	</select>
																</div>
															</div>
														</div>

														{shippingLocationId ? (
															<Fragment>
																{!isDefaultLocation ? (
																	<div className="form__column form__column--quarter form__column--button-only">
																		<button disabled={!shippingLocationId} className="form__button form__button--small form__button--blue" type="button" onClick={this.handleSetLocationDefault}>
																			<Localized code="page.cart.button.setLocationDefault" />
																		</button>
																	</div>
																): null}

																<div className="form__column form__column--quarter form__column--button-only">
																	<button disabled={!shippingLocationId} className="form__button form__button--small form__button--blue" type="button" onClick={this.handleDeleteLocation}>
																		<Localized code="page.cart.button.deleteLocation" />
																	</button>
																</div>
															</Fragment>
														) : null}
													</div>
												) : null}

												{!shippingLocationId ? (
													<Fragment>
														<div className="form__row">
															<div className="form__column">
																<div className="form__group">
																	<label className="form__label" htmlFor="locationFullName">
																		<Localized code="page.cart.label.locationFullName" />
																	</label>
																	<input className="form__input" type="text" id="locationFullName" value={shippingLocations.fullName} onChange={this.handleLocationFullNameChange} />
																</div>
															</div>

															<div className="form__column">
																<div className="form__group">
																	<label className="form__label" htmlFor="locationAddress">
																		<Localized code="page.cart.label.locationAddress" />
																	</label>
																	<input className="form__input" type="text" id="locationAddress" value={shippingLocations.address} onChange={this.handleLocationAddressChange} />
																</div>
															</div>
														</div>

														<div className="form__row">
															<div className="form__column">
																<div className="form__group">
																	<label className="form__label" htmlFor="locationCity">
																		<Localized code="page.cart.label.locationCity" />
																	</label>
																	<input className="form__input" type="text" id="locationCity" value={shippingLocations.city} onChange={this.handleLocationCityChange} />
																</div>
															</div>

															<div className="form__column">
																<div className="form__group">
																	<label className="form__label" htmlFor="locationMobile">
																		<Localized code="page.cart.label.locationMobile" />
																	</label>
																	<InputMask dir="ltr" className="form__input" type="text" id="locationMobile" value={shippingLocations.mobile} onChange={this.handleLocationMobileChange} mask="+\971 5a 999 9999" formatChars={{9: '[0-9]', a: '[0|2|4|5|6|8]'}} />
																</div>
															</div>
														</div>
													</Fragment>
												) : null}
											</div>
										) : null}

										{authenticated && !subscriptionExpired && total > 0 ? (
											<div className="cart__checkout">
												<h3 className="section__subtitle">
													<Localized code="page.cart.subtitle.billingDetails" />
												</h3>

												<div className="form__row">
													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="address">
																<Localized code="page.cart.label.address" />
															</label>
															<input className="form__input" type="text" id="address" value={address} onChange={this.handleAddressChange} />
														</div>
													</div>

													{false ? (
														<div className="form__column form__column--quarter">
															<div className="form__group">
																<label className="form__label" htmlFor="postalCode">
																	<Localized code="page.cart.label.postalCode" />
																</label>
																<input className="form__input" type="text" id="postalCode" value={postalCode} onChange={this.handlePostalCodeChange} />
															</div>
														</div>
													) : null}
												</div>

												<div className="form__row">
													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="country">
																<Localized code="page.cart.label.country" />
															</label>
															<div className="form__select-wrap">
																<select className="form__select" id="country" value={country} onChange={this.handleCountryChange}>
																	<option value="">{translateMessage('generic.label.pleaseSelect')}</option>
																	{countries.map(country => (
																		<option key={country.countryCode2c} value={country.countryCode2c}>{locale === 'ar' ? country.countryNameAr : country.countryName}</option>
																	))}
																</select>
															</div>
														</div>
													</div>

													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="city">
																<Localized code="page.cart.label.city" />
															</label>
															<input className="form__input" type="text" id="city" value={city} onChange={this.handleCityChange} />
														</div>
													</div>

													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="email">
																<Localized code="page.cart.label.email" />
															</label>
															<input className="form__input" type="email" id="email" value={email} onChange={this.handleEmailChange} />
															<FormError errors={errors} field="email" />
														</div>
													</div>
												</div>

												<h3 className="section__subtitle">
													<Localized code="page.cart.subtitle.paymentDetails" />
												</h3>

												<div className="form__row">
													<div className="form__column form__column--quarter">
														<div className="form__group">
															<label className="form__label" htmlFor="firstName">
																<Localized code="page.cart.label.firstName" />
															</label>
															<input className="form__input" type="text" id="firstName" value={firstName} onChange={this.handleFirstNameChange} />
														</div>
													</div>

													<div className="form__column form__column--quarter">
														<div className="form__group">
															<label className="form__label" htmlFor="lastName">
																<Localized code="page.cart.label.lastName" />
															</label>
															<input className="form__input" type="text" id="lastName" value={lastName} onChange={this.handleLastNameChange} />
														</div>
													</div>

													<div className="form__column">
														<div className="form__group">
															<label className="form__label">
																<Localized code="page.signup.label.ccDetails" />
															</label>
															<CreditCardInput
																containerClassName="form__cc-container"
																fieldClassName="form__cc-field"
																inputClassName="form__cc-input"
																dangerTextClassName="form__field-error"
																cardNumberInputProps={{ value: ccNumber, onChange: this.handleCcNumberChange }}
																cardExpiryInputProps={{ value: ccExpiry, onChange: this.handleCcExpiryChange }}
																cardCVCInputProps={{ value: ccCvv, onChange: this.handleCcCvvChange }}
															/>
														</div>
													</div>
												</div>
											</div>
										) : null }

										{subscriptionExpired ? (
											<div className="cart__sub-expired">
												<p className="cart__sub-expired-icon">
													<FontAwesomeIcon icon={faExclamationTriangle} size="5x" />
												</p>

												<p className="cart__sub-expired-text">
													<Localized code="page.cart.text.subExpired" />
												</p>

												<p className="cart__sub-expired-text">
													<Link to="/account/renew" className="button button--small button--gold">
														<Localized code="page.cart.link.renew" />
													</Link>
												</p>
											</div>
										) : null}

										<div className="cart__controls">
											<button className="button button--blue" onClick={this.handleEmptyCart}>
												<Localized code="page.cart.button.emptyCart" />
											</button>

											<button disabled={loading || (authenticated && (total > 0 && (!firstName || !lastName || !address || !city || !country || !email || !ccNumber || !ccExpiry || !ccCvv))) || productCheckoutDisabled || shippingLocations.working} className="button button--gold button--has-loading" onClick={this.handleCheckoutClick}>
												{loading ? (
													<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
												) : null}
												<Localized code="page.cart.button.checkout" />
											</button>
										</div>
									</div>
								) : (
									<div className="cart__empty">
										<p className="cart__empty-icon">
											<FontAwesomeIcon icon={faTimesCircle} size="5x" />
										</p>

										<p className="cart__empty-text">
											<Localized code="page.cart.text.currentlyEmpty" __links={{
												link1: <Link className="cart__empty-link" to={`/search?language=${locale}&types=VOUCHER`} />
											}} />
										</p>
									</div>
								)
							)}
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Cart;
