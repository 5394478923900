import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CategoriesStrip from '../base/categories/CategoriesStrip';
import Swiper from '@eredessil/react-id-swiper';
import GoogleMap from '../base/map/GoogleMap';
import HTTP404 from '../base/error/HTTP404';
import Config from '../../config/Config';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Localized from '../base/i18n/Localized';
import { autorun } from 'mobx';
import FormCheckboxButton from '../base/form/FormChecboxButton';

import imgSliderIconArrowLeft from '../../assets/img/slider-icon-arrow-left.png'
import imgSliderIconArrowRight from '../../assets/img/slider-icon-arrow-right.png'

moment.tz.setDefault('Asia/Dubai');

const Voucher = inject('rootStore')(observer(
	class Voucher extends Component {

		constructor(props) {
			super(props);

			this.state = {
				qty: 1,
				terms: false
			};

			const { voucherStore } = props.rootStore;

			voucherStore.setLoading(true);

			this.swiperRef = React.createRef();

			this.handleQtyChange = this.handleQtyChange.bind(this);
			this.handleAddToCart = this.handleAddToCart.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { commonStore, voucherStore } = props.rootStore;
				const { locale, pageTitlePrefix } = commonStore;
				const { slug } = this.props.match.params;
				const voucher = voucherStore.getVoucher(slug);

				if (voucher) {
					document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + voucher.partner.partnerName + ' - ' + voucher.localData[locale].title;
				} else {
					document.title = pageTitlePrefix;
				}
			});
		}

		componentDidMount() {
			const { slug } = this.props.match.params;

			this.props.rootStore.voucherStore.loadVoucher(slug);
		}
		
		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}
		
		handleQtyChange(e) {
			const { voucherStore } = this.props.rootStore;
			const { slug } = this.props.match.params;
			const voucher = voucherStore.getVoucher(slug);

			if (e.target.value !== '' && e.target.validity.valid && e.target.value <= voucher.stockAvailable) {
				this.setState({
					qty: e.target.value
				});
			} else {
				this.setState({
					qty: 1
				});
			}
		}

		handleAddToCart(e) {
			e.preventDefault();

			const { cartStore, voucherStore, commonStore: { translateMessage } } = this.props.rootStore;

			if (cartStore.added) {
				return;
			}

			const { slug } = this.props.match.params;
			const voucher = voucherStore.getVoucher(slug);

			if (cartStore.addToCart({
					type: 'VOUCHER',
					product: voucher,
					qty: parseInt(this.state.qty)
				})
			) {
				document.documentElement.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			} else {
				alert(translateMessage('page.cart.text.addMax', {
					amount: Config.MAX_CART_ITEMS_ADD
				}));
			}
		}

		handleTermsChange = e => this.setState({
			terms: e.target.checked
		});

		render() {
			const { locale, translateMessage } = this.props.rootStore.commonStore;
			const { voucherStore, cartStore } = this.props.rootStore;
			const { loading } = voucherStore;
			const { added, cart } = cartStore;
			const { slug } = this.props.match.params;
			const voucher = voucherStore.getVoucher(slug);
			const swiperParams = {
				slidesPerView: 1,
				navigation: {
					prevEl: '.voucher-page__gallery-left',
					nextEl: '.voucher-page__gallery-right'
				},
				renderPrevButton: () => (
					<div className="voucher-page__gallery-left">
						<img className="voucher-page__gallery-arrow" src={imgSliderIconArrowLeft} alt="Slide left" />
					</div>
				),
  				renderNextButton: () => (
					<div className="voucher-page__gallery-right">
						<img className="voucher-page__gallery-arrow" src={imgSliderIconArrowRight} alt="Slide right" />
					</div>
				)
			};
			const addDisabled = voucher ? moment(voucher.voucherOfferExpiry).isBefore() : true;
			const inCart = voucher ? cart.filter(i => i.type === 'VOUCHER' && i.product.id === voucher.id).reduce((a, i) => a + i.qty, 0) : 0;

			return (
				!loading ? (
					voucher ? (
						<section className="section section--gray">
							<div className="section__wrapper">
								<div className="voucher-page">
									<div className="voucher-page__main">
										<div className="voucher-page__gallery">
											{voucher.images.length > 0 ? (
												<Swiper {...swiperParams} ref={this.swiperRef} rebuildOnUpdate>
													{voucher.images.map(image => (
														<div key={image.id} className="voucher-page__slide">
															<img className="voucher-page__image" src={`${Config.UPLOADS_ENDPOINT}${image.imageUri}`} alt="" />
														</div>
													))}
												</Swiper>
											) : (
												<img className="voucher-page__image" src={`${Config.UPLOADS_ENDPOINT}${voucher.voucherImageUri}`} alt={voucher.localData[locale].title} />
											)}
										</div>

										<div className="voucher-page__description">
											<CategoriesStrip categories={voucher.categories} />

											<h3 className="voucher-page__partner">{voucher.partner.partnerName}</h3>

											<h2 className="voucher-page__title">{voucher.localData[locale].title}</h2>

											<p className="voucher-page__save">
												<Localized code="page.voucher.text.save" amount={voucher.voucherPrice - voucher.voucherDiscountPrice} currency="AED" />
											</p>

											<div className="voucher-page__description-text cms-generated" dangerouslySetInnerHTML={{__html: voucher.localData[locale].shortDescription}} />

											<p className="voucher-page__expiry">
												<Localized code="page.voucher.text.availableUntil" date={moment(voucher.voucherOfferExpiry).format('DD/MM/YY')} />
											</p>

											<p className="voucher-page__product">
												{voucher.localData[locale].subTitle}
											</p>

											{inCart ? (
												<p className="voucher-page__in-cart">
													<Localized code="generic.text.inCart" inCart={inCart} />
												</p>
											) : null}

											<div className="voucher-page__pricing">
												<div className="voucher-page__pricing-price">
													{voucher.voucherPrice} AED
												</div>

												<div className="voucher-page__add">
													{voucher.stockAvailable > 0 ? (
														<div className="voucher-page__qty-select-wrap">
															<select className="voucher-page__qty-select" value={this.state.qty} onChange={this.handleQtyChange}>
																{Array.from(Array(Math.min(voucher.stockAvailable, Config.MAX_CART_ITEMS_ADD)), (e, i) => {
																	return <option key={i} value={i + 1}>{i + 1}</option>
																})}
															</select>
														</div>
													) : null}

													<button disabled={addDisabled || voucher.stockAvailable - inCart <= 0 || !this.state.terms} className="button button--gold" onClick={this.handleAddToCart}>
														{!addDisabled ? (
															added && false ? (
																<Fragment>
																	<FontAwesomeIcon className="button__icon" icon={faCheck} />
																	<Localized code="page.voucher.button.addedToCart" />
																</Fragment>
															) : (
																voucher.stockAvailable - inCart <= 0 ? (
																	<Localized code="page.voucher.button.outOfStock" />
																) : (
																	<Localized code="page.voucher.button.addToCart" />
																)
															)
														) : (
															<Localized code="page.voucher.button.expired" />
														)}
													</button>
												</div>
											</div>

											<div className="voucher-page__terms-agree">
												<FormCheckboxButton name="terms" value={true} label={translateMessage('page.voucher.label.acceptTerms')} checked={this.state.terms} onChange={this.handleTermsChange} />
											</div>
										</div>
									</div>

									<div className="voucher-page__extended">
										<Tabs>
											<TabList>
												<Tab>
													<Localized code="page.voucher.tab.description" />
												</Tab>
												<Tab>
													<Localized code="page.voucher.tab.locations" />
												</Tab>
											</TabList>

											<TabPanel>
												<div className="cms-generated" dangerouslySetInnerHTML={{__html: voucher.localData[locale].description}} />
											</TabPanel>

											<TabPanel className="react-tabs__tab-panel react-tabs__tab-panel--map">
												{voucher.locations.length > 0 ? (
												<div className="voucher-page__map">
													<GoogleMap locations={voucher.locations} locale={locale} />
												</div>
												) : (
												<p className="voucher-page__no-locations">
													<Localized code="page.voucher.text.noLocations" />
												</p>
												)}
											</TabPanel>
										</Tabs>
									</div>
								</div>
							</div>
						</section>
					) : (
						<HTTP404 />
					)
				) : null
			);
		}

	}
));

export default Voucher;
