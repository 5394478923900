const DaysOfWeek = [
	'MONDAY',
	'TUESDAY',
	'WEDNESDAY',
	'THURSDAY',
	'FRIDAY',
	'SATURDAY',
	'SUNDAY'
];

Object.freeze(DaysOfWeek);

export default DaysOfWeek;
