import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const PASSWORD_RULE_LOWERCASE = /(?=.*[a-z])/;
const PASSWORD_RULE_UPPERCASE = /(?=.*[A-Z])/;
const PASSWORD_RULE_NUMBER = /(?=.*[0-9])/;
const PASSWORD_RULE_SPECIAL = /(?=.*[^A-Za-z0-9])/;
const PASSWORD_RULE_LENGTH = /(?=.{8,})/;

const Reset = inject('rootStore')(observer(
	class Forgot extends Component {

		constructor(props) {
			super(props);

			this.state = {
				passwordMismatch: false,
				passwordRuleLowercase: false,
				passwordRuleUppercase: false,
				passwordRuleNumber: false,
				passwordRuleSpecial: false,
				passwordRuleLength: false
			};

			this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.reset.title');
			});
		}

		componentDidMount() {
			const { userStore } = this.props.rootStore;
			const { token } = this.props.match.params;

			if (userStore.authenticated) {
				this.props.history.replace('/');
			}

			userStore.setPasswordResetToken(token);
			userStore.setError(false);
			userStore.setSuccess(false);
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		handleSecretChange = e => {
			const { userStore } = this.props.rootStore;

			userStore.setSecret(e.target.value);

			this.setState({
				passwordRuleLowercase: PASSWORD_RULE_LOWERCASE.test(userStore.secret),
				passwordRuleUppercase: PASSWORD_RULE_UPPERCASE.test(userStore.secret),
				passwordRuleNumber: PASSWORD_RULE_NUMBER.test(userStore.secret),
				passwordRuleSpecial: PASSWORD_RULE_SPECIAL.test(userStore.secret),
				passwordRuleLength: PASSWORD_RULE_LENGTH.test(userStore.secret),
			});
		};

		handleSecretConfirmChange = e => {
			const { userStore } = this.props.rootStore;

			userStore.setSecretConfirm(e.target.value);

			this.setState({
				passwordMismatch: userStore.secret !== userStore.secretConfirm
			});
		};

		getPasswordRuleClass = rule => {
			return rule ? 'password-strength__rule--success' : '';
		};

		getPasswordRuleIcon = rule => {
			return rule ? faCheckCircle : faTimesCircle;
		};

		isPasswordStrong = () => {
			const { passwordRuleLowercase, passwordRuleUppercase, passwordRuleNumber, passwordRuleSpecial, passwordRuleLength } = this.state;

			return passwordRuleLowercase && passwordRuleUppercase && passwordRuleNumber && passwordRuleSpecial && passwordRuleLength;
		};

		async handleResetPasswordClick(e) {
			e.preventDefault();

			const { userStore } = this.props.rootStore;

			userStore.forgotReset();
		}

		render() {
			const { secret, secretConfirm, error, success, loading } = this.props.rootStore.userStore;
			const { passwordRuleLowercase, passwordRuleUppercase, passwordRuleNumber, passwordRuleSpecial, passwordRuleLength } = this.state;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.reset.title" />
					</h2>

					<div className="section__wrapper">
						<div className="forgot">
							<div className="forgot__form">
								<form className="form">
									{!!success ? (
										<Fragment>
											<p className="form__text form__text--center">{success}</p>

											<p className="form__text form__text--center">
												<Link to="/account/signin" className="button button--gold">
													<Localized code="page.reset.link.goBack" />
												</Link>
											</p>
										</Fragment>
									) : null}

									{!success ? (
										<Fragment>
											<p className="form__text form__text--center">
												<Localized code="page.reset.text.resetPassword" />
											</p>

											{!!error ? (
											<div className="form__message form__message--error">{error}</div>
											) : null}

											<div className="form__group">
												<label className="form__label" htmlFor="newPassword">
													<Localized code="page.password.label.newPassword" />
												</label>
												<input className="form__input" type="password" id="newPassword" value={secret} onChange={this.handleSecretChange} />
												<div className="password-strength">
													<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleLowercase)}`}>
														<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleLowercase)} />
														<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleLowercase" /></span>
													</div>
													<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleUppercase)}`}>
														<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleUppercase)} />
														<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleUppercase" /></span>
													</div>
													<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleNumber)}`}>
														<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleNumber)} />
														<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleNumber" /></span>
													</div>
													<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleSpecial)}`}>
														<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleSpecial)} />
														<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleSpecial" /></span>
													</div>
													<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleLength)}`}>
														<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleLength)} />
														<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleLength" /></span>
													</div>
												</div>
											</div>

											<div className="form__group">
												<label className="form__label" htmlFor="newPasswordConfirm">
													<Localized code="page.password.label.newPasswordConfirm" />
												</label>
												<input className="form__input" type="password" id="newPasswordConfirm" value={secretConfirm} onChange={this.handleSecretConfirmChange} />
												{this.state.passwordMismatch && (
													<span className="form__field-error">
														<Localized code="page.password.text.passwordMismatch" />
													</span>
												)}
											</div>

											<div className="form__buttons">
												<button disabled={loading || !secret || !secretConfirm || (secret !== secretConfirm) || !this.isPasswordStrong()} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleResetPasswordClick}>
													{loading ? (
														<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
													) : null}
													<Localized code="page.reset.button.resetPassword" />
												</button>
											</div>
										</Fragment>
									) : null}
								</form>
							</div>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Reset;
