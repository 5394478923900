import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import Config from '../config/Config';
import { getDistanceFromLatLonInKm } from '../utils/Utils';
import { uniqWith } from 'lodash';
import hash from 'object-hash';
import { reportExceptionToSentry } from '../utils/Utils';

class SearchStore {

	loading = false;
	quickSearchLoading = false;
	error = false;
	query = '';
	page = 0;
	types = [];
	availableCategories = [];
	categories = [];
	cities = [];
	distance = undefined;
	total = 0;
	results = [];
	quickResults = [];
	previousResultCount = 0;
	nearMeTotal = 0;

	lastScrollPosition = 0;

	mapSearchOpen = false;
	mapSearchClosing = false;
	mapCenterLatitude = 25.276987; // Default Dubai lat
	mapCenterLongitude = 55.296249; // Default Dubai lon
	locationResults = [];

	address = '';
	locations = [];

	constructor(rootStore) {
		this.rootStore = rootStore;

		this.loadAvailableCategories().then(result => {
			if (result) {
				this.rootStore.commonStore.setAvailableCategoriesReady(true);
			} else {
				this.rootStore.commonStore.setAvailableCategoriesReady(false);
			}
		});
	}

	setLoading(loading) {
		this.loading = loading;
	}

	setQuickSearchLoading(quickSearchLoading) {
		this.quickSearchLoading = quickSearchLoading;
	}

	setError(error) {
		this.error = error;
	}

	setQuery(query) {
		this.query = query;
	}

	setTypes(types) {
		this.types = types;
	}

	setCategories(categories) {
		this.categories = categories;
	}

	setCities(cities) {
		this.cities = cities;
	}

	setDistance(distance) {
		this.distance = distance;
	}

	setMapSearchOpen(mapSearchOpen) {
		this.mapSearchOpen = mapSearchOpen;
	}

	setMapSearchClosing(mapSearchClosing) {
		this.mapSearchClosing = mapSearchClosing;
	}

	setMapCenterLatitude(mapCenterLatitude) {
		this.mapCenterLatitude = mapCenterLatitude;
	}

	setMapCenterLongitude(mapCenterLongitude) {
		this.mapCenterLongitude = mapCenterLongitude;
	}

	setAddress(address) {
		this.address = address;
	}

	clearResults() {
		this.page = 0;
		this.types = [];
		this.categories = [];
		this.cities = [];
		this.total = 0;
		this.results = [];
		this.previousResultCount = 0;

		this.lastScrollPosition = 0;
	}

	clearQuickResults() {
		this.quickResults = [];
	}

	async loadAvailableCategories() {
		try {
			const response = await FazaaAPI.Categories.all();

			runInAction(() => {
				this.availableCategories = response.data;
			});

			return true;
		} catch (e) {
			//this.rootStore.commonStore.setGeneralError('Unable to load categories data, general application error, please reload the page.');
			const errorMessage = this.rootStore.commonStore.locale === 'ar' ? localStorage.getItem('generalErrorAr') : localStorage.getItem('generalErrorEn');

			this.rootStore.commonStore.setGeneralError(errorMessage || Config.DEFAULT_STRINGS.generalError[this.rootStore.commonStore.locale || 'en']);

			reportExceptionToSentry(e);

			return false;
		}
	}

	async doSearch(params, preserve = false) {
		// TODO Maybe validate params?
		this.setLoading(true);

		if (!preserve) {
			this.clearResults();
		}

		runInAction(() => {
			params.page = preserve ? ++this.page : this.page;

			if (this.query !== params.q) {
				this.query = params.q ? params.q : '';
			}

			if (params.q === '') {
				delete params.q;
			}

			if (!!params.types) {
				this.setTypes(Array.isArray(params.types) ? params.types : [params.types]);
			}

			if (!!params.categories) {
				this.setCategories(Array.isArray(params.categories) ? params.categories : [params.categories]);
			}

			if (!!params.cities) {
				this.setCities(Array.isArray(params.cities) ? params.cities : [params.cities]);
			}

			// Send center of map position if we're on MapSearch component
			if (this.mapSearchOpen) {
				params.latitude = this.mapCenterLatitude;
				params.longitude = this.mapCenterLongitude;
			} else {
				// Send our position always if available (does not reflect in URL!)
				if (this.rootStore.userStore.position) {
					params.latitude = this.rootStore.userStore.position.latitude;
					params.longitude = this.rootStore.userStore.position.longitude;
				}
			}

			if (!!params.distanceInKm) {
				this.setDistance(params.distanceInKm);
			} else {
				this.setDistance(undefined);
			}

			if (this.mapSearchOpen) {
				params.size = Config.RESULTS_PER_PAGE_MAX;
			} else {
				params.size = Config.RESULTS_PER_PAGE;
			}
			
			// Make sure language is always present
			if (!params.language) {
				if (this.rootStore.commonStore.locale) {
					params.language = this.rootStore.commonStore.locale;
				} else {
					params.language = 'en';
				}
			}
		});

		try {
			const response = await FazaaAPI.Search.search(params);

			runInAction(() => {
				this.previousResultCount = this.results.length;
				this.results.push(...response.data.content);
				this.total = response.data.total;

				if (this.mapSearchOpen) {
					// Filter result locations to only include those close to search location
					const filtered = response.data.content.map(result => {
						result.locations = result.locations.filter(location => {
							if (getDistanceFromLatLonInKm(location.lat, location.lon, this.mapCenterLatitude, this.mapCenterLongitude) <= this.distance) {
								return true;
							} else {
								return false;
							}
						});

						// Only keep unique locations
						result.locations = uniqWith(result.locations, (a, b) => {
							if (a.lat === b.lat && a.lon === b.lon) return true;

							return false;
						});

						return result;
					});

					// Flatten result list so that each location includes item data
					const locations = filtered.reduce((a, c) => {
						a.push(...c.locations.map(location => {
							const { locations, ...rest } = c;

							return {
								...rest,
								location: {
									lat: location.lat,
									lng: location.lon
								},
								combinedTitle: c.partnerName ? c.partnerName + ' - ' + c.title : c.title,
								hash: hash({ ...location, id: c.id })
							};
						}));

						return a;
					}, []);

					const indexesToRemove = [];

					// Remove locations not in new results based on hash
					for (const location of this.locationResults) {
						if (!locations.find(l => l.hash === location.hash)) {
							indexesToRemove.push(this.locationResults.findIndex(lr => lr.hash === location.hash))
						}
					}

					for (const index of indexesToRemove.reverse()) {
						this.locationResults.splice(index, 1);
					}

					// Add new locations
					for (const location of locations) {
						if (!this.locationResults.find(lr => lr.hash === location.hash)) {
							this.locationResults.push(location);
						}
					}
				}
			});
		} catch (e) {
			this.setError(true);

			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}
	}

	async doQuickSearch(params) {
		this.setQuickSearchLoading(true);
		this.clearQuickResults();

		runInAction(() => {
			params.size = Config.MAX_QUICKSEARCH_RESULTS;

			// Send our position always if available (does not reflect in URL!)
			if (this.rootStore.userStore.position) {
				params.latitude = this.rootStore.userStore.position.latitude;
				params.longitude = this.rootStore.userStore.position.longitude;
			}
			
			// Make sure language is always present
			if (!params.language) {
				if (this.rootStore.commonStore.locale) {
					params.language = this.rootStore.commonStore.locale;
				} else {
					params.language = 'en';
				}
			}
		});

		try {
			const response = await FazaaAPI.Search.quickSearch(params);

			runInAction(() => {
				this.quickResults.push(...response.data);
			});
		} catch (e) {
			this.setError(true);

			reportExceptionToSentry(e);
		} finally {
			this.setQuickSearchLoading(false);
		}
	}

	async doNearMe() {
		const { commonStore, userStore } = this.rootStore;
		
		if (!userStore.position || !userStore.position.latitude || !userStore.position.longitude) return;

		const params = {
			latitude: userStore.position.latitude,
			longitude: userStore.position.longitude,
			language: commonStore.locale
		};

		try {
			const response = await FazaaAPI.Search.nearme(params);

			runInAction(() => {
				this.nearMeTotal = response.data;
			});
		} catch (e) {
			runInAction(() => {
				this.nearMeTotal = 0;
			});

			reportExceptionToSentry(e, [404]);
		}
	}

	async loadLocations() {
		this.locations = [];

		try {
			const response = await FazaaAPI.Search.locations();

			runInAction(() => {
				for (const entry in response.data) {
					this.locations.push({
						heading: true,
						id: response.data[entry].country.id,
						country: response.data[entry].country
					});

					for (const city of response.data[entry].cities) {
						this.locations.push({
							id: city.id,
							heading: false,
							city: city
						});
					}
				}
			});

			return true;
		} catch (e) {
			reportExceptionToSentry(e);

			return false;
		}
	}

}

decorate(SearchStore, {
	loading: observable,
	quickSearchLoading: observable,
	error: observable,
	query: observable,
	page: observable,
	types: observable,
	availableCategories: observable,
	categories: observable,
	distance: observable,
	results: observable,
	quickResults: observable,
	locationResults: observable,
	previousResultCount: observable,
	total: observable,
	nearMeTotal: observable,
	mapSearchOpen: observable,
	mapCenterLatitude: observable,
	mapCenterLongitude: observable,
	setLoading: action,
	setQuickSearchLoading: action,
	setError: action,
	setQuery: action,
	setCategories: action,
	setDistance: action,
	setTypes: action,
	setMapSearchOpen: action,
	setMapCenterLatitude: action,
	setMapCenterLongitude: action,
	clearResults: action,
	clearQuickResults: action,
	doSearch: action,
	doQuickSearch: action,
	doNearMe: action,
	address: observable,
	setAddress: action,
	cities: observable,
	setCities: action,
	locations: observable,
	loadLocations: action,
	loadAvailableCategories: action,
	mapSearchClosing: observable,
	setMapSearchClosing: action
});

export default SearchStore;
