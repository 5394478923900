import React, { Component } from 'react';
import Loader from 'react-loaders';

class AppLoading extends Component {

	render() {
		const { error } = this.props;

		return (
			<div className="app-loading">
				<div className="app-loading__inner">
					<div className="app-loading__loader">
						<Loader type="line-scale-pulse-out-rapid" color="#002652" />
					</div>
					{error ? (
					<p className="app-loading__error">{error}</p>
					) : null}
				</div>
			</div>
		);
	}

}

export default AppLoading;
