import { decorate, observable, action, runInAction, transaction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { reportExceptionToSentry } from '../utils/Utils';
import moment from 'moment-timezone';
import Config from '../config/Config';

moment.tz.setDefault('Asia/Dubai');

class PrepaidCardStore {

	secure = false;
	loading = false;
	code = '';
	cardInfo = undefined;
	balance = [];
	transactions = [];
	fromDate = '';
	toDate = '';
	customDate = false;
	showMonths = 1;
	fromWalletId = '';
	toWalletId = '';
	amount = '0.00';
	codeSent = false;
	smsResendTimer = 0;
	error = '';
	working = false;

	smsResendInterval = null;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	setSecure(secure) {
		this.secure = secure;
	}

	setLoading(loading) {
		this.loading = loading;
	}

	setCode(code) {
		this.code = code;
	}

	setFromDate(fromDate) {
		this.fromDate = fromDate;
	}
	
	setToDate(toDate) {
		this.toDate = toDate;
	}

	setCustomDate(customDate) {
		this.customDate = customDate;
	}

	setShowMonths(showMonths) {
		this.showMonths = showMonths;
	}

	setFromWalletId(fromWalletId) {
		this.fromWalletId = fromWalletId;
	}

	setToWalletId(toWalletId) {
		this.toWalletId = toWalletId;
	}

	setAmount(amount) {
		this.amount = amount;
	}

	setCodeSent(codeSent) {
		this.codeSent = codeSent;
	}

	setSmsResendTimer(smsResendTimer) {
		this.smsResendTimer = smsResendTimer;
	}

	setError(error) {
		this.error = error;
	}

	setWorking(working) {
		this.working = working;
	}

	async loadInfoBalance() {
		this.setError(false);
		this.setLoading(true);

		try {
			const response = await FazaaAPI.PrepaidCard.infoBalance();

			runInAction(() => {
				this.cardInfo = response.data.cardInfo;
				this.balance = response.data.balance;
			});

			return true;
		} catch (e) {
			this.handleAPIError(e);

			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}

		return false;
	}

	async loadInfo() {
		this.setError(false);
		this.setLoading(true);

		try {
			const response = await FazaaAPI.PrepaidCard.info();

			runInAction(() => {
				this.cardInfo = response.data
			});

			return true;
		} catch (e) {
			this.handleAPIError(e);

			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}

		return false;
	}

	async loadBalance() {
		this.setError(false);
		this.setLoading(true);

		try {
			const response = await FazaaAPI.PrepaidCard.balance();

			runInAction(() => {
				this.balance = response.data;
			});

			return true;
		} catch (e) {
			this.handleAPIError(e);

			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}

		return false;
	}

	async loadTransactions() {
		this.setError(false);
		this.setLoading(true);

		try {
			if (!this.customDate) {
				this.fromDate = moment().subtract(this.showMonths, "month").toDate();
				this.toDate = moment().toDate();
			}

			const data = {
				fromDate: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DD') : null,
				toDate: this.toDate ? moment(this.toDate).format('YYYY-MM-DD') : null
			};

			const response = await FazaaAPI.PrepaidCard.transactions(data);

			const transactions = [];
			let lastDate = null;

			for (const transaction of response.data) {
				if (!lastDate || lastDate !== moment(transaction.transactionDate, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YY')) {
					lastDate = moment(transaction.transactionDate, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YY');

					transactions.push({
						header: lastDate
					});
				}

				transactions.push(transaction);
			}

			runInAction(() => {
				this.transactions = transactions;
			});

			return true;
		} catch (e) {
			this.handleAPIError(e);

			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}

		return false;
	}

	async transfer() {
		this.setError(false);
		this.setWorking(true);

		try {
			const data = {
				fromWalletId: this.fromWalletId,
				toWalletId: this.toWalletId,
				amount: Number(this.amount)
			};

			const response = await FazaaAPI.PrepaidCard.transfer(data);

			this.setFromWalletId('');
			this.setToWalletId('');
			this.setAmount('0.00');

			return response.data;
		} catch (e) {
			this.handleAPIError(e);

			reportExceptionToSentry(e);
		} finally {
			this.setWorking(false);
		}

		return false;
	}

	async status(status) {
		this.setError(false);
		this.setWorking(true);

		try {
			await FazaaAPI.PrepaidCard.status("\"" + status + "\"");

			this.loadInfo();

			return true;
		} catch (e) {
			this.handleAPIError(e);

			reportExceptionToSentry(e);
		} finally {
			this.setWorking(false);
		}

		return false;
	}

	async OTPRequest() {
		this.setError(false);
		this.setLoading(true);
		this.setCode('');

		try {
			clearInterval(this.smsResendInterval);

			this.setSmsResendTimer(Config.SMS_VERIFICATION_TIMEOUT_SECONDS);

			this.smsResendInterval = setInterval(() => {
				if (this.smsResendTimer > 0) {
					this.setSmsResendTimer(this.smsResendTimer - 1);
				} else {
					clearInterval(this.smsResendInterval);
				}
			}, 1000);

			await FazaaAPI.PrepaidCard.OTPRequest();

			this.setCodeSent(true);

			return true;
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}

		return false;
	}

	async OTPValidate() {
		this.setError(false);
		this.setLoading(true);

		try {			
			const response = await FazaaAPI.PrepaidCard.OTPValidate(this.code);
			const prepaidToken = response.data;

			sessionStorage.setItem('prepaidToken', prepaidToken);

			this.setSecure(true);
			this.setCodeSent(false);

			return true;
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}

		return false;
	}

	invalidatePrepaidCardAccess() {
		try {
			this.cleanup();
		} catch (e) {
			reportExceptionToSentry(e);
		}
	}

	cleanupSessionData() {
		sessionStorage.removeItem('prepaidToken');

		this.secure = false;
		this.code = '';
		this.cardInfo = undefined;
		this.balance = [];
		this.transactions = [];
		this.fromDate = '';
		this.toDate = '';
		this.customDate = false;
		this.showMonths = 1;
		this.fromWalletId = '';
		this.toWalletId = '';
		this.amount = '0.00';
		this.codeSent = false;
		this.smsResendTimer = 0;
		this.error = '';
		this.working = false;

		clearInterval(this.smsResendInterval);

		this.smsResendInterval = null;
	}

	handleAPIError(e) {
		const { translateMessage } = this.rootStore.commonStore;

		if (e.response) {
			if (e.response.status === 403 && e.response.config && e.response.config.url && e.response.config.url.includes('prepaid/service')) {
				this.error = translateMessage('page.prepaidCard.error.tokenMissingOrExpired');
				
				this.invalidatePrepaidCardAccess();
	
				return;
			}
		}
	
		if (e.response && e.response.data) {
			switch (e.response.data.code) {
				case 'PC100':
					this.error = translateMessage('page.prepaidCard.error.bankServiceError');
	
					break;
				
				case 'PC101':
					this.error = translateMessage('page.prepaidCard.error.invalidInputData');
	
					break;
	
				case 'PC102':
					this.error = translateMessage('page.prepaidCard.error.unknownWalletId');
	
					break;
	
				case 'PC103':
					this.error = translateMessage('page.prepaidCard.error.notEnoughBalance');
		
					break;
				
				case 'PC104':
					this.error = translateMessage('page.prepaidCard.error.walletTransferFailed');
		
					break;
				
				case 'PC105':
					this.error = translateMessage('page.prepaidCard.error.invalidCardStatus');
		
					break;
	
				case 'PC106':
					this.error = translateMessage('page.prepaidCard.error.errorSettingCardStatus');
		
					break;

				// TODO Mark card as missing
				case 'PC107':
					this.error = translateMessage('page.prepaidCard.error.unknownFazaaId');
		
					break;
	
				default:
					if (e.response.data.localizedMessage) {
						this.error = translateMessage('generic.error.reason', {
							reason: e.response.data.localizedMessage
						});
					} else {
						this.error = translateMessage('generic.error.default');
					}
			}
		} else {
			this.error = translateMessage('generic.error.default');
		}
	}

}

decorate(PrepaidCardStore, {
	secure: observable,
	loading: observable,
	code: observable,
	cardInfo: observable,
	balance: observable,
	transactions: observable,
	fromDate: observable,
	toDate: observable,
	customDate: observable,
	showMonths: observable,
	fromWalletId: observable,
	toWalletId: observable,
	amount: observable,
	status: observable,
	codeSent: observable,
	smsResendTimer: observable,
	error: observable,
	working: observable,
	setSecure: action,
	setLoading: action,
	setCode: action,
	setFromDate: action,
	setToDate: action,
	setCustomDate: action,
	setShowMonths: action,
	setFromWalletId: action,
	setToWalletId: action,
	setAmount: action,
	setStatus: action,
	setCodeSent: action,
	setSmsResendTimer: action,
	setError: action,
	setWorking: action,
	loadInfoBalance: action,
	loadInfo: action,
	loadBalance: action,
	loadTransactions: action,
	invalidatePrepaidCardAccess: action,
	cleanupSessionData: action,
	handleAPIError: action
});

export default PrepaidCardStore;
