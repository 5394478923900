import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';

const VALID_EMAIL_OR_MEMBER_ID = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|[0-9]{7}$/;

const Forgot = inject('rootStore')(observer(
	class Forgot extends Component {

		constructor(props) {
			super(props);

			this.state = {
				emailError: false
			};

			this.handleRecoverPasswordClick = this.handleRecoverPasswordClick.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.forgot.title');
			});
		}

		componentDidMount() {
			const { userStore } = this.props.rootStore;

			if (userStore.authenticated) {
				this.props.history.replace('/');
			}

			userStore.setError(false);
			userStore.setSuccess(false);
			userStore.setPrincipal('');
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		handlePrincipalChange = e => {
			this.props.rootStore.userStore.setPrincipal(e.target.value.replace(' ', ''));

			const { userStore } = this.props.rootStore;
			const { principal } = userStore;

			if (principal && !VALID_EMAIL_OR_MEMBER_ID.test(principal)) {
				this.setState({
					emailError: true
				});	

				return;
			}

			this.setState({
				emailError: false
			});
		}

		async handleRecoverPasswordClick(e) {
			e.preventDefault();

			const { userStore } = this.props.rootStore;

			userStore.forgot();
		}

		render() {
			const { principal, error, success, loading } = this.props.rootStore.userStore;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.forgot.title" />
					</h2>

					<div className="section__wrapper">
						<div className="forgot">
							<div className="forgot__form">
								<form className="form">
									{!!success ? (
										<Fragment>
											<p className="form__text form__text--center">{success}</p>

											<p className="form__text form__text--center">
												<Link to="/" className="button button--gold">
													<Localized code="page.forgot.link.goBack" />
												</Link>
											</p>
										</Fragment>
									) : null}
									
									{!success ? (
										<Fragment>
											<p className="form__text form__text--center">
												<Localized code="page.forgot.text.recoverPassword" />
											</p>

											{!!error ? (
											<div className="form__message form__message--error">{error}</div>
											) : null}

											<div className="form__group">
												<label className="form__label form__label--center" htmlFor="principal">
													<Localized code="page.forgot.label.principal" />
												</label>
												<input className="form__input" type="text" id="principal" name="principal" value={principal} onChange={this.handlePrincipalChange} />
												{this.state.emailError && (
													<span className="form__field-error">
														<Localized code="generic.error.invalidEmailOrMemberId" />
													</span>
												)}
											</div>

											<div className="form__buttons">
												<button disabled={loading || !principal || this.state.emailError} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleRecoverPasswordClick}>
													{loading ? (
														<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
													) : null}
													<Localized code="page.forgot.button.recoverPassword" />
												</button>
											</div>
										</Fragment>
									) : null}
								</form>
							</div>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Forgot;
