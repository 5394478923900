import { decorate, observable, action, computed, runInAction, autorun, reaction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import Config from '../config/Config';
import { reportExceptionToSentry } from '../utils/Utils';

class CartStore {

	loading = false;
	error = '';
	success = '';
	errors = [];
	added = false;
	showTooltip = false;
	cart = [];

	// Checkout
	firstName = '';
	lastName = '';
	address = '';
	postalCode = '00000';
	city = '';
	country = '';
	email = '';

	ccNumber = '';
	ccExpiry = '';
	ccCvv = '';

	orderId = null;

	shippingLocationId = '';

	constructor(rootStore) {
		this.rootStore = rootStore;

		const localCart = JSON.parse(localStorage.getItem('cart'));
		const localOrderId = JSON.parse(localStorage.getItem('orderId'));

		if (localCart) {
			this.setCart(localCart);
			this.setOrderId(localOrderId);
			this.verifyCart();
		}

		autorun(() => {
			localStorage.setItem('cart', JSON.stringify(this.cart));
			localStorage.setItem('orderId', JSON.stringify(this.orderId));
		});

		// React to cart changes and invalidate orderId if we have any
		reaction(
			() => this.cart.map(item => item.qty),
			() => this.setOrderId(null)
		);
	}

	setLoading(loading) {
		this.loading = loading;
	}

	setError(error) {
		this.error = error;
	}

	setSuccess(success) {
		this.success = success;
	}

	setErrors(errors) {
		this.errors = errors;
	}

	setShowTooltip(showTooltip) {
		this.showTooltip = showTooltip;
	}

	setCart(cart) {
		this.cart = cart;
	}

	setFirstName(firstName) {
		this.firstName = firstName;
	}

	setLastName(lastName) {
		this.lastName = lastName;
	}

	setAddress(address) {
		this.address = address;
	}

	setPostalCode(postalCode) {
		this.postalCode = postalCode;
	}

	setCity(city) {
		this.city = city;
	}

	setCountry(country) {
		this.country = country;
	}

	setEmail(email) {
		this.email = email;
	}

	setCcNumber(ccNumber) {
		this.ccNumber = ccNumber;
	}

	setCcExpiry(ccExpiry) {
		this.ccExpiry = ccExpiry;
	}

	setCcCvv(ccCvv) {
		this.ccCvv = ccCvv;
	}

	setOrderId(orderId) {
		this.orderId = orderId;
	}

	setShippingLocationId(shippingLocationId) {
		this.shippingLocationId = shippingLocationId;
	}

	async verifyCart() {
		let allGood = true;

		// Verify each item in the cart and make sure it exists in remote
		if (this.cart && this.cart.length > 0) {
			const indexesToRemove = []; // items out of stock or above limits
			
			const validationResult = await this.validateCart();

			if (validationResult) {
				for (const item of this.cart) {
					let stockData;

					if (item.type === 'VOUCHER') {
						stockData = validationResult.unavailableVoucherIds.find(uvi => uvi.itemId === item.product.id);
					}

					if (item.type === 'VOUCHER_PACKAGE') {
						stockData = validationResult.unavailableVoucherPackageIds.find(uvpi => uvpi.itemId === item.product.id);
					}

					if (item.type === 'PRODUCT') {
						stockData = validationResult.unavailableProductIds.find(upi => upi.itemId === item.product.id);
					}

					if (stockData) {
						if (stockData.itemQty === 0) {
							runInAction(() => {
								item.qty = 0;
							});

							indexesToRemove.push(this.cart.findIndex(i => i.product.id === item.product.id));
						} else {
							runInAction(() => {
								item.qty = stockData.itemQty;
							});
						}

						allGood = false;
					}
				}
			}

			runInAction(() => {
				for (const index of indexesToRemove) {
					this.cart.splice(index, 1);
				}
			});
		}

		return allGood;
	}

	async validateCart() {
		try {
			const checkoutParams = this.generateValidationParams();
			const response = await FazaaAPI.Cart.validate(checkoutParams);

			if (response.status === 200) {
				return response.data;
			}
		} catch (e) {
			reportExceptionToSentry(e, [401, 404]);

			return false;
		}
	}

	addToCart(item, bought = 0, vouchers = [], voucherList = []) {
		const found = this.cart.find(i => i.product.id === item.product.id);

		if (found) {
			// Check if the number of items already in the cart is less or equal compared to
			// - maximum allowed items in cart
			// - not above available stock for products
			// - not above available stock for vouchers
			// - not above limit for voucher packages
			// - not above limit in regards to stock available for every voucher in voucher package
			let stockOrLimitExceeded = false;

			if (Number(found.qty) + Number(item.qty) > Config.MAX_CART_ITEMS_ADD) {
				stockOrLimitExceeded = true;
			}

			if (item.type === 'VOUCHER' && (Number(found.qty) + Number(item.qty)) > item.product.stockAvailable) {
				stockOrLimitExceeded = true;
			}

			if (item.type === 'VOUCHER_PACKAGE') {
				if ((Number(found.qty) + Number(item.qty)) > item.product.limitNumber - bought) {
					stockOrLimitExceeded = true;
				}

				// Check if adding the package vould go above available stock for each voucher contained
				if (vouchers.reduce((a, i) => a || i.stockAvailable < voucherList.find(vl => vl.voucherId === i.id).voucherQty * (found.qty + item.qty), false)) {
					stockOrLimitExceeded = true;
				}
			}

			if (item.type === 'PRODUCT' && (Number(found.qty) + Number(item.qty)) > item.product.productStock) {
				stockOrLimitExceeded = true;
			}

			if (stockOrLimitExceeded) {
				return false;
			}

			found.qty = Number(found.qty) + Number(item.qty);
			
			if (bought) {
				found.bought = bought;
			}

			if (vouchers && voucherList) {
				item.vouchers = vouchers;
				item.voucherList = voucherList;
			}
		} else {
			if (bought) {
				item.bought = bought;
			}

			if (vouchers && voucherList) {
				item.vouchers = vouchers;
				item.voucherList = voucherList;
			}

			this.cart.push(item);
		}

		this.added = true;
		this.showTooltip = true;

		setTimeout(() => {
			runInAction(() => {
				this.added = false;
			});
		}, 3000);

		return true;
	}

	updateCart(id, qty) {
		const found = this.cart.find(i => i.product.id === id);

		if (found) {
			found.qty = parseInt(qty) < 0 ? 0 : parseInt(qty);
		}

		if (found.qty === 0) {
			const foundIndex = this.cart.findIndex(item => item.product.id === id);

			if (foundIndex) {
				this.cart.splice(foundIndex, 1);
			}
		}
	}

	removeFromCart(id) {
		this.cart = this.cart.filter(i => i.product.id !== id);
	}

	get count() {
		return this.cart.reduce((a, i) => a + Number(i.qty), 0);
	}

	get total() {
		return this.cart.reduce((a, i) => {
			switch (i.type) {
				case 'VOUCHER':
					return a + (i.product.voucherPrice * i.qty);
				
				case 'VOUCHER_PACKAGE':
					return a + (i.product.packagePrice * i.qty);

				case 'PRODUCT':
					return a + (Config.PRODUCT_CREDIT_CARD_FEE * ((Math.min(i.product.productOriginalPrice, i.product.productDiscountPrice) * i.qty) + (i.product.productShippingCost * i.qty)))
				
				default:
					return a;
			}
		}, 0);
	}

	get totalShipping() {
		return this.cart.reduce((a, i) => {
			switch (i.type) {
				case 'PRODUCT':
					return a + (i.product.productShippingCost * i.qty);
				
				default:
					return a;
			}
		}, 0);
	}

	get totalProductCreditCardFee() {
		return this.cart.reduce((a, i) => {
			switch (i.type) {
				case 'PRODUCT':
					return a + ((Config.PRODUCT_CREDIT_CARD_FEE - 1) * ((Math.min(i.product.productOriginalPrice, i.product.productDiscountPrice) * i.qty) + (i.product.productShippingCost * i.qty)))
				
				default:
					return a;
			}
		}, 0);
	}

	clearCart() {
		this.cart = [];
		this.orderId = null;
	}

	clearForm() {
		this.firstName = '';
		this.lastName = '';
		this.address = '';
		this.postalCode = '00000';
		this.city = '';
		this.country = '';
		this.email = '';

		this.ccNumber = '';
		this.ccExpiry = '';
		this.ccCvv = '';

		this.shippingLocationId = '';

		this.error = false;
		this.success = false;
		this.errors = [];
	}

	generateValidationParams() {
		return {
			voucherIds: this.cart.filter(item => item.type === 'VOUCHER').map(item => ({
				itemId: item.product.id,
				itemQty: item.qty
			})) || [],
			voucherPackageIds: this.cart.filter(item => item.type === 'VOUCHER_PACKAGE').map(item => ({
				itemId: item.product.id,
				itemQty: item.qty
			})) || [],
			productIds: this.cart.filter(item => item.type === 'PRODUCT').map(item => ({
				itemId: item.product.id,
				itemQty: item.qty
			})) || []
		};
	}

	generateCheckoutParams() {
		return {
			vouchers: this.cart.filter(item => item.type === 'VOUCHER').map(item => ({
				voucherId: item.product.id,
				qty: item.qty
			})) || [],
			voucherPackages: this.cart.filter(item => item.type === 'VOUCHER_PACKAGE').map(item => ({
				packageId: item.product.id,
				qty: item.qty
			})) || [],
			productCart: this.cart.find(item => item.type === 'PRODUCT') ? {
				products: this.cart.filter(item => item.type === 'PRODUCT').map(item => ({
					productId: item.product.id,
					qty: item.qty
				})) || [],
				productShippingLocationId: this.shippingLocationId ? this.shippingLocationId : null
			} : null
		};
	}

	async doCheckout() {
		if (this.loading) return;

		this.setError(false);
		this.setSuccess(false);
		this.clearErrors();
		this.setLoading(true);

		try {
			let success = false;

			if (!this.orderId) {
				const checkoutParams = this.generateCheckoutParams();
				const checkoutResponse = await FazaaAPI.Cart.checkout(checkoutParams);

				if (checkoutResponse.status === 201) {
					this.setOrderId(checkoutResponse.headers.location);
				}
			}

			if (this.orderId) {
				if (this.total > 0) {
					const paymentParams = {
						creditCardInfo: {
							cardNumber: this.ccNumber.replace(new RegExp(' ', 'g'), ''),
							expMonth: this.ccExpiry.split(' / ')[0],
							expYear: this.ccExpiry.split(' / ')[1],
							cvv: this.ccCvv
						},
						billingInformation: {
							firstName: this.firstName,
							lastName: this.lastName,
							address: this.address,
							postalCode: this.postalCode,
							city: this.city,
							country: this.country,
							email: this.email
						}
					};

					const paymentResponse = await FazaaAPI.Cart.payment(this.orderId, paymentParams);

					if (paymentResponse.status === 200) {
						success = true;
					} 
				} else {
					success = true;
				}
			}

			if (success) {
				this.setSuccess(true);
				this.clearCart();
			}
		} catch (e) {
			// P101 or C101 means that item is no longer available
			// L001 means member exceeded amount of vouchers per month
			// TODO: Handle errors for voucher packages
			this.setError(this.rootStore.commonStore.translateMessage('page.cart.error.checkoutError', {
				reason: (e && e.response && e.response.data && e.response.data.localizedMessage) ? e.response.data.localizedMessage : 'ERROR'
			}));

			if (e && e.response && e.response.data) {
				if (e.response.data.code && (e.response.data.code === 'P101' || e.response.data.code === 'C101')) {
					this.setError(this.rootStore.commonStore.translateMessage('page.cart.error.checkoutErrorOutOfStock'));
				} else if (e.response.data.code && e.response.data.code === 'L001') {
					this.setError(this.rootStore.commonStore.translateMessage('page.cart.error.checkoutErrorMaximumExceeded'));
				} else if (e.response.data.code && e.response.data.code === 'R200') {
					this.setError(this.rootStore.commonStore.translateMessage('page.cart.error.checkoutErrorEmptyCart'));
				} else if (e.response.data.localizedMessage) {
					this.setError(this.rootStore.commonStore.translateMessage('page.cart.error.checkoutError', {
						reason: e.response.data.localizedMessage
					}));
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.cart.error.checkoutErrorNonDescript'));
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.cart.error.checkoutErrorNonDescript'));
			}

			reportExceptionToSentry(e, [422]);
		} finally {
			this.setLoading(false);
		}
	}

	clearErrors() {
		this.error = false;
		this.errors = [];
	}

	addError(field, message) {
		this.errors.push({
			field,
			message
		});
	}

	cleanupSessionData() {
		this.cart = [];
		this.firstName = '';
		this.lastName = '';
		this.address = '';
		this.postalCode = '00000';
		this.city = '';
		this.country = '';
		this.email = '';
		this.ccNumber = '';
		this.ccExpiry = '';
		this.ccCvv = '';
		this.orderId = null;
		this.shippingLocationId = '';
	}

}

decorate(CartStore, {
	loading: observable,
	error: observable,
	success: observable,
	errors: observable,
	added: observable,
	showTooltip: observable,
	cart: observable,
	firstName: observable,
	lastName: observable,
	address: observable,
	postalCode: observable,
	city: observable,
	country: observable,
	email: observable,
	ccNumber: observable,
	ccExpiry: observable,
	ccCvv: observable,
	orderId: observable,
	shippingLocationId: observable,
	setLoading: action,
	setError: action,
	setSuccess: action,
	setErrors: action,
	setShowTooltip: action,
	setCart: action,
	setFirstName: action,
	setLastName: action,
	setAddress: action,
	setPostalCode: action,
	setCity: action,
	setCountry: action,
	setEmail: action,
	setCcNumber: action,
	setCcExpiry: action,
	setCcCvv: action,
	setOrderId: action,
	verifyCart: action,
	addToCart: action,
	updateCart: action,
	removeFromCart: action,
	count: computed,
	total: computed,
	clearCart: action,
	clearForm: action,
	doCheckout: action,
	clearErrors: action,
	addError: action,
	setShippingLocationId: action,
	cleanupSessionData: action
});

export default CartStore;
