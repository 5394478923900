import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import Config from '../../../config/Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import imgLogo from '../../../assets/img/logo-fazaa.png';


const AppError = inject('rootStore')(observer(
	class AppError extends PureComponent {

		render() {
			const { error, rootStore: { commonStore: { locale } } } = this.props;

			return (
				<div className="app-error">
					<div className="app-error__inner">
						<FontAwesomeIcon className="app-error__icon" icon={faExclamationTriangle} size="6x" />

						<img className="app-error__logo" src={imgLogo} alt="Fazaa" title="Fazaa" />

						<hr className="app-error__hr" />

						<p className="app-error__text">{error || Config.DEFAULT_STRINGS.generalError[locale || 'en']}</p>
					</div>
				</div>
			);
		}

	}
));


export default AppError;
