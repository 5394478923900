import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDateInput from '../../base/form/CustomDateInput';
import ErrorBox from '../../base/error/ErrorBox';

moment.tz.setDefault('Asia/Dubai');

const PrepaidCardTransactions = inject('rootStore')(observer(
	class PrepaidCardTransactions extends Component {

		constructor(props) {
			super(props);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.prepaidCardTransactions.title');
			});
		}

		componentDidMount() {
			const { prepaidCardStore } = this.props.rootStore;

			prepaidCardStore.setShowMonths(1);
			prepaidCardStore.setCustomDate(false);
			prepaidCardStore.loadTransactions();
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		handlePeriodChange = e => {
			const { prepaidCardStore } = this.props.rootStore;

			prepaidCardStore.setShowMonths(e.target.value);

			if (e.target.value === "0") {
				prepaidCardStore.setCustomDate(true);
			} else {
				prepaidCardStore.setCustomDate(false);
			}

			prepaidCardStore.loadTransactions();
		};

		handleFromDateChange = date => {
			const { prepaidCardStore } = this.props.rootStore;

			prepaidCardStore.setFromDate(date);

			prepaidCardStore.loadTransactions();
		};

		handleToDateChange = date => {
			const { prepaidCardStore } = this.props.rootStore;

			prepaidCardStore.setToDate(date);

			prepaidCardStore.loadTransactions();
		};

		render() {
			const { commonStore, prepaidCardStore } = this.props.rootStore;
			const { translateMessage } = commonStore;
			const { error, loading, transactions, showMonths, customDate, fromDate, toDate } = prepaidCardStore;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.prepaidCardTransactions.title" />
					</h2>

					<div className="section__wrapper">
						<div className="prepaid-card">
							<div className="prepaid-card__main">
								<ErrorBox message={error} style={{ justifyContent: "center" }} />

								<div className="prepaid-card__filters">
									<div className="prepaid-card__filter">
										<div className="form__group">
											<label className="form__label" htmlFor="period">
												<Localized code="page.prepaidCard.label.period" />
											</label>
											<div className="form__select-wrap">
												<select className="form__select" id="period" value={showMonths} onChange={this.handlePeriodChange}>
													<option value={1}>{translateMessage('page.prepaidCardTransactions.label.lastMonth')}</option>
													<option value={2}>{translateMessage('page.prepaidCardTransactions.label.lastTwoMonths')}</option>
													<option value={3}>{translateMessage('page.prepaidCardTransactions.label.lastThreeMonths')}</option>
													<option value={0}>{translateMessage('page.prepaidCardTransactions.label.customDateRange')}</option>
												</select>
											</div>
										</div>
									</div>

									{customDate ? (
										<Fragment>
											<div className="prepaid-card__filter">
												<div className="form__group">
													<label className="form__label" htmlFor="fromDate">
														<Localized code="page.prepaidCardTransactions.label.fromDate" />
													</label>
													<DatePicker
														dateFormat="dd-MM-yyyy"
														selected={fromDate}
														onChange={this.handleFromDateChange}
														customInput={
															<CustomDateInput dir="ltr" type="text" id="fromDate" />
														}
														className="form__input"
														placeholderText="DD/MM/YYYY"
														showMonthDropdown
														showYearDropdown
														dropdownMode="select"
														maxDate={moment(toDate).subtract(1, "day").toDate()}
													/>
												</div>
											</div>

											<div className="prepaid-card__filter">
												<div className="form__group">
													<label className="form__label" htmlFor="toDate">
														<Localized code="page.prepaidCardTransactions.label.toDate" />
													</label>
													<DatePicker
														dateFormat="dd-MM-yyyy"
														selected={toDate}
														onChange={this.handleFromDateChange}
														customInput={
															<CustomDateInput dir="ltr" type="text" id="toDate" />
														}
														className="form__input"
														placeholderText="DD/MM/YYYY"
														showMonthDropdown
														showYearDropdown
														dropdownMode="select"
														minDate={moment(fromDate).add(1, "day").toDate()}
														maxDate={moment().toDate()}
													/>
												</div>
											</div>
										</Fragment>
									) : null}
								</div>

								{loading ? (
									<div className="prepaid-card__transactions">
										<div className="prepaid-card__tx-header">
											<Skeleton width={100} />
										</div>

										<div className="prepaid-card__tx">
											<div className="prepaid-card__tx-meta">
												<div className="prepaid-card__tx-location">
													<Skeleton width={200} />
												</div>
												<div className="prepaid-card__tx-description">
													<Skeleton width={100} />
												</div>
											</div>

											<div className="prepaid-card__tx-amount">
												<div className="prepaid-card__tx-amount-transaction">
													<Skeleton width={100} />
												</div>
												<div className="prepaid-card__tx-amount-billing">
													<Skeleton width={50} />
												</div>
											</div>
										</div>

										<div className="prepaid-card__tx">
											<div className="prepaid-card__tx-meta">
												<div className="prepaid-card__tx-location">
													<Skeleton width={200} />
												</div>
												<div className="prepaid-card__tx-description">
													<Skeleton width={100} />
												</div>
											</div>

											<div className="prepaid-card__tx-amount">
												<div className="prepaid-card__tx-amount-transaction">
													<Skeleton width={100} />
												</div>
												<div className="prepaid-card__tx-amount-billing">
													<Skeleton width={50} />
												</div>
											</div>
										</div>

										<div className="prepaid-card__tx">
											<div className="prepaid-card__tx-meta">
												<div className="prepaid-card__tx-location">
													<Skeleton width={200} />
												</div>
												<div className="prepaid-card__tx-description">
													<Skeleton width={100} />
												</div>
											</div>

											<div className="prepaid-card__tx-amount">
												<div className="prepaid-card__tx-amount-transaction">
													<Skeleton width={100} />
												</div>
												<div className="prepaid-card__tx-amount-billing">
													<Skeleton width={50} />
												</div>
											</div>
										</div>
									</div>
								) : (
									transactions.length > 0 ? (
										<div className="prepaid-card__transactions">
											{transactions.map((tx, i) => (
												tx.header ? (
													<div key={i} className="prepaid-card__tx-header">{tx.header}</div>
												) : (
													<div key={i} className="prepaid-card__tx">
														<div className="prepaid-card__tx-meta">
															<div className="prepaid-card__tx-location">{tx.merchantLocation ? tx.merchantLocation : tx.transactionNarration}</div>
															<div className="prepaid-card__tx-description">{tx.merchantLocation ? tx.transactionNarration + ' &mdash; ' : ''}{moment(tx.transactionDate, 'DD-MM-YYYY HH:mm:ss').format('HH:mm')}</div>
														</div>

														<div className="prepaid-card__tx-amount">
															<div className="prepaid-card__tx-amount-transaction">{tx.transactionAmount} {tx.transactionCurrency.AlphabeticCode}</div>
															<div className="prepaid-card__tx-amount-billing">{tx.billingCurrency.AlphabeticCode}</div>
														</div>
													</div>
												)
											))}
										</div>
									) : (
										<p className="section__text section__text--center">
											<Localized code="page.account.text.noTransactions" />
										</p>
									)
								)}
							</div>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default PrepaidCardTransactions;
