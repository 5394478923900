import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { reportExceptionToSentry } from '../utils/Utils';

class HamadStore {

	loading = false;
	news = [];
	currentSlideIndex = 0;
	reload = false;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	setLoading(loading) {
		this.loading = loading;
	}

	setCurrentSlideIndex(index) {
		this.currentSlideIndex = index;
	}

	setReload(reload) {
		this.reload = reload;
	}

	async loadAllNews() {
		this.loading = true;

		this.news.clear();

		try {
			const response = await FazaaAPI.Home.hamadNews();

			runInAction(() => {
				this.news = response.data;
			});
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

}

decorate(HamadStore, {
	loading: observable,
	news: observable,
	currentSlideIndex: observable,
	reload: observable,
	setLoading: action,
	setCurrentSlideIndex: action,
	setReload: action,
	loadAllNews: action
});

export default HamadStore;