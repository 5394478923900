import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { reportExceptionToSentry } from '../utils/Utils';

class PageStore {

	loading = false;
	notFound = false;
	pages = new Map();

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	async loadPage(slug) {
		this.notFound = false;

		if (this.pages.has(slug)) {
			return this.pages.get(slug);
		}

		this.loading = true;

		try {
			const response = await FazaaAPI.Pages.get(slug);

			runInAction(() => {
				this.pages.set(slug, response.data);
			});

			return response.data;
		} catch (e) {
			runInAction(() => {
				this.notFound = true;
			});

			reportExceptionToSentry(e, [404]);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	getPage(slug) {
		return this.pages.get(slug);
	}

}

decorate(PageStore, {
	loading: observable,
	notFound: observable,
	pages: observable,
	loadPage: action,
	getPage: action
});

export default PageStore;
