import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CategoriesStrip from '../base/categories/CategoriesStrip';
import Swiper from '@eredessil/react-id-swiper';
import HTTP404 from '../base/error/HTTP404';
import Config from '../../config/Config';
import Localized from '../base/i18n/Localized';
import { autorun } from 'mobx';
import FormCheckboxButton from '../base/form/FormChecboxButton';

import imgSliderIconArrowLeft from '../../assets/img/slider-icon-arrow-left.png'
import imgSliderIconArrowRight from '../../assets/img/slider-icon-arrow-right.png'

const VoucherPackage = inject('rootStore')(observer(
	class VoucherPackage extends Component {

		constructor(props) {
			super(props);

			this.state = {
				qty: 1,
				terms: false,
				bought:0 
			};

			const { voucherStore } = props.rootStore;

			voucherStore.setLoadingPackage(true);

			this.swiperRef = React.createRef();

			this.handleQtyChange = this.handleQtyChange.bind(this);
			this.handleAddToCart = this.handleAddToCart.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { commonStore, voucherStore } = props.rootStore;
				const { locale, pageTitlePrefix } = commonStore;
				const { slug } = this.props.match.params;
				const voucherPackage = voucherStore.getVoucherPackage(slug);

				if (voucherPackage) {
					document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + voucherPackage.voucherPackage.localData[locale].name;
				} else {
					document.title = pageTitlePrefix;
				}
			});
		}

		componentDidMount() {
			const { slug } = this.props.match.params;
			const { voucherStore } = this.props.rootStore;

			this.props.rootStore.voucherStore.loadVoucherPackage(slug).then(() => {
				const voucherPackage = voucherStore.getVoucherPackage(slug);

				this.props.rootStore.voucherStore.loadLimits(voucherPackage.voucherPackage.id).then(result => {
					this.setState({
						bought: result
					});
				});
			});
		}
		
		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}
		
		handleQtyChange(e) {
			const { voucherStore } = this.props.rootStore;
			const { slug } = this.props.match.params;
			const voucherPackage = voucherStore.getVoucherPackage(slug);

			if (e.target.value !== '' && e.target.validity.valid) {
				this.setState({
					qty: e.target.value > voucherPackage.voucherPackage.limitNumber - this.state.bought ?  voucherPackage.voucherPackage.limitNumber - this.state.bought : e.target.value
				});
			} else {
				this.setState({
					qty: 1
				});
			}
		}

		handleAddToCart(e) {
			e.preventDefault();

			const { cartStore, voucherStore, commonStore: { translateMessage } } = this.props.rootStore;

			if (cartStore.added) {
				return;
			}

			const { slug } = this.props.match.params;
			const voucherPackage = voucherStore.getVoucherPackage(slug);

			if (cartStore.addToCart({
					type: 'VOUCHER_PACKAGE',
					// Add just the embedded voucher package data so that we can access the id
					product: voucherPackage.voucherPackage,
					qty: parseInt(this.state.qty)
				}, this.state.bought, voucherPackage.vouchers, voucherPackage.voucherList)
			) {
				document.documentElement.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			} else {
				alert(translateMessage('page.cart.text.addMax', {
					amount: Config.MAX_CART_ITEMS_ADD
				}));
			}
		}

		handleTermsChange = e => this.setState({
			terms: e.target.checked
		});

		render() {
			const { locale, translateMessage } = this.props.rootStore.commonStore;
			const { voucherStore, cartStore } = this.props.rootStore;
			const { loadingPackage } = voucherStore;
			const { added, cart } = cartStore;
			const { slug } = this.props.match.params;
			const voucherPackage = voucherStore.getVoucherPackage(slug);
			const swiperParams = {
				slidesPerView: 1,
				navigation: {
					prevEl: '.voucher-page__gallery-left',
					nextEl: '.voucher-page__gallery-right'
				},
				renderPrevButton: () => (
					<div className="voucher-page__gallery-left">
						<img className="voucher-page__gallery-arrow" src={imgSliderIconArrowLeft} alt="Slide left" />
					</div>
				),
  				renderNextButton: () => (
					<div className="voucher-page__gallery-right">
						<img className="voucher-page__gallery-arrow" src={imgSliderIconArrowRight} alt="Slide right" />
					</div>
				)
			};
			const inCart = voucherPackage ? cart.filter(i => i.type === 'VOUCHER_PACKAGE' && i.product.id === voucherPackage.voucherPackage.id).reduce((a, i) => a + parseInt(i.qty), 0) : 0;
			const images = voucherPackage ? voucherPackage.vouchers.reduce((a, i) => { a.push(...i.images); return a; } , []) : [];
			const outOfStock = voucherPackage ? voucherPackage.vouchers.reduce((a, i) => a || i.stockAvailable < voucherPackage.voucherList.find(vl => vl.voucherId === i.id).voucherQty, false) : false;
			let maxQty = Config.MAX_CART_ITEMS_ADD;
			
			if (voucherPackage) {
				maxQty = Math.min(voucherPackage.voucherPackage.limitNumber - this.state.bought - inCart, Config.MAX_CART_ITEMS_ADD);

				for (let i = 1; i <= maxQty; i++) {
					let voucherOutOfStock = false;

					voucherOutOfStock = voucherPackage.vouchers.reduce((a, v) => a || (v.stockAvailable < voucherPackage.voucherList.find(vl => vl.voucherId === v.id).voucherQty * (i + inCart)), false);

					if (voucherOutOfStock) {
						maxQty = i - 1;

						break;
					}
				}
			}

			return (
				!loadingPackage ? (
					voucherPackage ? (
						<section className="section section--gray">
							<div className="section__wrapper">
								<div className="voucher-page">
									<div className="voucher-page__main">
										<div className="voucher-page__gallery">
											{images.length > 0 ? (
												<Swiper {...swiperParams} ref={this.swiperRef} rebuildOnUpdate>
													<div className="voucher-page__slide">
														<img className="voucher-page__image" src={`${Config.UPLOADS_ENDPOINT}${voucherPackage.voucherPackage.packageImage}`} alt={voucherPackage.voucherPackage.localData[locale].name} />
													</div>
													{images.map(image => (
														<div key={image.id} className="voucher-page__slide">
															<img className="voucher-page__image" src={`${Config.UPLOADS_ENDPOINT}${image.imageUri}`} alt="" />
														</div>
													))}
												</Swiper>
											) : (
												<img className="voucher-page__image" src={`${Config.UPLOADS_ENDPOINT}${voucherPackage.voucherPackage.packageImage}`} alt={voucherPackage.voucherPackage.localData[locale].name} />
											)}
										</div>

										<div className="voucher-page__description">
											<CategoriesStrip categories={voucherPackage.vouchers.reduce((a, i) => {
												for (const c of i.categories) {
													if (a.find(v => v.id === c.id) === undefined) {
														a.push(c);
													}
												}

												return a;
											} , [])} />

											<h2 className="voucher-page__title voucher-page__title--margin-bottom">{voucherPackage.voucherPackage.localData[locale].name}</h2>

											<p className="section__text">
												<Localized code="page.voucher.text.vouchersPackageNotice" />
											</p>

											<h3 className="voucher-page__subtitle">
												<Localized code="page.voucher.text.vouchersContained" />
											</h3>

											<div className="voucher-page__voucher-list">
												{voucherPackage.vouchers.map(voucher => (
													<div key={voucher.id} className="voucher-page__voucher-item">
														<div className="voucher-page__voucher-item-image-wrapper">
															<img className="voucher-page__voucher-item-image" src={`${Config.UPLOADS_ENDPOINT}${voucher.voucherImageUri}`} alt={voucher.localData[locale].title} />
														</div>

														<div className="voucher-page__voucher-item-info">
															<div className="voucher-page__voucher-item-partner">
																{voucher.partner.partnerName}
															</div>
															<div className="voucher-page__voucher-item-title">
																{voucher.localData[locale].title}
															</div>
														</div>

														<div className="voucher-page__voucher-item-qty">
															&times; {voucherPackage.voucherList.find(v => v.voucherId === voucher.id).voucherQty}
															{(voucher.stockAvailable < voucherPackage.voucherList.find(v => v.voucherId === voucher.id).voucherQty) ? (
																<Fragment>
																	<br />
																	<p className="voucher-page__voucher-item-out-of-stock">
																		<Localized code="page.voucher.text.voucherOutOfStock" />
																	</p>
																</Fragment>
															) : null}
														</div>
													</div>
												))}
											</div>

											<div className="voucher-page__package-limit">
												<p className="section__text">
													{voucherPackage.voucherPackage.monthlyLimit ? (
														<Localized code="page.voucher.text.packageMonthlyLimit" bought={this.state.bought} limit={voucherPackage.voucherPackage.limitNumber} />
													) : (
														<Localized code="page.voucher.text.packageLimit" bought={this.state.bought} limit={voucherPackage.voucherPackage.limitNumber} />
													)}
												</p>
											</div>

											{inCart ? (
												<p className="voucher-page__in-cart">
													<Localized code="generic.text.inCart" inCart={inCart} />
												</p>
											) : null}

											<div className="voucher-page__pricing">
												<div className="voucher-page__pricing-price">
													{voucherPackage.voucherPackage.packagePrice} AED
												</div>

												<div className="voucher-page__add">
													{voucherPackage.voucherPackage.limitNumber - this.state.bought - inCart > 0 && !outOfStock && maxQty > 0 ? (
														<div className="voucher-page__qty-select-wrap">
															<select className="voucher-page__qty-select" value={this.state.qty} onChange={this.handleQtyChange}>
																{Array.from(Array(maxQty), (e, i) => {
																	return <option key={i} value={i + 1}>{i + 1}</option>
																})}
															</select>
														</div>
													) : null}

													<button disabled={voucherPackage.voucherPackage.limitNumber - inCart - this.state.bought <= 0 || !this.state.terms || outOfStock || maxQty === 0} className="button button--gold" onClick={this.handleAddToCart}>
														{voucherPackage.voucherPackage.limitNumber - inCart - this.state.bought <= 0 || outOfStock || maxQty === 0 ? (
															outOfStock || maxQty === 0 ? (
																<Localized code="page.voucher.button.outOfStock" />
															) : (
																<Localized code="page.voucher.button.packageLimitExceeded" />
															)
														) : (
															<Localized code="page.voucher.button.addToCart" />
														)}
													</button>
												</div>
											</div>

											<div className="voucher-page__terms-agree">
												<FormCheckboxButton name="terms" value={true} label={translateMessage('page.voucher.label.acceptTerms')} checked={this.state.terms} onChange={this.handleTermsChange} />
											</div>
										</div>
									</div>

									<div className="voucher-page__extended">
										<Tabs>
											<TabList>
												<Tab>
													<Localized code="page.voucher.tab.description" />
												</Tab>
											</TabList>

											<TabPanel>
												<div className="cms-generated" dangerouslySetInnerHTML={{__html: voucherPackage.voucherPackage.localData[locale].description}} />
											</TabPanel>
										</Tabs>
									</div>
								</div>
							</div>
						</section>
					) : (
						<HTTP404 />
					)
				) : null
			);
		}

	}
));

export default VoucherPackage;
