import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import { Link } from 'react-router-dom';
import Loader from 'react-loaders';
import moment from 'moment-timezone';

moment.tz.setDefault('Asia/Dubai');

const Orders = inject('rootStore')(observer(
	class Orders extends Component {

		constructor(props) {
			super(props);

			this.scrollListener = this.scrollListener.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				if (props.archive) {
					document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.orders.title.archive');
				} else {
					document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.orders.title.current');
				}
			});
		}

		componentDidMount() {
			const { orderStore } = this.props.rootStore;

			if (this.props.archive) {
				orderStore.loadOrdersArchive(false);
			} else {
				orderStore.loadOrders(false);
			}

			// Add scroll event listener
			window.addEventListener('scroll', this.scrollListener);
		}

		componentWillUnmount() {
			// Remove scroll event listener
			window.removeEventListener('scroll', this.scrollListener);

			this.mobxAutorunDisposer();
		}

		scrollListener(e) {
			const { rootStore } = this.props;
			const { /*categoriesRef,*/ footerRef } = rootStore.commonStore;
			const { orderStore } = rootStore;
			const { loading } = orderStore;

			if (orderStore.lastScrollPosition > window.pageYOffset) {
				return;
			}

			if (/*categoriesRef == null ||*/ footerRef == null) {
				return;
			}

			if (window.pageYOffset + window.innerHeight <= document.body.scrollHeight /*- categoriesRef.scrollHeight*/ - footerRef.scrollHeight) {
				return;
			}

			if (orderStore.total === orderStore.orders.length) {
				return;
			}

			if (!loading) {
				orderStore.lastScrollPosition = window.pageYOffset;

				if (this.props.archive) {
					orderStore.loadOrdersArchive(true);
				} else {
					orderStore.loadOrders(true);
				}
			}
		}

		render() {
			const { commonStore, orderStore } = this.props.rootStore;
			const { locale } = commonStore;
			const { loading: ordersLoading, orders } = orderStore;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						{this.props.archive ? (
							<Localized code="page.orders.title.archive" />
						) : (
							<Localized code="page.orders.title.current" />
						)}
					</h2>

					<div className="section__wrapper">
						<div className="account">
							{orders.length > 0 ? (
								<div className="account__orders">
									<div className="account__orders-header">
										<div className="account__orders-header-column account__orders-header-column--order-id">
											<Localized code="page.account.text.orderId" />
										</div>
										<div className="account__orders-header-column account__orders-header-column--amount">
											<Localized code="page.account.text.orderAmount" />
										</div>
										<div className="account__orders-header-column account__orders-header-column--status">
											<Localized code="page.account.text.orderStatus" />
										</div>
									</div>

									<div className="account__orders-body">
										{orders.map(order => (
											<Link key={order.orderId} className="account__orders-row" to={`/account/orders/view/${order.orderId}`}>
												<div className="account__orders-body-column account__orders-body-column--order-id">
													{order.orderId}
													<br />
													<small>{moment(order.createdAt).format('DD/MM/YY')}</small>
												</div>
												<div className="account__orders-body-column account__orders-body-column--amount">
													{order.total} AED
												</div>
												<div className="account__orders-body-column account__orders-body-column--status">
													{order.status}
													<br />
													<small title="Last update">{moment(order.statusChanged).format('DD/MM/YY @ HH:mm:ss')}</small>
												</div>
											</Link>
										))}
									</div>
								</div>
							) : (
								<p className="section__text section__text--center">
									<Localized code="page.account.text.noOrders" __links={{
										link1: <Link className="section__link" to={`/search?language=${locale}&types=VOUCHER`}></Link>
									}} />
								</p>
							)}

							{ordersLoading ? (
								<div className="account__orders-loading">
									<Loader type="line-scale-pulse-out-rapid" color="#002652" />
								</div>
							) : null}
						</div>
					</div>
				</section>
			);
		}

	}
));

Orders.defaultProps = {
	archive: false
};

export default Orders;
