import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { chunk } from 'lodash';
import Config from '../../config/Config';
import Localized from '../base/i18n/Localized';
import { autorun } from 'mobx';

const AllCategories = inject('rootStore')(observer(
	class AllCategories extends Component {

		constructor(props) {
			super(props);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.categories.title');
			});
		}

		componentDidMount() {
			const { categoryStore } = this.props.rootStore;

			categoryStore.loadAllCategories();
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		render() {
			const { locale } = this.props.rootStore.commonStore;
			const { loading, categories } = this.props.rootStore.categoryStore;
			const grouped = categories.length > 0 ? chunk(categories, 4) : [];

			return (
				!loading ? (
					<section className="section section--gray">
						<h2 className="section__title section__title--center">
							<Localized code="page.categories.title" />
						</h2>

						<div className="section__wrapper">
							<div className="all-categories">
								{grouped.map((row, rowIndex) => (
								<TransitionGroup key={rowIndex} appear className="all-categories__row">
									{row.map((category, index) => {
										const delay = (rowIndex * 200) + (index * 50);

										return (
											<CSSTransition key={category.id} timeout={300 + delay} classNames="all-categories__category">
												<div className="all-categories__category" style={{transitionDelay: `${delay}ms`}}>
													<Link className="all-categories__link" to={`/search?language=${locale}&categories=${encodeURIComponent(category.localData[locale].name)}`}>
														<div className="all-categories__image-wrapper">
															<img className="all-categories__image" src={`${Config.UPLOADS_ENDPOINT}${category.categoryImageUri}`} alt={category.localData[locale].name} title={category.localData[locale].name} />
														</div>

														<div className="all-categories__meta">
															<h5 className="all-categories__title">{category.localData[locale].name}</h5>

															<p className="all-categories__description">{category.localData[locale].description}</p>
														</div>
													</Link>
												</div>
											</CSSTransition>
										)
									})}
								</TransitionGroup>
								))}
							</div>
						</div>
					</section>
				) : null
			);
		}

	}
));

export default AllCategories;
