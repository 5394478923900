import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FormCheckboxButton extends Component {

	constructor(props) {
		super(props);

		this.handleOnChange = this.handleOnChange.bind(this);
	}

	handleOnChange(e) {
		this.props.onChange(e);
	}

	render() {
		const { name, value, defaultValue = null, label, checked } = this.props;

		return (
			<div className={`form__checkbox ${checked ? 'form__checkbox--checked' : ''}`}>
				<label className="form__checkbox-label">
					{defaultValue && !checked ? (
						<input type="hidden" name={name} value={defaultValue} />
					) : null}
					<input className="form__checkbox-input" type="checkbox" name={name} value={value} onChange={this.handleOnChange} checked={checked} /> {label}
				</label>
			</div>
		);
	}

}

FormCheckboxButton.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
	label: PropTypes.any.isRequired,
	checked: PropTypes.any.isRequired,
	onChange: PropTypes.func.isRequired
};

export default FormCheckboxButton;
