import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { reportExceptionToSentry } from '../utils/Utils';

class CarStore {

	loading = false;
	cars = [];
	total = 0;
	page = 0;

	previousResultCount = 0;

	lastScrollPosition = 0;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	setLoading(loading) {
		this.loading = loading;
	}

	async loadCars(preserve = false) {
		this.setLoading(true);

		if (!preserve) {
			runInAction(() => {
				this.cars = [];
				this.total = 0;
				this.page = 0;
				this.previousResultCount = 0;
			});
		} else {
			runInAction(() => {
				this.page++;
			});
		}

		try {
			const response = await FazaaAPI.Cars.list(this.page);

			runInAction(() => {
				this.previousResultCount = this.cars.length;
				this.cars.push(...response.data.content);
				this.total = response.data.totalRows;
			});
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}
	}

	async loadCar(slug) {
		this.setLoading(true);

		try {
			const response = await FazaaAPI.Cars.get(slug);

			return response.data;
		} catch (e) {
			reportExceptionToSentry(e, [404]);

			return null;
		} finally {
			this.setLoading(false);
		}
	}

}

decorate(CarStore, {
	loading: observable,
	cars: observable,
	total: observable,
	page: observable,
	previousResultCount: observable,
	setLoading: action,
	loadCars: action
});

export default CarStore;
