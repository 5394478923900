import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import { Link } from 'react-router-dom';
import Loader from 'react-loaders';
import ReactLoading from 'react-loading';
import { camelCase, capitalize } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import ErrorBox from '../../base/error/ErrorBox';
import Config from '../../../config/Config';

import imgCardPlatinum from '../../../assets/img/prepaid-card-platinum-new-2021.jpg';
import imgCardGold from '../../../assets/img/prepaid-card-gold-new-2021.jpg';
import imgCardSilver from '../../../assets/img/prepaid-card-silver-new-2021.jpg';

const PrepaidCard = inject('rootStore')(observer(
	class PrepaidCard extends Component {

		constructor(props) {
			super(props);

			this.cardPreviewRef = React.createRef();

			this.state = {
				number: {
					fontSize: 33,
					top: 170,
					left: 55
				},
				expiry: {
					fontSize: 20,
					top: 265,
					left: 90
				},
				name: {
					fontSize: 22,
					top: 145,
					left: 0,
					right: 0
				},
				membershipNumber: {
					fontSize: 22,
					top: 170,
					left: 0,
					right: 0
				}
			};

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.prepaidCard.title');
			});
		}

		componentDidMount() {
			const { prepaidCardStore } = this.props.rootStore;
			const { secure } = prepaidCardStore;

			if (secure) {
				prepaidCardStore.loadInfoBalance();
			}

			window.addEventListener('resize', this.handleResize);

			this.handleResize();
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();

			window.removeEventListener('resize', this.handleResize);
		}

		handleResize = () => {
			const baseWidth = 485;

			if (this.cardPreviewRef.current) {
				const cr = this.cardPreviewRef.current.getBoundingClientRect();

				const wChangePercent = cr.width * 100 / baseWidth;

				this.setState({
					number: {
						fontSize: 33 * (wChangePercent / 100),
						top: 170 * (wChangePercent / 100),
						left: 55 * (wChangePercent / 100)
					},
					expiry: {
						fontSize: 20 * (wChangePercent / 100),
						top: 265 * (wChangePercent / 100),
						left: 90 * (wChangePercent / 100)
					},
					name: {
						fontSize: 22 * (wChangePercent / 100),
						top: 145 * (wChangePercent / 100)
					},
					membershipNumber: {
						fontSize: 22 * (wChangePercent / 100),
						top: 170 * (wChangePercent / 100)
					}
				});
			}
		}

		handleSmsCodeChange = e => {
			const { prepaidCardStore } = this.props.rootStore;

			prepaidCardStore.setCode(e.target.value);
		};

		handleResendSmsVerificationCode = e => {
			const { prepaidCardStore } = this.props.rootStore;

			e.preventDefault();

			prepaidCardStore.OTPRequest();
		};

		handleSmsVerificationClick = e => {
			const { commonStore: { translateMessage } , prepaidCardStore } = this.props.rootStore;

			e.preventDefault();

			prepaidCardStore.OTPValidate().then(result => {
				if (!result) {
					prepaidCardStore.setError(translateMessage('page.prepaidCard.error.smsVerifyFail'));
				} else {
					prepaidCardStore.loadInfoBalance();
				}
			})
		};

		handlePrepaidCardStatusToggle = e => {
			const { prepaidCardStore } = this.props.rootStore;
			const { status } = prepaidCardStore;

			e.preventDefault();

			prepaidCardStore.status(status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE');
		};

		render() {
			const { userStore, prepaidCardStore } = this.props.rootStore;
			const { me } = userStore;
			const { code, secure, error, codeSent, smsResendTimer, loading, cardInfo, balance, working } = prepaidCardStore;
			const noBalance = !balance || balance.reduce((a, c) => a + c.balance, 0) === 0;
			let cardImage = null;

			switch (me.membershipLevel.id) {
				case 1: cardImage = imgCardPlatinum; break;
				case 2: cardImage = imgCardGold; break;
				case 3: cardImage = imgCardSilver; break;
			}

			const cardImageSource = !me.companyCoverImageUri ? cardImage : Config.UPLOADS_ENDPOINT + me.companyCoverImageUri;
			const textColor = !me.companyCoverImageUri || !me.companyCoverDarkBranding ? (me.membershipLevel.id === 4 ? '#000' : '#fff') : me.companyCoverDarkBranding ? '#fff' : '#000';

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.prepaidCard.title" />
					</h2>

					<div className="section__wrapper">
						<div className="prepaid-card">
							{secure ? (
								<div className="prepaid-card__main">
									<ErrorBox message={error} style={{ justifyContent: "center" }} />

									<div className="section__columns">
										<div className="section__column">
											<h3 className="section__subtitle">
												<Localized code="page.prepaidCard.subtitle.card" />
											</h3>

											{loading || !cardInfo ? (
												<div className="prepaid-card__card-loading">
													<div className="prepaid-card__card-loading-inner">
														<Loader type="line-scale-pulse-out-rapid" color="#002652" />
													</div>
												</div>
											) : (
												<div ref={this.cardPreviewRef} className="prepaid-card__card">
													<img className="prepaid-card__card-image" src={cardImageSource} />

													<span className="prepaid-card__card-text" style={{ color: textColor, fontSize: this.state.expiry.fontSize + 'px', top: this.state.expiry.top + 'px', left: this.state.expiry.left + 'px'}}>{cardInfo.expiryMonth}/{cardInfo.expiryYear}</span>
													<span className="prepaid-card__card-text prepaid-card__card-text--center" style={{ color: textColor, fontSize: this.state.name.fontSize + 'px', top: this.state.name.top + 'px', left: this.state.name.left + 'px', right: 0 }}>{cardInfo.nameOnCard}</span>
													<span className="prepaid-card__card-text prepaid-card__card-text--center" style={{ color: textColor, fontSize: this.state.membershipNumber.fontSize + 'px', top: this.state.membershipNumber.top + 'px', left: this.state.membershipNumber.left + 'px', right: 0 }}>{me.membershipNumber}</span>
												</div>
											)}

											{!loading && cardInfo ? (
												<div className="prepaid-card__status">
													<p className="prepaid-card__status-text section__text">
														<span className="prepaid-card__status-text-label">
															<Localized code="page.prepaidCard.text.cardStatus" />:
														</span>
														<span className="prepaid-card__status-text-value">
															<Localized code={`page.prepaidCard.text.cardStatus${capitalize(camelCase(cardInfo.status))}`} />
														</span>
													</p>

													{cardInfo.status === 'ACTIVE' || cardInfo.status === 'INACTIVE' ? (
														<button disabled={working} className="prepaid-card__status-button button button--small button--gold form__button--has-loading" onClick={this.handlePrepaidCardStatusToggle}>
															{working ? (
																<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
															) : null}
															{cardInfo.status === 'ACTIVE' ? (
																<Localized code="page.prepaidCard.button.cardStatusToggleInactive" />
															) : (
																<Localized code="page.prepaidCard.button.cardStatusToggleActive" />
															)}
														</button>
													) : null}
												</div>
											) : null}

											<div className="account__controls">
												<Link to="/account/prepaid-card/transactions" className="account__controls-button button button--small button--gold">
													<Localized code="page.prepaidCard.link.transactions" />
												</Link>

												<Link to="/account/prepaid-card/transfer" className={`account__controls-button button button--small button--gold ${!cardInfo || cardInfo.status !== 'ACTIVE' || noBalance ? 'button--disabled' : ''}`}>
													<Localized code="page.prepaidCard.link.transfer" />
												</Link>
											</div>
										</div>

										<div className="section__column">
											<h3 className="section__subtitle">
												<Localized code="page.prepaidCard.subtitle.balance" />
											</h3>

											{loading || !balance ? (
												<div className="prepaid-card__balance">
													<div className="prepaid-card__wallet">
														<div className="prepaid-card__currency">
															<span className="prepaid-card__currency-code">
																<Skeleton width={50} />
															</span>
															<span className="prepaid-card__currency-name">
																<Skeleton width={200} />
															</span>
														</div>

														<div className="prepaid-card__wallet-amount">
															<Skeleton width={50} />
														</div>
													</div>

													<div className="prepaid-card__wallet">
														<div className="prepaid-card__currency">
															<span className="prepaid-card__currency-code">
																<Skeleton width={50} />
															</span>
															<span className="prepaid-card__currency-name">
																<Skeleton width={200} />
															</span>
														</div>

														<div className="prepaid-card__wallet-amount">
															<Skeleton width={50} />
														</div>
													</div>

													<div className="prepaid-card__wallet">
														<div className="prepaid-card__currency">
															<span className="prepaid-card__currency-code">
																<Skeleton width={50} />
															</span>
															<span className="prepaid-card__currency-name">
																<Skeleton width={200} />
															</span>
														</div>

														<div className="prepaid-card__wallet-amount">
															<Skeleton width={50} />
														</div>
													</div>
												</div>
											) : (
												<div className="prepaid-card__balance">
													{balance.map(b => (
														<div key={b.walletId} className="prepaid-card__wallet">
															<div className="prepaid-card__currency">
																<span className="prepaid-card__currency-code">{b.currencyCode.AlphabeticCode}</span>
																<span className="prepaid-card__currency-name">{b.currencyCode.Currency}</span>
															</div>

															<div className="prepaid-card__wallet-amount">{b.balance.toFixed(2)}</div>
														</div>
													))}
												</div>
											)}
										</div>
									</div>
								</div>
							) : (
								<div className="prepaid-card__gate">
									<div className="prepaid-card__gate-form">
										<form className="form">
											<p className="form__text form__text--center">
												<Localized code="page.prepaidCard.text.verificationIntro" />
											</p>

											{!!error ? (
												<div className="form__message form__message--center form__message--error">{error}</div>
											) : null}

											{!!codeSent ? (
												<p className="form__text form__text--center">
													<Localized code="page.prepaidCard.text.codeSent" />
												</p>
											) : null}

											<div className="form__row">
												<div className="form__column">
													<div className="form__group">
														<label className="form__label" htmlFor="smsCode">
															<Localized code="page.signup.label.smsCode" />
														</label>
														<input className="form__input" type="text" id="smsCode" value={code} onChange={this.handleSmsCodeChange} />
													</div>
												</div>

												<div className="form__column form__column--button-only">
													<button disabled={smsResendTimer > 0} className="form__button form__button--small form__button--blue" type="button" onClick={this.handleResendSmsVerificationCode}>
														<Localized code="page.prepaidCard.button.resendSMSCode" />
														{smsResendTimer > 0 ? ' (' + smsResendTimer + ')' : '' }
													</button>
												</div>
											</div>

											<div className="form__buttons">
												<button disabled={!code || loading} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleSmsVerificationClick}>
													{loading ? (
														<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
													) : null}
													<Localized code="page.prepaidCard.button.verifySMSCode" />
												</button>
											</div>
										</form>
									</div>
								</div>
							)}
						</div>
					</div>
				</section>
			);
		}

	}
));

export default PrepaidCard;
