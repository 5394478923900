import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { withRouter } from 'react-router-dom';

const TempEstore = inject('rootStore')(withRouter(observer(
	class TempEstore extends Component {

		constructor(props) {
			super(props);

			this.handleSignIn = this.handleSignIn.bind(this);
			this.handleSignUp = this.handleSignUp.bind(this);
		}

		handleSignIn() {
			const { userStore } = this.props.rootStore;

			userStore.setRedirectTo(this.props.location.pathname);

			this.props.history.push('/account/signin');
		}

		handleSignUp() {
			const { userStore } = this.props.rootStore;
			
			userStore.setRedirectTo(this.props.location.pathname);

			this.props.history.push('/account/signup');
		}

		render() {
			const { userStore } = this.props.rootStore;
			const { authenticated } = userStore;

			return (
				<section className="section section--gray section--no-top-padding">
					<div className="section__wrapper">
						<div className="temp-store">
							{authenticated ? (
								<p className="section__text section__text--center">
									<a className="button button--blue" target="_blank" href="https://grocery.fazaa.ae/?VvBvfFQJRh-JPxl9ZmQYfg">
										<Localized code="page.tempStore.link.accessStore" />
									</a>
								</p>
							) : (
								<Fragment>
									<p className="section__text section__text--lead section__text--center">
										<Localized code="page.tempStore.text.accountRequired" />
									</p>

									<div className="section__columns">
										<div className="section__column section__column--center">
											<button className="button button--blue" onClick={this.handleSignIn}>
												<Localized code="page.signIn.button.signIn" />
											</button>
										</div>

										<div className="section__column section__column--center">
											<button className="button button--blue" onClick={this.handleSignUp}>
												<Localized code="page.signIn.link.signUp" />
											</button>
										</div>
									</div>
								</Fragment>
							)}
						</div>
					</div>
				</section>
			);
		}

	}
)));

export default TempEstore;
