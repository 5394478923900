const Emirates = [{
	id: 1, name: 'Abu Dhabi', nameAr: 'إِمَـارَة أَبُـوظَـبِي'
}, {
	id: 2, name: 'Ajman', nameAr: 'إمارة عجمان'
}, {
	id: 3, name: 'Dubai', nameAr: 'دبيّ'
}, {
	id: 4, name: 'Fujairah', nameAr: 'الفجيرة'
}, {
	id: 5, name: 'Ras Al Khaimah', nameAr: 'إِمَارَة رَأْس ٱلْخَيْمَة'
}, {
	id: 6, name: 'Sharjah', nameAr: 'إِمَارَة ٱلشَّارِقَة'
}, {
	id: 7, name: 'Umm Al Quwain', nameAr: 'إِمَارَة أُمّ ٱلْقَيْوَيْن'
}];

Object.freeze(Emirates);

export default Emirates;
