import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { reportExceptionToSentry } from '../utils/Utils';

class PaymentStore {

	loading = false;
	working = false;
	notFound = false;
	paymentRequest = null;
	error = '';
	success = '';
	errors = [];

	// Payment
	firstName = '';
	lastName = '';
	address = '';
	postalCode = '00000';
	city = '';
	country = '';
	email = '';

	ccNumber = '';
	ccExpiry = '';
	ccCvv = '';

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	setWorking(working) {
		this.working = working;
	}

	setFirstName(firstName) {
		this.firstName = firstName;
	}

	setLastName(lastName) {
		this.lastName = lastName;
	}

	setAddress(address) {
		this.address = address;
	}

	setPostalCode(postalCode) {
		this.postalCode = postalCode;
	}

	setCity(city) {
		this.city = city;
	}

	setCountry(country) {
		this.country = country;
	}

	setEmail(email) {
		this.email = email;
	}

	setCcNumber(ccNumber) {
		this.ccNumber = ccNumber;
	}

	setCcExpiry(ccExpiry) {
		this.ccExpiry = ccExpiry;
	}

	setCcCvv(ccCvv) {
		this.ccCvv = ccCvv;
	}

	async loadPaymentRequest(id) {
		this.notFound = false;
		this.loading = true;

		try {
			const response = await FazaaAPI.Payment.getPaymentRequest(id);

			runInAction(() => {
				this.paymentRequest = response.data;
			});

			return response.data;
		} catch (e) {
			runInAction(() => {
				this.notFound = true;
			});

			reportExceptionToSentry(e, [404]);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async makePayment(id) {
		if (this.working) return;

		this.setError(false);
		this.setSuccess(false);
		this.clearErrors();
		this.setWorking(true);

		try {
			const paymentParams = {
				creditCardInfo: {
					cardNumber: this.ccNumber.replace(new RegExp(' ', 'g'), ''),
					expMonth: this.ccExpiry.split(' / ')[0],
					expYear: this.ccExpiry.split(' / ')[1],
					cvv: this.ccCvv
				},
				billingInformation: {
					firstName: this.firstName,
					lastName: this.lastName,
					address: this.address,
					postalCode: this.postalCode,
					city: this.city,
					country: this.country,
					email: this.email
				}
			};

			const paymentResponse = await FazaaAPI.Payment.makePayment(id, paymentParams);

			if (paymentResponse.status === 200) {
				this.setSuccess(true);
			}
		} catch (e) {
			this.setError(this.rootStore.commonStore.translateMessage('page.payment.error.paymentError', {
				reason: (e && e.response && e.response.data && e.response.data.localizedMessage) ? e.response.data.localizedMessage : 'ERROR'
			}));

			reportExceptionToSentry(e, [422]);
		} finally {
			this.setWorking(false);
		}
	}

	setError(error) {
		this.error = error;
	}

	setSuccess(success) {
		this.success = success;
	}

	setErrors(errors) {
		this.errors = errors;
	}

	clearErrors() {
		this.error = false;
		this.errors = [];
	}

	addError(field, message) {
		this.errors.push({
			field,
			message
		});
	}

	clearForm() {
		this.firstName = '';
		this.lastName = '';
		this.address = '';
		this.postalCode = '00000';
		this.city = '';
		this.country = '';
		this.email = '';

		this.ccNumber = '';
		this.ccExpiry = '';
		this.ccCvv = '';

		this.error = false;
		this.success = false;
		this.errors = [];
	}

	cleanupSessionData() {
		this.firstName = '';
		this.lastName = '';
		this.address = '';
		this.postalCode = '00000';
		this.city = '';
		this.country = '';
		this.email = '';
		this.ccNumber = '';
		this.ccExpiry = '';
		this.ccCvv = '';
	}

}

decorate(PaymentStore, {
	loading: observable,
	working: observable,
	notFound: observable,
	paymentRequest: observable,
	firstName: observable,
	lastName: observable,
	address: observable,
	postalCode: observable,
	city: observable,
	country: observable,
	email: observable,
	ccNumber: observable,
	ccExpiry: observable,
	ccCvv: observable,
	error: observable,
	success: observable,
	errors: observable,
	setWorking: action,
	setFirstName: action,
	setLastName: action,
	setAddress: action,
	setPostalCode: action,
	setCity: action,
	setCountry: action,
	setEmail: action,
	setCcNumber: action,
	setCcExpiry: action,
	setCcCvv: action,
	loadPaymentRequest: action,
	makePayment: action,
	setError: action,
	setSuccess: action,
	setErrors: action,
	clearErrors: action,
	addError: action,
	clearForm: action,
	cleanupSessionData: action
});

export default PaymentStore;
