import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {

	componentDidUpdate(prevProps) {
		// TODO Fix to work only on pathname not on params!
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return this.props.children;
	}

}

export default withRouter(ScrollToTop);
