import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { without, union } from 'lodash';
import { reportExceptionToSentry } from '../utils/Utils';

class OrderStore {

	loading = false;
	pdfLoading = [];
	orders = [];
	extraOffersTransactions = [];
	total = 0;
	page = 0;

	lastScrollPosition = 0;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	setLoading(loading) {
		this.loading = loading;
	}

	setPdfLoading(pdfLoading) {
		this.pdfLoading = pdfLoading;
	}

	async loadOrders(preserve = false) {
		this.setLoading(true);

		if (!preserve) {
			runInAction(() => {
				this.orders = [];
				this.page = 0;
			});
		} else {
			runInAction(() => {
				this.page++;
			});
		}

		try {
			const response = await FazaaAPI.User.orders(this.page);

			runInAction(() => {
				this.orders.push(...response.data.content);
				this.total = response.data.totalRows;
			});
		} catch (e) {
			reportExceptionToSentry(e, [403]);
		} finally {
			this.setLoading(false);
		}
	}

	async loadOrdersArchive(preserve = false) {
		this.setLoading(true);

		if (!preserve) {
			runInAction(() => {
				this.orders = [];
				this.page = 0;
			});
		} else {
			runInAction(() => {
				this.page++;
			});
		}

		try {
			const response = await FazaaAPI.User.ordersArchived(this.page);

			runInAction(() => {
				this.orders.push(...response.data.content);
				this.total = response.data.totalRows;
			});
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}
	}

	async loadOrder(orderId) {
		this.setLoading(true);

		try {
			const response = await FazaaAPI.User.order(orderId);

			return response.data;
		} catch (e) {
			reportExceptionToSentry(e, [404]);

			return null;
		} finally {
			this.setLoading(false);
		}
	}

	async loadOrderPDF(idOrder, idVoucher) {
		this.setPdfLoading(union(this.pdfLoading, [idVoucher]));

		try {
			const response = await FazaaAPI.User.orderPDF(this.rootStore.commonStore.locale, idOrder, idVoucher);

			return response.data;
		} catch (e) {
			reportExceptionToSentry(e, [404]);
			
			return null;
		} finally {
			this.setPdfLoading(without(this.pdfLoading, idVoucher));
		}
	}

	async loadExtraOffersTransactions(preserve = false) {
		this.setLoading(true);

		if (!preserve) {
			runInAction(() => {
				this.extraOffersTransactions = [];
				this.page = 0;
			});
		} else {
			runInAction(() => {
				this.page++;
			});
		}

		try {
			const response = await FazaaAPI.User.extraOffersTransactions(this.page);

			runInAction(() => {
				this.extraOffersTransactions.push(...response.data.content);
				this.total = response.data.totalRows;
			});
		} catch (e) {
			reportExceptionToSentry(e, [403]);
		} finally {
			this.setLoading(false);
		}
	}

	cleanupSessionData() {
		this.orders = [];
		this.extraOffersTransactions = []
	}

}

decorate(OrderStore, {
	loading: observable,
	pdfLoading: observable,
	orders: observable,
	extraOffersTransactions: observable,
	total: observable,
	page: observable,
	setLoading: action,
	setPdfLoading: action,
	loadOrders: action,
	loadOrdersArchive: action,
	cleanupSessionData: action
});

export default OrderStore;
