import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

class ErrorBox extends PureComponent {

	render() {
		const { message, ...rest } = this.props;

		if (message) {
			return (
				<div className="error-box" {...rest}>
					<FontAwesomeIcon icon={faExclamationTriangle} className="error-box__icon" />
					<p className="error-box__text">{this.props.message}</p>
				</div>
			);
		} else {
			return null;
		}
	}

}

export default ErrorBox;
