import { decorate, observable, action, runInAction, computed } from 'mobx';
import FazaAPI from '../api/FazaaAPI';
import { groupBy, replace } from 'lodash';
import moment from 'moment-timezone';
import React, { Fragment } from 'react';
import Qs from 'qs';
import { reportExceptionToSentry } from '../utils/Utils';
import Config from '../config/Config';

moment.tz.setDefault('Asia/Dubai');

class CommonStore {

	appReady = {
		userReady: null,
		languagesReady: null,
		i18nReady: null,
		availableCategoriesReady: null
	};
	locationReady = false;
	i18n = {};
	languages = [];
	previousLocale = 'en';
	locale = 'en';
	pageTitlePrefix = 'Fazaa';
	mobileMenuOpen = false;
	mobileMenuMainOpen = false
	languageDropdownOpen = false;
	switchDropdownOpen = false;
	generalError = '';

	modalVisible = false;
	modalType = undefined;
	modalTitle = undefined;
	modalMessage = undefined;

	categoriesRef = null;
	footerRef = null;

	constructor(rootStore) {
		this.rootStore = rootStore;

		this.translateMessage = this.translateMessage.bind(this);

		this.loadLanguages().then(result => {
			let locale = localStorage.getItem('locale');

			// Check for language param in the URL
			const searchParams = Qs.parse(window.location.search, {
				ignoreQueryPrefix: true
			});

			if (searchParams.language) {
				locale = searchParams.language;
			}
			
			if (result) {
				if (this.languages.find(language => language.languageCode === locale)) {
					runInAction(() => {
						this.previousLocale = locale;
						this.locale = locale;
					});
				} else {
					runInAction(() => {
						this.previousLocale = this.languages[0].languageCode;
						this.locale = this.languages[0].languageCode;
					});

					localStorage.setItem('locale', this.locale);
				}

				this.setLanguagesReady(true);
			} else {
				this.setLanguagesReady(false);
			}
		});

		this.loadI18nTimestamp().then(result => {
			const timestamp = localStorage.getItem('i18nTimestamp');
			const i18n = localStorage.getItem('i18nList');

			// Failing to load timestamp is not a show stopper, we can just treat our local data if we have any as expired and proceed
			if (!!!result || !!!timestamp || !!!i18n || timestamp < moment(result).unix()) {
				this.loadI18nList().then(result => {
					if (result) {
						localStorage.setItem('i18nList', JSON.stringify(this.i18n));
						localStorage.setItem('i18nTimestamp', moment(result).unix());

						this.setI18nReady(true);

						// Store error message for when API is down or unreachable
						if (this.i18n) {
							if (this.i18n.en && this.i18n.ar) {
								if (this.i18n.en.find(i => i.code === 'home.error.backendDownError') && this.i18n.ar.find(i => i.code === 'home.error.backendDownError')) {
									localStorage.setItem('generalErrorEn', this.i18n.en.find(i => i.code === 'home.error.backendDownError').message);
									localStorage.setItem('generalErrorAr', this.i18n.ar.find(i => i.code === 'home.error.backendDownError').message);
								}
							}
						}
					} else {
						this.setI18nReady(false);
					}
				})
			} else {
				this.i18n = JSON.parse(i18n);

				this.setI18nReady(true);
			}
		});
	}

	setUserReady(userReady) {
		this.appReady.userReady = userReady;
	}

	setLanguagesReady(languagesReady) {
		this.appReady.languagesReady = languagesReady;
	}

	setI18nReady(i18nReady) {
		this.appReady.i18nReady = i18nReady;
	}

	setAvailableCategoriesReady(availableCategoriesReady) {
		this.appReady.availableCategoriesReady = availableCategoriesReady;
	}

	setLocationReady(locationReady) {
		this.locationReady = locationReady;
	}

	setPreviousLocale(locale) {
		this.previousLocale = locale;
	}

	setLocale(locale) {
		if (this.languages.length === 0 || !this.locale || !locale) {
			return;
		}

		if (!!this.languages.find(language => language.languageCode === locale)) {
			this.previousLocale = this.locale;
			this.locale = locale;
		} else {
			this.previousLocale = this.locale;
			this.locale = this.languages[0].languageCode;
		}

		localStorage.setItem('locale', this.locale);
	}

	setMobileMenuOpen(open) {
		this.mobileMenuOpen = open;
	}

	setMobileMenuMainOpen(open) {
		this.mobileMenuMainOpen = open;
	}

	setLanguageDropdownOpen(open) {
		this.languageDropdownOpen = open;
	}

	setSwitchDropdownOpen(open) {
		this.switchDropdownOpen = open;
	}

	setGeneralError(generalError) {
		this.generalError = generalError;
	}

	setModalVisible(modalVisible) {
		this.modalVisible = modalVisible;
	}

	setModalType(modalType) {
		this.modalType = modalType;
	}

	setModalTitle(modalTitle) {
		this.modalTitle = modalTitle;
	}

	setModalMessage(modalMessage) {
		this.modalMessage = modalMessage;
	}

	async loadLanguages() {
		try {
			const response = await FazaAPI.Common.languages();

			runInAction(() => {
				this.languages = response.data;
			});

			return true;
		} catch (e) {
			// Try to recover by adding English as the only locale
			runInAction(() => {
				this.languages = [{
					languageName: 'English',
					languageCode: 'en'
				}];
			});

			reportExceptionToSentry(e);

			return false;
		}
	}

	async loadI18n() {
		try {
			const response = await FazaAPI.Common.i18n();

			runInAction(() => {
				this.i18n = groupBy(response.data, 'languageCode');
			});

			return true;
		} catch (e) {
			const errorMessage = this.locale === 'ar' ? localStorage.getItem('generalErrorAr') : localStorage.getItem('generalErrorEn');

			this.setGeneralError(errorMessage || Config.DEFAULT_STRINGS.generalError[this.locale || 'en']);

			reportExceptionToSentry(e);

			return false;
		}
	}

	async loadI18nList() {
		try {
			const response = await FazaAPI.Common.i18nList();

			runInAction(() => {
				this.i18n = groupBy(response.data.list, 'languageCode');
			});

			return response.data.timestamp;
		} catch (e) {
			const errorMessage = this.locale === 'ar' ? localStorage.getItem('generalErrorAr') : localStorage.getItem('generalErrorEn');

			this.setGeneralError(errorMessage || Config.DEFAULT_STRINGS.generalError[this.locale || 'en']);

			reportExceptionToSentry(e);

			return false;
		}
	}

	async loadI18nTimestamp() {
		try {
			const response = await FazaAPI.Common.i18nTimestamp();

			return response.data;
		} catch (e) {
			reportExceptionToSentry(e);
			
			return false;
		}
	}

	get isAppReadyResolved() {
		for (const step in this.appReady) {
			if (this.appReady[step] === null) {
				return false;
			}
		}

		return true;
	}

	get isAppReady() {
		for (const step in this.appReady) {
			if (!!!this.appReady[step]) {
				return false;
			}
		}

		return true;
	}

	translateMessage(code, fields = {}) {
		let message = code;

		if (!!this.i18n[this.locale] && this.i18n[this.locale].find(t => t.code === code)) {
			message = this.i18n[this.locale].find(t => t.code === code).message;

			for (const field in fields) {
				if (field !== '__links') {
					message = replace(message, new RegExp('{' + field + '}', 'g'), fields[field]);
				}
			}

			if (fields['__links']) {
				const parts = [];
				let position = 0;
				let stage = 'NEUTRAL'; // NEUTRAL, OPENING, MIDDLE, CLOSING
				let expecting = '{';
				let notExpecting = '}';
				let startPosition = null;
				let endPosition = null;
				let linkName = null;

				for (const c of message) {
					if (notExpecting === c) {
						return `Malformed i18n message for code '${code}', encountered unexpected character '${c}' at position '${position}', expecting '${expecting}'.`;
					}

					if (expecting === c) {
						switch (stage) {
							case 'NEUTRAL':
								// An expected char during NEUTRAL stage means begining of opening tag
								startPosition = position;
								expecting = '}';
								notExpecting = null;
								stage = 'OPENING';
								parts.push(message.substring(endPosition ? endPosition + 1 : 0, startPosition));
							break;

							case 'OPENING':
								// We encountered end of opening tag
								endPosition = position;
								expecting = '{';
								notExpecting = '}'
								stage = 'MIDDLE';
								linkName = message.substring(startPosition + 1, endPosition);

								if (!fields['__links'][linkName] || !React.isValidElement(fields['__links'][linkName])/* || fields['__links'][linkName].type.name !== 'Link'*/) {
									return `Required <Link> element ${linkName} for code '${code}' is missing or is not a valid React Link element.`;
								}
							break;

							case 'MIDDLE':
								// We encountered begining of closing tag
								startPosition = position;
								expecting = '}';
								notExpecting = null;
								stage = 'CLOSING';
								parts.push(React.cloneElement(fields['__links'][linkName], {}, [message.substring(endPosition + 1, startPosition)]));
							break;

							case 'CLOSING':
								// We encountered end of closing tag
								if (linkName !== message.substring(startPosition + 1, position)) {
									return `Malformed i18n message for code '${code}', mismatched link tags at position '${startPosition + 1}', expecting '${linkName}' got '${message.substring(startPosition + 1, position)}'.`;
								}

								startPosition = null;
								endPosition = position;
								expecting = '{';
								notExpecting = '}';
								stage = 'NEUTRAL';
							break;

							default:
						}
					}

					position++;
				}

				if (expecting && startPosition) {
					return `Malformed i18n message for code '${code}', expected character '${expecting}' not encounted until end of message after position '${startPosition}'.`;
				}

				if (!startPosition && endPosition && (endPosition + 1) !== position) {
					parts.push(message.substring(endPosition + 1, position));
				}

				return(
					<Fragment>
						{parts.map((p, index) => (
							<Fragment key={index}>
								{p}
							</Fragment>
						))}
					</Fragment>
				);
			}
		}

		return message;
	}

}

decorate(CommonStore, {
	appReady: observable,
	locationReady: observable,
	i18n: observable,
	languages: observable,
	previousLocale: observable,
	locale: observable,
	pageTitlePrefix: observable,
	mobileMenuOpen: observable,
	mobileMenuMainOpen: observable,
	languageDropdownOpen: observable,
	switchDropdownOpen: observable,
	generalError: observable,
	modalVisible: observable,
	modalType: observable,
	modalTitle: observable,
	modalMessage: observable,
	setUserReady: action,
	setLanguagesReady: action,
	setI18nReady: action,
	setAvailableCategoriesReady: action,
	setLocationReady: action,
	setPreviousLocale: action,
	setLocale: action,
	setMobileMenuOpen: action,
	setMobileMenuMainOpen: action,
	setLanguageDropdownOpen: action,
	setSwitchDropdownOpen: action,
	setGeneralError: action,
	setModalVisible: action,
	setModalType: action,
	setModalTitle: action,
	setModalMessage: action,
	isAppReadyResolved: computed,
	isAppReady: computed
});

export default CommonStore;
