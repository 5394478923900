import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Config from '../../../config/Config';
import Swiper from '@eredessil/react-id-swiper';
import imgSliderIconArrowLeft from '../../../assets/img/slider-icon-arrow-left.png';
import imgSliderIconArrowRight from '../../../assets/img/slider-icon-arrow-right.png'

const CategoriesSlider = inject('rootStore')(observer(
	class CategoriesSlider extends Component {

		constructor(props) {
			super(props);

			this.goPrev = this.goPrev.bind(this);
			this.goNext = this.goNext.bind(this);

			this.setCategoriesRef = this.setCategoriesRef.bind(this);
			this.swiperRef = React.createRef();
		}

		goPrev() {
			if (document.documentElement.classList.contains('--rtl')) {
				this.swiperRef.current.swiper.slideNext();
			} else {
				this.swiperRef.current.swiper.slidePrev();
			}
		}

		goNext() {
			if (document.documentElement.classList.contains('--rtl')) {
				this.swiperRef.current.swiper.slidePrev();
			} else {
				this.swiperRef.current.swiper.slideNext();
			}
		}

		componentDidMount() {
			const { categoryStore } = this.props.rootStore;

			categoryStore.loadFeaturedCategories();
		}

		setCategoriesRef(element) {
			const { commonStore } = this.props.rootStore;

			commonStore.categoriesRef = element;
		}

		render() {
			const { locale } = this.props.rootStore.commonStore;
			const { loading, categories } = this.props.rootStore.categoryStore;
			const swiperParams = {
				slidesPerView: 8,
				spaceBetween: 0,
				breakpoints: {
					1500: {
						slidesPerView: 6
					},
					1200: {
						slidesPerView: 4
					},
					800: {
						slidesPerView: 3
					}
				}
			};

			return (
				!loading && categories.length > 0 ? (
					<section className="categories" ref={this.setCategoriesRef}>
						<Swiper {...swiperParams} ref={this.swiperRef} rebuildOnUpdate>
							{categories.map(category => (
								<div key={category.id} className="categories__slide">
									<Link to={`/search?language=${locale}&categories=${encodeURIComponent(category.localData[locale].name)}`} className="categories__link">
										<div className="categories__image-wrap">
											<img className="categories__image" src={`${Config.UPLOADS_ENDPOINT}${category.categoryImageUri}`} alt={category.localData[locale].name} title={category.localData[locale].name} />
										</div>

										<h4 className="categories__name">{category.localData[locale].name}</h4>
									</Link>
								</div>
							))}
						</Swiper>

						<div className="categories__arrow categories__arrow--left" onClick={this.goPrev}>
							<img className="categories__arrow-image" src={imgSliderIconArrowLeft} alt="Slide left" />
						</div>

						<div className="categories__arrow categories__arrow--right" onClick={this.goNext}>
							<img className="categories__arrow-image" src={imgSliderIconArrowRight} alt="Slide right" />
						</div>
					</section>
				) : null
			);
		}

	}
));

export default CategoriesSlider;
