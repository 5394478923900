import { env } from '../env';

const Config = {

	RESULTS_PER_PAGE: 20,
	RESULTS_PER_PAGE_MAX: 100,

	MAX_QUICKSEARCH_RESULTS: 10,

	GOOGLE_MAPS_API_KEY: 'AIzaSyD3X6X2ETurgStZh4CC1vuE5dYbVKMDRx0',
	GOOGLE_MAPS_DEFAULT_ZOOM: 16,

	SMS_VERIFICATION_TIMEOUT_SECONDS: 30,

	EMAIL_VERIFICATION_TIMEOUT_SECONDS: 180, // 3 minutes

	MAX_CART_ITEMS_ADD: 10,

	SESSION_TIMEOUT: 7200, // seconds

	IPSTACK_API_ACCESS_KEY: '385387656168d4cb298371a48d88f53a', //'2f1f8b6186e3635a065e81ad6e137690',

	PRODUCT_CREDIT_CARD_FEE: 1.02,

	RH_STORE_URL: 'https://fazaastores.com/checkoutlogin?token=',

	// Dynamic (extra) offers
	HIDE_DYNAMIC_OFFERS: true,

	// Default strings used as fallback
	DEFAULT_STRINGS: {
		generalError: {
			en: 'We are experiencing technical difficulties, please try again later. Sorry for the inconvenience.',
			ar: 'نواجه صعوبات تقنية، يرجى المحاولة مرة أخرى لاحقًا. نأسف للإزعاج.'
		}
	},
	
};

const UAEPassConfig = {
	staging: {
		UAE_PASS_ENDPOINT_AUTH: 'https://stg-id.uaepass.ae/idshub/authorize',
		UAE_PASS_ENDPOINT_TOKEN: 'https://stg-id.uaepass.ae/idshub/token',
		UAE_PASS_ENDPOINT_USER: 'https://stg-id.uaepass.ae/idshub/userinfo',
		UAE_PASS_ENDPOINT_LOGOUT: 'https://stg-id.uaepass.ae/idshub/logout',
		UAE_PASS_CLIENT_ID: 'fazaa_web_stage'
	},
	production: {
		UAE_PASS_ENDPOINT_AUTH: 'https://id.uaepass.ae/idshub/authorize',
		UAE_PASS_ENDPOINT_TOKEN: 'https://id.uaepass.ae/idshub/token',
		UAE_PASS_ENDPOINT_USER: 'https://id.uaepass.ae/idshub/userinfo',
		UAE_PASS_ENDPOINT_LOGOUT: 'https://id.uaepass.ae/idshub/logout',
		UAE_PASS_CLIENT_ID: 'fazaa_web_prod'
	}
};

if (env.REACT_APP_NODE_ENV === 'production') {
	Config.API_BASE = env.REACT_APP_API_BASE || 'https://api.fazaa.ae';

	Config.API_ENDPOINT = `${Config.API_BASE}`;
	Config.PUBLIC_API_ENDPOINT = `${Config.API_BASE}/@public/api`;
	Config.UAEPASS_API_ENDPOINT = `${Config.API_BASE}/@uaepass`;
	Config.MEMBERS_API_ENDPOINT = `${Config.API_BASE}/@member`;
	Config.LEGACY_API_ENDPOINT = `${Config.API_BASE}/@legacy`;
	Config.UPLOADS_ENDPOINT = env.REACT_APP_UPLOADS_ENDPOINT || 'https://www.fazaa.ae';
	Config.CASHIER_ENDPOINT = `${Config.UPLOADS_ENDPOINT}/admin/cashier/checkout?token=`

	Object.assign(Config, UAEPassConfig.production);

	Config.UAE_PASS_CALLBACK_URL = `${Config.UPLOADS_ENDPOINT}/uae-sso`;

	Config.NEW_FAZAA_API_ENDPOINT = 'https://fznewapi.ssf.ae/api';
}

if (env.REACT_APP_NODE_ENV === 'staging') {
	Config.API_BASE = env.REACT_APP_API_BASE || 'https://fzstage.ssf.ae';

	Config.API_ENDPOINT = `${Config.API_BASE}`;
	Config.PUBLIC_API_ENDPOINT = `${Config.API_BASE}/@public/api`;
	Config.UAEPASS_API_ENDPOINT = `${Config.API_BASE}/@uaepass`;
	Config.MEMBERS_API_ENDPOINT = `${Config.API_BASE}/@member`;
	Config.LEGACY_API_ENDPOINT = `${Config.API_BASE}/@legacy`;
	Config.UPLOADS_ENDPOINT = env.REACT_APP_UPLOADS_ENDPOINT || 'https://fzstage.ssf.ae';
	Config.CASHIER_ENDPOINT = `${Config.UPLOADS_ENDPOINT}/admin/cashier/checkout?token=`

	Object.assign(Config, UAEPassConfig.staging);

	Config.UAE_PASS_CALLBACK_URL = `${Config.UPLOADS_ENDPOINT}/uae-sso`;

	Config.NEW_FAZAA_API_ENDPOINT = 'https://fzstage.ssf.ae/api';
}

if (env.REACT_APP_NODE_ENV === 'tst') {
	Config.API_BASE = env.REACT_APP_API_BASE || 'https://fztst.fazaa.ae';

	Config.API_ENDPOINT = `${Config.API_BASE}`;
	Config.PUBLIC_API_ENDPOINT = `${Config.API_BASE}/@public/api`;
	Config.UAEPASS_API_ENDPOINT = `${Config.API_BASE}/@uaepass`;
	Config.MEMBERS_API_ENDPOINT = `${Config.API_BASE}/@member`;
	Config.LEGACY_API_ENDPOINT = `${Config.API_BASE}/@legacy`;
	Config.UPLOADS_ENDPOINT = env.REACT_APP_UPLOADS_ENDPOINT || 'https://fztst.fazaa.ae';
	Config.CASHIER_ENDPOINT = `${Config.UPLOADS_ENDPOINT}/admin/cashier/checkout?token=`

	Object.assign(Config, UAEPassConfig.staging);

	Config.UAE_PASS_CALLBACK_URL = `${Config.UPLOADS_ENDPOINT}/uae-sso`;

	Config.NEW_FAZAA_API_ENDPOINT = 'https://fztst.ssf.ae/api';
}

if (env.REACT_APP_NODE_ENV === 'swarm-test') {
	Config.API_BASE = env.REACT_APP_API_BASE || 'https://testapi.fazaa.ae';

	Config.API_ENDPOINT = `${Config.API_BASE}`;
	Config.PUBLIC_API_ENDPOINT = `${Config.API_BASE}/@public/api`;
	Config.UAEPASS_API_ENDPOINT = `${Config.API_BASE}/@uaepass`;
	Config.MEMBERS_API_ENDPOINT = `${Config.API_BASE}/@member`;
	Config.LEGACY_API_ENDPOINT = `${Config.API_BASE}/@legacy`;
	Config.UPLOADS_ENDPOINT = env.REACT_APP_UPLOADS_ENDPOINT || 'https://testapi.fazaa.ae';
	Config.CASHIER_ENDPOINT = `${Config.UPLOADS_ENDPOINT}/admin/cashier/checkout?token=`

	Object.assign(Config, UAEPassConfig.staging);

	Config.UAE_PASS_CALLBACK_URL = `${Config.UPLOADS_ENDPOINT}/uae-sso`;
}

if (!env.REACT_APP_NODE_ENV || env.REACT_APP_NODE_ENV === 'development') {
	Config.API_BASE = env.REACT_APP_API_BASE || 'https://fzdev.ssf.ae';

	Config.API_ENDPOINT = `${Config.API_BASE}`;
	Config.PUBLIC_API_ENDPOINT = `${Config.API_BASE}/@public/api`;
	Config.UAEPASS_API_ENDPOINT = `${Config.API_BASE}/@uaepass`;
	Config.MEMBERS_API_ENDPOINT = `${Config.API_BASE}/@member`;
	Config.LEGACY_API_ENDPOINT = `${Config.API_BASE}/@legacy`;
	Config.UPLOADS_ENDPOINT = env.REACT_APP_UPLOADS_ENDPOINT || 'https://fzdev.ssf.ae';
	Config.CASHIER_ENDPOINT = `${Config.UPLOADS_ENDPOINT}/admin/cashier/checkout?token=`

	Object.assign(Config, UAEPassConfig.staging);

	Config.UAE_PASS_CALLBACK_URL = `${Config.UPLOADS_ENDPOINT}/uae-sso`;

	// This does not exist!
	Config.NEW_FAZAA_API_ENDPOINT = 'https://fzdev.ssf.ae/api';
}

// For local development
if (!env.REACT_APP_NODE_ENV) {
	Config.UAE_PASS_CLIENT_ID = 'sandbox_stage';
	Config.UAE_PASS_CALLBACK_URL = 'http://localhost:3000/uae-sso';
}

Object.freeze(Config);

export default Config;
