import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import Config from '../config/Config';
import { reportExceptionToSentry } from '../utils/Utils';

class OfferStore {

	loading = false;
	offers = new Map();
	dynamicOffers = new Map();
	dynamicOfferTokens = new Map();
	viewingOffer = false;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	setLoading(loading) {
		this.loading = loading;
	}

	async loadAllOffers() {
		this.loading = true;

		this.offers.clear();

		try {
			const response = await FazaaAPI.Offers.all();

			runInAction(() => {
				response.data.forEach(offer => this.offers.set(offer.offerSlug, offer));
			});
		} catch (e) {
			reportExceptionToSentry(e, [401]);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async loadFeaturedOffers() {
		this.loading = true;

		this.offers.clear();

		try {
			const response = await FazaaAPI.Offers.featured();

			runInAction(() => {
				response.data.forEach(offer => this.offers.set(offer.offerSlug, offer));
			});
		} catch (e) {
			reportExceptionToSentry(e, [401]);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async loadOffer(slug) {
		if (this.offers.has(slug)) {
			this.loading = false;

			return;
		}

		this.loading = true;

		try {
			const response = await FazaaAPI.Offers.get(slug);
			const offer = response.data;

			runInAction(() => {
				this.offers.set(offer.offerSlug, offer);
			});
		} catch (e) {
			reportExceptionToSentry(e, [404]);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	getOffer(id) {
		return this.offers.get(id);
	}

	setOffer(slug, offer) {
		this.offers.set(slug, offer);
	}

	clearOffers() {
		this.offers.clear();
	}

	async loadDynamicOffer(slug, force = false) {
		if (this.dynamicOffers.has(slug) && !force) {
			this.loading = false;

			return;
		}

		this.loading = true;

		try {
			const response = await FazaaAPI.Offers.getDynamic(slug);
			const offer = response.data;

			runInAction(() => {
				this.dynamicOffers.set(offer.offer.offerSlug, offer.offer);
				this.dynamicOfferTokens.set(offer.offer.offerSlug, offer.token);
			});
		} catch (e) {
			reportExceptionToSentry(e, [404]);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	getDynamicOffer(slug) {
		if (Config.HIDE_DYNAMIC_OFFERS) {
			return null;
		}
		
		return this.dynamicOffers.get(slug);
	}

	setDynamicOffer(slug, offer) {
		this.dynamicOffers.set(slug, offer);
	}

	clearDynamicOffers() {
		this.dynamicOffers.clear();
	}

	getDynamicOfferToken(slug) {
		return this.dynamicOfferTokens.get(slug);
	}

	setDynamicOfferToken(slug, token) {
		this.dynamicOfferTokens.set(slug, token);
	}

	clearDynamicOfferTokens() {
		this.dynamicOfferTokens.clear();
	}

	setViewingOffer(viewingOffer) {
		this.viewingOffer = viewingOffer;
	}

}

decorate(OfferStore, {
	loading: observable,
	offers: observable,
	dynamicOffers: observable,
	viewingOffer: observable,
	setLoading: action,
	loadAllOffers: action,
	loadFeaturedOffers: action,
	loadOffer: action,
	setOffer: action,
	clearOffers: action,
	loadDynamicOffer: action,
	setDynamicOffer: action,
	clearDynamicOffers: action,
	setDynamicOfferToken: action,
	clearDynamicOfferTokens: action,
	setViewingOffer: action
});

export default OfferStore;
