import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import ReactLoading from 'react-loading';

const Partner = inject('rootStore')(observer(
	class Partner extends Component {

		constructor(props) {
			super(props);

			this.handleSuggestPartnerClick = this.handleSuggestPartnerClick.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;
				const { authenticated } = props.rootStore.userStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + (authenticated ? translateMessage('page.partner.title.suggestPartner') : translateMessage('page.partner.title.becomePartner'));
			});

			this.emailRef = undefined;
		}

		componentDidMount() {
			const { userStore } = this.props.rootStore;

			if (!userStore.authenticated) {
				this.props.history.replace('/page/become-fazaa-partner');
			}

			this.resetForm();
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		resetForm = () => {
			const { userStore } = this.props.rootStore;
			
			userStore.setError(false);
			userStore.setSuccess(false);

			userStore.setBusinessName('');
			userStore.setBusinessEmail('');
			userStore.setBusinessPhone('');
		};

		handleBusinessNameChange = e => this.props.rootStore.userStore.setBusinessName(e.target.value);
		handleBusinessEmailChange = e => this.props.rootStore.userStore.setBusinessEmail(e.target.value);
		handleBusinessPhoneChange = e => {
			if (e.target.validity.valid) {
				this.props.rootStore.userStore.setBusinessPhone(e.target.value);
			}
		}

		handleSuggestPartnerClick(e) {
			e.preventDefault();

			const { userStore } = this.props.rootStore;

			userStore.suggestPartner();
		}

		render() {
			const { authenticated, businessName, businessEmail, businessPhone, error, success, loading } = this.props.rootStore.userStore;
			const suggestPartnerDisabled = !!!businessName || !!!businessEmail || !!!businessPhone || (this.emailRef && !this.emailRef.validity.valid);

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						{authenticated ? (
							<Localized code="page.partner.title.suggestPartner" />
						) : (
							<Localized code="page.partner.title.becomePartner" />
						)}
					</h2>

					<div className="section__wrapper">
						<div className="become-partner">
							{!!success ? (
							<p className="section__text">{success}</p>
							) : (
							<div className="become-partner__form">
								<form className="form">
									{!!error ? (
									<div className="form__message form__message--error">{error}</div>
									) : null}

									<div className="form__group">
										<label className="form__label" htmlFor="businessName">
											<Localized code="page.partner.label.businessName" />
										</label>
										<input className="form__input" type="text" id="businessName" value={businessName} onChange={this.handleBusinessNameChange} />
									</div>

									<div className="form__group">
										<label className="form__label" htmlFor="businessEmail">
											<Localized code="page.partner.label.businessEmail" />
										</label>
										<input ref={el => this.emailRef = el} dir="ltr" className="form__input" type="email" id="businessEmail" value={businessEmail} onChange={this.handleBusinessEmailChange} />
									</div>

									<div className="form__group">
										<label className="form__label" htmlFor="businessPhone">
											<Localized code="page.partner.label.businessPhone" />
										</label>
										<input className="form__input" type="text" id="businessPhone" pattern="^\+?[0-9]*$" value={businessPhone} onChange={this.handleBusinessPhoneChange} />
									</div>

									<div className="form__buttons">
										<button disabled={loading || suggestPartnerDisabled} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleSuggestPartnerClick}>
											{loading ? (
												<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
											) : null}
											{authenticated ? (
												<Localized code="page.partner.button.suggestPartner" />
											) : (
												<Localized code="page.partner.button.becomePartner" />
											)}
										</button>
									</div>
								</form>
							</div>
							)}
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Partner;
