import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FormRadioButton extends Component {

	constructor(props) {
		super(props);

		this.handleOnChange = this.handleOnChange.bind(this);
	}

	handleOnChange(e) {
		this.props.onChange(e);
	}

	render() {
		const { name, value, label, current } = this.props;
		const selected = String(value) === String(current);

		return (
			<div className={`form__radio ${selected ? 'form__radio--checked' : ''}`}>
				<label className="form__radio-label">
					<input className="form__radio-input" type="radio" name={name} value={value} onChange={this.handleOnChange} checked={selected} /> {label}
				</label>
			</div>
		);
	}

}

FormRadioButton.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
	label: PropTypes.string.isRequired,
	current: PropTypes.any.isRequired,
	onChange: PropTypes.func.isRequired
};

export default FormRadioButton;
