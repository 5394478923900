import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import ProductRating from './ProductRating';
import Localized from '../i18n/Localized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShoppingCart, faTruck } from '@fortawesome/free-solid-svg-icons';
import ReactImageMagnify from 'react-image-magnify';
import { Link } from 'react-router-dom';
import Config from '../../../config/Config';
import Swiper from '@eredessil/react-id-swiper';

import imgSliderIconArrowLeft from '../../../assets/img/slider-icon-arrow-left.png'
import imgSliderIconArrowRight from '../../../assets/img/slider-icon-arrow-right.png'

const Product = inject('rootStore')(observer(
	class Product extends Component {

		constructor(props) {
			super(props);

			this.state = {
				qty: 1,
				working: false
			};

			this.swiperRef = React.createRef();
			this.magnifyRef = React.createRef();
		}

		componentDidUpdate(prevProps) {
			if (prevProps.listingType !== this.props.listingType) {
				if (this.magnifyRef.current) {
					this.magnifyRef.current.setSmallImageDimensionState();
				}
			}
		}

		goPrev = () => {
			if (document.documentElement.classList.contains('--rtl')) {
				this.swiperRef.current.swiper.slideNext();
			} else {
				this.swiperRef.current.swiper.slidePrev();
			}
		}

		goNext = () => {
			if (document.documentElement.classList.contains('--rtl')) {
				this.swiperRef.current.swiper.slidePrev();
			} else {
				this.swiperRef.current.swiper.slideNext();
			}
		}

		handleSlideClick = src => {
			const { estoreStore } = this.props.rootStore;

			estoreStore.setCurrentProductImage(Config.UPLOADS_ENDPOINT + src);

			if (this.magnifyRef.current) {
				this.magnifyRef.current.setSmallImageDimensionState();
			}
		}

		handleQtyClick = e => {
			e.preventDefault();
		}

		handleQtyChange = e => {
			this.setState({
				qty: e.target.value
			});
		}

		handleAddToCartClick = async e => {
			e.preventDefault();

			const { partial, product } = this.props;
			const { cartStore, estoreStore, commonStore: { translateMessage } } = this.props.rootStore;
			let productInfo = product;

			if (cartStore.added) {
				return;
			}

			this.setState({
				working: true
			});

			if (partial) {
				const loadedProduct = await estoreStore.loadProduct(product.productSlug, true);

				productInfo = loadedProduct.product;
			} else {
				productInfo = product;
			}

			const stockCheck = await estoreStore.checkStock(product.id, Number(this.state.qty));

			this.setState({
				working: false
			});

			if (stockCheck) {
				if (cartStore.addToCart({
						type: 'PRODUCT',
						product: productInfo,
						qty: Number(this.state.qty)
					})
				) {
					document.documentElement.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				} else {
					alert(translateMessage('page.cart.text.addMax', {
						amount: Config.MAX_CART_ITEMS_ADD
					}));
				}
			} else {
				alert(translateMessage('page.cart.text.addOverStock'));
			}
		}

		wrapWithLink = component => {
			const { display, product } = this.props;

			if (display) {
				return (
					<div className="estore-page__product-link">
						{component}
					</div>
				);
			} else {
				return (
					<Link className="estore-page__product-link" to={`/store/product/${product.productSlug}`}>
						{component}
					</Link>
				);
			}
		}

		render() {
			const { display, product, delay, listingType, locale } = this.props;
			const { cart } = this.props.rootStore.cartStore;
			const hasDiscount = product.productDiscountPrice < product.productOriginalPrice;
			const discountPercent = ((100 * product.productDiscountPrice) / product.productOriginalPrice).toFixed(0);
			const swiperParams = {
				slidesPerView: 6,
				spaceBetween: 10,
				breakpoints: {
					1500: {
						slidesPerView: 4
					},
					1000: {
						slidesPerView: 2
					},
					650: {
						slidesPerView: 4
					}

				}
			};
			const { currentProductImage } = this.props.rootStore.estoreStore;
			const magnifySrc = display ? (currentProductImage ? currentProductImage : Config.UPLOADS_ENDPOINT + product.productMainImageUri) : Config.UPLOADS_ENDPOINT + product.mainImageUri;
			// const outOfStock = product.productStock === 0;
			// const productExpired = moment(product.productExpiry).isBefore();
			const inCart = product ? cart.filter(i => i.type === 'PRODUCT' && i.product.id === product.id).reduce((a, i) => a + i.qty, 0) : 0;

			return (
				<div className="estore-page__product" style={delay ? { transitionDelay: `${delay}ms` } : {}}>
					{this.wrapWithLink(
						<Fragment>
							<div className="estore-page__product-images">
								<div className="estore-page__product-image-wrapper">
									<ReactImageMagnify ref={this.magnifyRef} className="estore-page__product-image" {...{
										smallImage: {
											alt: '',
											isFluidWidth: true,
											src: magnifySrc
										},
										largeImage: {
											src: magnifySrc,
											width: 800,
											height: 600
										},
										enlargedImagePosition: 'over',
										enlargedImageContainerClassName: 'estore-page__product-image-large'
									}} />

									{listingType === 'list' ? (
										hasDiscount ? (
											<div className="estore-page__product-tag estore-page__product-tag--discount estore-page__product-tag--image">
												<Localized code="estore.text.discount" value={discountPercent} />
											</div>
										) : null
									) : null}
								</div>

								{display ? (
									<div className="estore-page__gallery">
										<div className="estore-page__gallery-left" onClick={this.goPrev}>
											<img className="estore-page__gallery-arrow" src={imgSliderIconArrowLeft} alt="Slide left" />
										</div>

										<Swiper {...swiperParams} ref={this.swiperRef} rebuildOnUpdate>
											<div className="estore-page__slide" onClick={() => this.handleSlideClick(product.productMainImageUri)}>
												<img className="estore-page__image" src={`${Config.UPLOADS_ENDPOINT}${product.productMainImageUri}`} alt="" />
											</div>
											{product.images.map(image => (
												<div key={image.id} className="estore-page__slide" onClick={() => this.handleSlideClick(image.productImageUri)}>
													<img className="estore-page__image" src={`${Config.UPLOADS_ENDPOINT}${image.productImageUri}`} alt="" />
												</div>
											))}
										</Swiper>

										<div className="estore-page__gallery-right" onClick={this.goNext}>
											<img className="estore-page__gallery-arrow" src={imgSliderIconArrowRight} alt="Slide right" />
										</div>
									</div>
								) : null}
							</div>

							<div className="estore-page__product-info">
								<h4 className="estore-page__product-title">
									<span className="estore-page__product-title-text">{display ? product.localData[locale].title : product.title}</span>
									
									{false ? (
										<button className="estore-page__product-favorite">
											<FontAwesomeIcon className="estore-page__product-favorite-icon" icon={faHeart} />
										</button>
									) : null}
								</h4>

								<div className="estore-page__product-price">
									{product.productDiscountPrice < product.productOriginalPrice ? (
										<div className="estore-page__product-price-old">AED {product.productOriginalPrice}</div>
									) : null}
									<div className="estore-page__product-price-current">AED {product.productDiscountPrice < product.productOriginalPrice ? product.productDiscountPrice : product.productOriginalPrice}</div>
								</div>

								{listingType === 'list' ? (
									<Fragment>
										<div className="estore-page__product-description">
											{display ? (
												<div className="estore-page__product-description-text cms-generated" dangerouslySetInnerHTML={{__html: product.localData[locale].longDescription}} />
											) : (
												<div className="estore-page__product-description-text cms-generated" dangerouslySetInnerHTML={{__html: product.shortDescription}} />
											)}

											{false ? (
												<ProductRating rating={0} reviewCount={0} />
											) : null}
										</div>

										<div className="estore-page__product-features">
											<div className="estore-page__product-feature">
												<FontAwesomeIcon className="estore-page__product-feature-icon" icon={faTruck} />
												<Localized code="estore.text.productDelivery" />
											</div>
										</div>
									</Fragment>
								) : null}

								{listingType !== 'list' ? (
									hasDiscount ? (
										<div className="estore-page__product-tags">
											<div className="estore-page__product-tag estore-page__product-tag--discount estore-page__product-tag--info">
												<Localized code="estore.text.discount" value={discountPercent} />
											</div>
										</div>
									) : null
								) : null}

								<div className="estore-page__product-buttons">
									{product.productStock > 0 ? (
										<div className="estore-page__qty-select-wrap">
											<select className="estore-page__qty-select" value={this.state.qty} onChange={this.handleQtyChange} onClick={this.handleQtyClick}>
												{Array.from(Array(Math.min(product.productStock, Config.MAX_CART_ITEMS_ADD)), (e, i) => {
													return <option key={i} value={i + 1}>{i + 1}</option>
												})}
											</select>
										</div>
									) : null}

									<button disabled={product.productStock - inCart <= 0}  className="button button--gold button--small estore-page__product-button" onClick={this.handleAddToCartClick}>
										<span className="button__text button__text--full">
											{product.productStock - inCart > 0 ? <Localized code="estore.button.addToCart" /> : <Localized code="estore.button.outOfStock" />}
										</span>
										<span className="button__text button__text--short">
											{product.productStock - inCart > 0 ? <Localized code="estore.button.addToCartShort" /> : <Localized code="estore.button.outOfStock" />}
										</span>
										<FontAwesomeIcon className="button__icon button__icon--right" icon={faShoppingCart} />
									</button>
								</div>

								{inCart ? (
									<p className="estore-page__in-cart">
										<Localized code="generic.text.inCart" inCart={inCart} />
									</p>
								) : null}
							</div>
						</Fragment>
					)}
				</div>
			);
		}

	}
));

export default Product;
