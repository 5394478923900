import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CategoriesStrip = inject('rootStore')(observer(
	class CategoriesStrip extends Component {

		render() {
			const { locale } = this.props.rootStore.commonStore;
			const { categories } = this.props;

			return (
				categories.length > 0 ? (
					<div className="categories-strip">
						<ul className="categories-strip__list">
							{categories.map(category => (
								<li key={category.id} className="categories-strip__tag">
									<Link className="categories-strip__link" to={`/search?language=${locale}&categories=${encodeURIComponent(category.localData[locale].name)}`}>
										{category.localData[locale].name}
									</Link>
								</li>
							))}
						</ul>
					</div>
				) : null
			);
		}

	}
));

CategoriesStrip.propTypes = {
	categories: PropTypes.array.isRequired
};

export default CategoriesStrip;
