import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import QrCode from 'react-qrcode-svg';
// import Barcode from 'react-barcode';
import moment from 'moment-timezone';
import ReactLoading from 'react-loading';
import Config from '../../../config/Config';
import { reportExceptionToSentry } from '../../../utils/Utils';
import { union, without } from 'lodash';

moment.tz.setDefault('Asia/Dubai');

const Order = inject('rootStore')(observer(
	class Order extends Component {

		constructor(props) {
			super(props);

			this.state = {
				order: null,
				copied: []
			};

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.order.title');
			});
		}

		componentDidMount() {
			const { id } = this.props.match.params;
			const { orderStore, userStore } = this.props.rootStore;

			orderStore.loadOrder(id).then(order => {
				this.setState({ order });
			});

			userStore.loadLevels();
			userStore.loadLocations();
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		handlePDFClick = (idOrder, idVoucher) => {
			const { orderStore } = this.props.rootStore;

			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				orderStore.loadOrderPDF(idOrder, idVoucher).then(response => {
					const file = new Blob([response], {
						type: 'application/pdf'
					});

					window.navigator.msSaveOrOpenBlob(file, 'voucher-' + idVoucher + '.pdf');
				}).catch(e => {
					reportExceptionToSentry(e);
				});
			} else {
				const win = window.open();

				if (win) {
					win.document.write('<p>PDF loading ...</p>');
				}

				orderStore.loadOrderPDF(idOrder, idVoucher).then(response => {
					if (response) {
						const file = new Blob([response], {
							type: 'application/pdf'
						});

						const fileURL = URL.createObjectURL(file);

						if (win) {
							win.location = fileURL;

							win.focus();
						}
					} else {
						if (win) {
							win.close();
						}
					}
				}).catch(e => { // TODO Do we need this?
					reportExceptionToSentry(e);

					if (win) {
						win.close();
					}
				});
			}
		}

		handleCopyCode = code => {
			navigator.clipboard.writeText(code).then(() => {
				this.setState(state => {
					return {
						copied: union(state.copied, [code])
					}
				}, () => {
					setTimeout(() => {
						this.setState(state => {
							return {
								copied: without(state.copied, code)
							}
						})
					}, 2000)
				})
			});
		};

		render() {
			const { locale } = this.props.rootStore.commonStore;
			const { loading, pdfLoading } = this.props.rootStore.orderStore;
			const { levels, shippingLocations: { locations } } = this.props.rootStore.userStore;
			const { order, copied } = this.state;
			const location = order && order.productOrder && locations && locations.find(location => location.id === order.productOrder.shippingLocationId) ? locations.find(location => location.id === order.productOrder.shippingLocationId) : null;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.order.title" />
					</h2>

					<div className="section__wrapper">
						<div className="order-page">
							{!loading ? (
								order ? (
									<Fragment>
										{order.archived ? (
										<p className="order-page__archived">
											<Localized code="page.order.text.archivedOrder" />
										</p>
										) : null}

										<h3 className="section__subtitle">
											<Localized code="page.order.text.orderNumber" orderId={order.orderId} />
										</h3>

										<div className="order-details">
											<div className="order-details__item">
												<div className="order-details__heading">
													<Localized code="page.order.text.created" />
												</div>
												<div className="order-details__content">
													{moment(order.createdAt).format('DD/MM/YY')}
												</div>
											</div>

											<div className="order-details__item">
												<div className="order-details__heading">
													<Localized code="page.order.text.amount" />
												</div>
												<div className="order-details__content order-details__content--amount">
													{order.total} AED
												</div>
											</div>

											<div className="order-details__item">
												<div className="order-details__heading">
													<Localized code="page.order.text.updated" />
												</div>
												<div className="order-details__content">
													{moment(order.statusChanged).format('DD/MM/YY @ HH:mm')}
												</div>
											</div>

											<div className="order-details__item">
												<div className="order-details__heading">
													<Localized code="page.order.text.status" />
												</div>
												<div className={`order-details__content order-details__content--status-${order.status.toLowerCase()}`}>
													{order.status}
												</div>
											</div>
										</div>

										{order.membership && order.membership.length > 0 ? (
											order.membership.map(membership => {
												if (membership.action === 'UPGRADE') {
													const level = levels && levels.length > 0 ? levels.find(l => l.id === membership.levelId).levelName : '...';
													
													return (
														<Fragment key={membership.action}>
															<h3 className="section__subtitle">
																<Localized code="page.order.subtitle.upgrade" />
															</h3>

															<p className="section__text">
																<Localized code="page.order.text.upgraded" level={level} />
															</p>
														</Fragment>
													)
												}

												if (membership.action === 'RENEW') {
													return (
														<Fragment key={membership.action}>
															<h3 className="section__subtitle">
																<Localized code="page.order.subtitle.renew" />
															</h3>

															<p className="section__text">
																<Localized code="page.order.text.renewed" expiryDate={membership.expiryDate} />
															</p>
														</Fragment>
													)
												}

												if (membership.action === 'REGISTRATION') {
													return (
														<Fragment key={membership.action}>
															<h3 className="section__subtitle">
																<Localized code="page.order.subtitle.registration" />
															</h3>

															<p className="section__text">
																<Localized code="page.order.text.registration" expiryDate={membership.expiryDate} />
															</p>
														</Fragment>
													)
												}

												return null;
											})
										) : null}

										{!order.membership || order.membership.length === 0 ? (
											<Fragment>
												<h3 className="section__subtitle">
													<Localized code="page.order.subtitle.vouchers" />
												</h3>

												{order.vouchers.length > 0 ? (
													order.vouchers.map(voucher => (
														<div key={voucher.voucher.id} className="order-voucher">
															<div className="order-voucher__header">
																<div className="order-voucher__header-item">
																	<div className="order-voucher__partner">{voucher.voucher.partner.partnerName}</div>
																	<div className="order-voucher__title">{voucher.voucher.localData[locale].subTitle}</div>
																</div>

																<div className="order-voucher__header-item order-voucher__header-item--no-flex">
																	<div className="order-voucher__header-label">
																		<Localized code="page.order.text.qty" />
																	</div>
																	<div className="order-voucher__header-content">{voucher.items.length}</div>
																</div>

																<div className="order-voucher__header-item order-voucher__header-item--no-flex">
																	<div className="order-voucher__header-label">
																		<Localized code="page.order.text.price" />
																	</div>
																	<div className="order-voucher__header-content">{voucher.voucher.voucherPrice} AED</div>
																</div>
															</div>

															<div className="order-voucher__vouchers">
																{voucher.items.map(item => (
																	<div key={item.mediaCode} className="order-voucher__voucher-item">
																		<div className="order-voucher__voucher-item-code">
																			{item.codeType === 'QRCODE' ? (
																				<QrCode
																					data={item.mediaCode}
																					height="100"
																					width="100"
																					fgColor="#000"
																					bgColor="#FFF"
																					margin={0}
																				/>
																			) : null}

																			{item.codeType === 'BARCODE' ? (
																				null
																			) : null}
																			
																			{item.codeType === 'PLAIN' ? (
																				item.mediaCode
																			) : null}

																			{item.codeType === 'CODE_ONLY' ? (
																				<Fragment>
																					<input readOnly className="order-voucher__voucher-item-code-selectable" value={item.mediaCode} onFocus={e => e.target.select()} />
																					<button className="button button--small button--blue" onClick={() => this.handleCopyCode(item.mediaCode)}>
																						{copied.includes(item.mediaCode) ? (
																							<Localized code="page.order.button.copied" />
																						) : (
																							<Localized code="page.order.button.copyCode" />
																						)}
																					</button>
																				</Fragment>
																			) : null}
																		</div>

																		<div className="order-voucher__voucher-item-meta">
																			<Localized code="page.order.text.mediaCode" mediaCode={item.mediaCode} />
																			<br />
																			<Localized code="page.order.text.startDate" startDate={moment(item.startDate).format('DD/MM/YY')} />
																			<br />
																			<Localized code="page.order.text.expiryDate" expiryDate={moment(item.expiryDate).format('DD/MM/YY')} />
																			<br />
																			<Localized code="page.order.text.pnrCode" pnrCode={item.pnrCode} />
																		</div>

																		<div className="order-voucher__pdf">
																			<button disabled={pdfLoading.includes(item.id)} className="button button--small button--blue button--has-loading" onClick={() => this.handlePDFClick(order.id, item.id)}>
																				{pdfLoading.includes(item.id) ? (
																					<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
																				) : null}
																				<Localized code="page.order.button.pdf" />
																			</button>
																		</div>
																	</div>
																))}
															</div>

															<div className="order-voucher__terms">
																<div className="order-voucher__terms-container cms-generated" dangerouslySetInnerHTML={{__html: voucher.voucher.localData[locale].description}} />
															</div>
														</div>
													))
												) : (
													<p className="section__text">
														<Localized code="page.order.text.noVouchers" />
													</p>
												)}

												<h3 className="section__subtitle">
													<Localized code="page.order.subtitle.products" />
												</h3>

												{order.productOrder && order.productOrder.items.length > 0 ? (
													<div className="order-products">
														<div className="order-products__header">
															<div className="order-products__header-item">
																<div className="order-products__header-label">
																	<Localized code="page.order.text.shippingLocation" />
																</div>
																<div className="order-products__header-content">
																	{location ? (
																		<address className="order-products__header-content-address">
																			{location.fullName}, {location.address}, {location.city}, {location.mobile}
																		</address>
																	) : null}
																</div>
															</div>

															<div className="order-products__header-item order-products__header-item--no-flex">
																<div className="order-products__header-label">
																	<Localized code="page.order.text.total" />
																</div>
																<div className="order-products__header-content">
																	{order.productOrder.total} AED
																</div>
															</div>

															<div className="order-products__header-item order-products__header-item--no-flex">
																<div className="order-products__header-label">
																	<Localized code="page.order.text.status" />
																</div>
																<div className={`order-products__header-content order-products__header-content--status order-products__header-content--status-${order.productOrder.status.toLowerCase()}`}>{order.productOrder.status}</div>
															</div>
														</div>

														<div className="order-products__products">
															{order.productOrder.items.map(product => (
																<div key={product.productSlug} className="order-product">
																	<div className="order-product__image-wrapper">
																		<img className="order-product__image" src={Config.UPLOADS_ENDPOINT + product.productMainImageUri} alt={product.localData[locale].title} />
																	</div>
																	<div className="order-product__meta">
																		<div className="order-product__title">{product.localData[locale].title}</div>
																		<div className="order-product__price">
																			<Localized code="page.order.text.unitPrice" unitPrice={product.productPrice} />
																		</div>
																		<div className="order-product__shipping">
																			<Localized code="page.order.text.shippingCost" shippingCost={product.productShippingCost} />
																		</div>
																	</div>
																	<div className="order-product__qty">&times;{product.productQty}</div>
																	<div className="order-product__subtotal">{product.productPrice * product.productQty + product.productShippingCost * product.productQty} AED</div>
																</div>
															))}
														</div>
													</div>
												) : (
													<p className="section__text">
														<Localized code="page.order.text.noProducts" />
													</p>
												)}
											</Fragment>
										) : null}
									</Fragment>
								) : (
									<p className="section__text">
										<Localized code="page.order.text.cantFindOrder" />
									</p>
								)
							) : null}
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Order;
