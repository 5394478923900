import React, { Component } from 'react';
import { observer } from 'mobx-react';

const FormError = observer(
	class FormError extends Component {

		render() {
			const { errors, field } = this.props;
			const messages = errors.filter(error => error.field === field).map(error => error.message);

			return (
				messages.length > 0 ? (
					messages.map((message, index) => (
						<span key={index} className="form__field-error">{message}</span>
					))
				) : null
			);
		}

	}
);

export default FormError;
