import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
import AppError from './AppError';

class ErrorBoundry extends Component {
    constructor(props) {
		super(props);
		
        this.state = {
			error: null
		};
    }

    componentDidCatch(error, errorInfo) {
		this.setState({ error });

		Sentry.withScope(scope => {
			scope.setExtras(errorInfo);

			Sentry.captureException(error);
		});
    }

    render() {
        if (this.state.error) {
            return <AppError />
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundry;
