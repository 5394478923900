import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

const SignOut = inject('rootStore')(observer(
	class SignOut extends Component {

		constructor(props) {
			super(props);

			this.mounted = false;
			this.state = {
				signedOut: false
			};
		}

		componentDidMount() {
			const { userStore } = this.props.rootStore;

			this.mounted = true;

			userStore.signOut().then(() => {
				if (this.mounted) {
					this.setState({
						signedOut: true
					});
				}
			});
		}

		componentWillUnmount() {
			this.mounted = false;
		}

		render() {
			return (
				this.state.signedOut ? <Redirect to="/" /> : null
			);
		}

	}
));

export default SignOut;
