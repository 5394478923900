import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { withRouter } from 'react-router-dom';
import ProgressBar from '../../base/form/ProgressBar';
import Config from '../../../config/Config';
import ReactLoading from 'react-loading';
import FileUpload from '../../base/form/FileUpload';

const Insurance = inject('rootStore')(withRouter(observer(
	class Insurance extends Component {

		constructor(props) {
			super(props);

			this.insuranceRef = React.createRef();

			this.handleFileChange = this.handleFileChange.bind(this);
			this.handleSignIn = this.handleSignIn.bind(this);
			this.handleSignUp = this.handleSignUp.bind(this);
			this.handleInsuranceSubmitClick = this.handleInsuranceSubmitClick.bind(this);

			this.scrollToTop = this.scrollToTop.bind(this);
		}

		componentDidMount() {
			this.resetForm();
		}

		resetForm = () => {
			const { userStore } = this.props.rootStore;

			userStore.clearUploads();
			userStore.clearErrors();

			userStore.setSuccess(false);
		};

		async handleFileChange(event, callback = null) {
			const { userStore } = this.props.rootStore;
			const target = event.target;
			const file = event.target.files[0];
			const name = target.name;

			if (!!file) {
				await userStore.addUpload(name, file);

				if (callback !== null) {
					callback();
				}
			}
		}

		handleSignIn() {
			const { userStore } = this.props.rootStore;

			userStore.setRedirectTo(this.props.location.pathname);

			this.props.history.push('/account/signin');
		}

		handleSignUp() {
			const { userStore } = this.props.rootStore;
			
			userStore.setRedirectTo(this.props.location.pathname);

			this.props.history.push('/account/signup');
		}

		handleInsuranceSubmitClick(e) {
			e.preventDefault();

			const { userStore } = this.props.rootStore;

			userStore.clearErrors();
			userStore.submitInsurance().then(() => {
				this.scrollToTop();
			});
		}

		scrollToTop() {
			if (this.insuranceRef.current) {
				requestAnimationFrame(() => {
					this.insuranceRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				});
			}
		}

		render() {
			const { commonStore, userStore } = this.props.rootStore;
			const { translateMessage } = commonStore;
			const { loading, success, error, authenticated, uploads } = userStore;
			const submitDisabled = !uploads.carOwnershipFront || !uploads.carOwnershipFront.progress === 100 || !uploads.carOwnershipBack || !uploads.carOwnershipBack.progress === 100 || !uploads.drivingLicenseFront || !uploads.drivingLicenseFront.progress === 100 || !uploads.drivingLicenseBack || !uploads.drivingLicenseBack.progress === 100;

			return (
				<section className="section section--gray section--no-top-padding">
					<div className="section__wrapper">
						<div ref={this.insuranceRef} className="insurance">
							{authenticated ? (
								<div className="form insurance__form">
									{!!success ? (
										<div className="form__section">
											<h4 className="form__title form__title--center">
												<Localized code="page.insurance.subtitle.success" />
											</h4>

											<p className="form__text form__text--center">
												<Localized code="page.insurance.text.success" />
											</p>
										</div>
									) : (
										<div className="form__section">
											<h4 className="form__title form__title--center">
												<Localized code="page.insurance.subtitle.submitFiles" />
											</h4>

											<p className="form__text form__text--center">
												<Localized code="page.insurance.text.submitFiles" />
											</p>

											{!!error ? (
												<div className="form__message form__message--error">{error}</div>
											) : null}

											<div className="form__row">
												<div className="form__column">
													<div className="form__group">
														<label className="form__label" htmlFor="carOwnershipFront">
															<Localized code="page.insurance.label.carOwnershipFront" />
														</label>
														<FileUpload capture accept="image/*" name="carOwnershipFront" placeholder={translateMessage('generic.text.selectFile')} onChange={this.handleFileChange} />
													</div>
												</div>

												<div className="form__column">
													{typeof uploads.carOwnershipFront !== typeof undefined ? (
														<div className="form__group">
															<label className="form__label">
																<Localized code="page.insurance.label.currentImage" />
															</label>
															{!!uploads.carOwnershipFront.uri ? (
																<img alt="Uploaded" src={`${Config.UPLOADS_ENDPOINT}${uploads.carOwnershipFront.uri}`} className="insurance__image" />
															) : null}
															{!!uploads.carOwnershipFront.progress ? (
																<ProgressBar value={uploads.carOwnershipFront.progress} />
															) : null}
														</div>
													) : null}
												</div>
											</div>

											<div className="form__row">
												<div className="form__column">
													<div className="form__group">
														<label className="form__label" htmlFor="carOwnershipBack">
															<Localized code="page.insurance.label.carOwnershipBack" />
														</label>
														<FileUpload capture accept="image/*" name="carOwnershipBack" placeholder={translateMessage('generic.text.selectFile')} onChange={this.handleFileChange} />
													</div>
												</div>

												<div className="form__column">
													{typeof uploads.carOwnershipBack !== typeof undefined ? (
														<div className="form__group">
															<label className="form__label">
																<Localized code="page.insurance.label.currentImage" />
															</label>
															{!!uploads.carOwnershipBack.uri ? (
																<img alt="Uploaded" src={`${Config.UPLOADS_ENDPOINT}${uploads.carOwnershipBack.uri}`} className="insurance__image" />
															) : null}
															{!!uploads.carOwnershipBack.progress ? (
																<ProgressBar value={uploads.carOwnershipBack.progress} />
															) : null}
														</div>
													) : null}
												</div>
											</div>

											<div className="form__row">
												<div className="form__column">
													<div className="form__group">
														<label className="form__label" htmlFor="drivingLicenseFront">
															<Localized code="page.insurance.label.drivingLicenseFront" />
														</label>
														<FileUpload capture accept="image/*" name="drivingLicenseFront" placeholder={translateMessage('generic.text.selectFile')} onChange={this.handleFileChange} />
													</div>
												</div>

												<div className="form__column">
													{typeof uploads.drivingLicenseFront !== typeof undefined ? (
														<div className="form__group">
															<label className="form__label">
																<Localized code="page.insurance.label.currentImage" />
															</label>
															{!!uploads.drivingLicenseFront.uri ? (
																<img alt="Uploaded" src={`${Config.UPLOADS_ENDPOINT}${uploads.drivingLicenseFront.uri}`} className="insurance__image" />
															) : null}
															{!!uploads.drivingLicenseFront.progress ? (
																<ProgressBar value={uploads.drivingLicenseFront.progress} />
															) : null}
														</div>
													) : null}
												</div>
											</div>

											<div className="form__row">
												<div className="form__column">
													<div className="form__group">
														<label className="form__label" htmlFor="drivingLicenseBack">
															<Localized code="page.insurance.label.drivingLicenseBack" />
														</label>
														<FileUpload capture accept="image/*" name="drivingLicenseBack" placeholder={translateMessage('generic.text.selectFile')} onChange={this.handleFileChange} />
													</div>
												</div>

												<div className="form__column">
													{typeof uploads.drivingLicenseBack !== typeof undefined ? (
														<div className="form__group">
															<label className="form__label">
																<Localized code="page.insurance.label.currentImage" />
															</label>
															{!!uploads.drivingLicenseBack.uri ? (
																<img alt="Uploaded" src={`${Config.UPLOADS_ENDPOINT}${uploads.drivingLicenseBack.uri}`} className="insurance__image" />
															) : null}
															{!!uploads.drivingLicenseBack.progress ? (
																<ProgressBar value={uploads.drivingLicenseBack.progress} />
															) : null}
														</div>
													) : null}
												</div>
											</div>

											<div className="form__buttons">
												<button disabled={submitDisabled || loading} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleInsuranceSubmitClick}>
													{loading ? (
														<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
													) : null}
													<Localized code="page.insurance.button.submit" />
												</button>
											</div>
										</div>
									)}
								</div>
							) : (
								<Fragment>
									<p className="section__text section__text--lead section__text--center">
										<Localized code="page.insurance.text.accountRequired" />
									</p>

									<div className="section__columns">
										<div className="section__column section__column--center">
											<button className="button button--blue" onClick={this.handleSignIn}>
												<Localized code="page.signIn.button.signIn" />
											</button>
										</div>

										<div className="section__column section__column--center">
											<button className="button button--blue" onClick={this.handleSignUp}>
												<Localized code="page.signIn.link.signUp" />
											</button>
										</div>
									</div>
								</Fragment>
							)}
						</div>
					</div>
				</section>
			);
		}

	}
)));

export default Insurance;
