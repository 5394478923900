import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import ReactLoading from 'react-loading';

const Cancel = inject('rootStore')(observer(
	class Cancel extends Component {

		constructor(props) {
			super(props);

      this.state = {
        membership_id: '',
        reason: '',
				ssoToken: ''
      };

			this.handleCancelAccountClick = this.handleCancelAccountClick.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.cancelMembership.title');
			});
		}

		componentDidMount() {
      const { userStore, contentStore } = this.props.rootStore;

      contentStore.loadContent('dataRetentionPolicy');

			this.resetForm();

      const { me } = this.props.rootStore.userStore;

      if (me.membershipNumber) {
        this.setState({
          membership_id: me.membershipNumber
        });
      }

			userStore.ssoTokenRH().then(token => {
				if (token) {
					this.setState({
						ssoToken: token
					});
				}
			});
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		resetForm = () => {
			const { userStore } = this.props.rootStore;
			
			userStore.setError(false);
			userStore.setSuccess(false);

      this.setState({
        membership_id: '',
        reason: ''
      });
		};

		handleReasonChange = e => this.setState({ reason: e.target.value });

		handleCancelAccountClick(e) {
			e.preventDefault();

			const { userStore } = this.props.rootStore;

			userStore.cancelMembershipRequest(
				this.state.membership_id,
        this.state.reason,
				this.state.ssoToken
      );
		}

		render() {
      const { locale } = this.props.rootStore.commonStore;
			const { error, success, loading } = this.props.rootStore.userStore;
      const { membership_id, reason } = this.state;
      const { contentStore } = this.props.rootStore;
			const content = {
				dataRetentionPolicy: contentStore.getContent('dataRetentionPolicy')
			};
			const cancelAccountDisabled = !!!membership_id || !!!reason;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.cancelMembership.title" />
					</h2>

					<div className="section__wrapper">
						<div className="cancel-membership">
							{!!success ? (
							<p className="section__text">{success}</p>
							) : (
							<div className="cancel-membership__form">
								<form className="form">
                  <p className="form__text">
                    <Localized code="page.cancelMembership.description" />
                  </p>

									{!!error ? (
									<div className="form__message form__message--error">{error}</div>
									) : null}

									<div className="form__group">
										<label className="form__label" htmlFor="membership_id">
											<Localized code="page.cancelMembership.membership_id" />
										</label>
										<input readOnly className="form__input" type="text" id="membership_id" value={membership_id} />
									</div>

									<div className="form__group">
										<label className="form__label" htmlFor="reason">
											<Localized code="page.cancelMembership.reason" />
										</label>
										<textarea className="form__textarea" rows="5" id="reason" value={reason} onChange={this.handleReasonChange} />
									</div>

                  <div className="form__group">
                    <label className="form__label">
                      <Localized code="page.cancelMembership.dataRetentionPolicyLabel" />
                    </label>

                    <div className="cancel-membership__terms-wrapper">
                      {content.dataRetentionPolicy ? (
                      <div className="cancel-membership__terms cms-generated" dangerouslySetInnerHTML={{__html: content.dataRetentionPolicy.localData[locale].contentValue}} />
                      ) : null}
                    </div>
                  </div>

									<div className="form__buttons">
										<button disabled={loading || cancelAccountDisabled} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleCancelAccountClick}>
											{loading ? (
												<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
											) : null}
											<Localized code="page.cancelMembership.button" />
										</button>
									</div>
								</form>
							</div>
							)}
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Cancel;
