import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import HTTP404 from '../base/error/HTTP404';
import { autorun } from 'mobx';
import Skeleton from 'react-loading-skeleton';
import Loader from 'react-loaders';
import Localized from '../base/i18n/Localized';

const Page = inject('rootStore')(observer(
	class Page extends Component {

		constructor(props) {
			super(props);

			this.state = {
				iframePresent: false,
				iframeLoaded: false
			};

			this.loadPage = this.loadPage.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { locale, pageTitlePrefix } = props.rootStore.commonStore;
				const slug = this.props.match.params[0];
				const page = props.rootStore.pageStore.getPage(slug);

				if (page) {
					document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + page.localData[locale].pageTitle;
				} else {
					document.title = pageTitlePrefix;
				}
			});

			this.cmsContent = React.createRef();
		}

		componentDidMount() {
			this.loadPage();

			this.embedScript();

			this.runIframeLoader();
		}

		componentDidUpdate(prevProps) {
			if (prevProps.match.params[0] !== this.props.match.params[0]) {
				this.loadPage();
			}

			this.embedScript();

			this.runIframeLoader();
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		loadPage() {
			const slug = this.props.match.params[0];

			this.setState({
				iframePresent: false,
				iframeLoaded: false
			});
			
			this.props.rootStore.pageStore.loadPage(slug);
		}

		embedScript = () => {
			const { pageStore } = this.props.rootStore;
			const { loading } = pageStore;
			const slug = this.props.match.params[0];
			const page = pageStore.getPage(slug);

			let script = document.querySelector('script#embedded');

			if (script) {
				script.remove();
			}

			if (!loading && page && page.pageSlug === 'benefits') {				
				script = document.createElement('script');
				script.setAttribute('id', 'embedded');
				script.setAttribute('type', 'text/javascript')
				script.innerHTML = `function demo(e,l){var d=0;for(document.querySelectorAll(".embedded li");d<document.querySelectorAll(".embedded li").length;d++)0<document.querySelectorAll(".embedded li")[d].className.search("active")&&(document.querySelectorAll(".embedded li")[d].classList.remove("active"),document.querySelectorAll(".embedded li")[e-1].classList.add("active"));document.querySelectorAll(".embedded td").forEach(function(e,d){document.querySelectorAll(".embedded td")[d].className==l||"title-mbt"==document.querySelectorAll(".embedded td")[d].className?document.querySelectorAll(".embedded td")[d].style.display="table-cell":document.querySelectorAll(".embedded td")[d].style.display="none"})}document.getElementById("PLATINUM").click();`;

				document.head.appendChild(script);
			}
		}

		runIframeLoader = () => {
			if (this.cmsContent.current) {
				const iframe = this.cmsContent.current.querySelector('iframe');

				if (iframe) {
					if (!this.state.iframePresent) {
						this.setState({
							iframePresent: true
						});
					}

					iframe.onload = () => {
						this.setState({
							iframeLoaded: true
						});

						const { userStore } = this.props.rootStore;
						const { authenticated, me } = userStore;

						if (authenticated) {
							// Post message to iframe containting membership number
							iframe.contentWindow.postMessage({
								membershipNumber: me.membershipNumber
							}, '*');
						}
					};
				}
			}
		}

		render() {
			const { locale } = this.props.rootStore.commonStore;
			const { pageStore, userStore } = this.props.rootStore;
			const { loading, notFound } = pageStore;
			const { authenticated, me } = userStore;
			const slug = this.props.match.params[0];
			const page = pageStore.getPage(slug);

			let pageContent = null;

			if (page) {
				pageContent = page.localData[locale].pageContent;

				// Replace membership id if logged in
				if (page.pageSlug === 'report-issue') {
					if (authenticated && me && me.membershipNumber) {
						pageContent = page.localData[locale].pageContent.replace('&membership=', `&membership=${me.membershipNumber}`);
					} else {
						pageContent = page.localData[locale].pageContent.replace('&membership=', '');
					}
				}
			}

			return (
				!loading ? (
					page ? (
						<section className="section section--gray">
							<h2 className="section__title section__title--center">
								{page.localData[locale].pageTitle}
							</h2>

							<div className="section__wrapper">
								<div className={`cms-page${this.state.iframeLoaded || !this.state.iframePresent ? '' : ' cms-page--iframe-loading'}`}>
									<div className="cms-page__iframe-loader">
										<Loader type="line-scale-pulse-out-rapid" color="#002652" />

										<p className="cms-page__iframe-loader-text">
											<Localized code="page.cms.iframeLoading" />
										</p>
									</div>
									<div ref={this.cmsContent} className="cms-generated" dangerouslySetInnerHTML={{__html: pageContent}} />
								</div>
							</div>
						</section>
					) : (
						notFound ? (
							<HTTP404 />
						) : null
					)
				) : (
					<section className="section section--gray">
						<h2 className="section__title section__title--center">
							<Skeleton width={300} />
						</h2>

						<div className="section__wrapper">
							<div className="cms-page">
								<div className="cms-generated">
									<h3>
										<Skeleton width={300} />
									</h3>

									<p>
										<Skeleton count={3} />
									</p>

									<p>
										<Skeleton count={3} />
									</p>
								</div>
							</div>
						</div>
					</section>
				)
			);
		}

	}
));

export default Page;
