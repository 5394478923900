const ModalType = {
	INFO: Symbol('info'),
	SUCCESS: Symbol('success'),
	WARNING: Symbol('warning'),
	ERROR: Symbol('error')
};

Object.freeze(ModalType);

export default ModalType;
