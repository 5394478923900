import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Swiper from '@eredessil/react-id-swiper';
import Config from '../../../config/Config';
import Localized from '../../base/i18n/Localized';
import ServiceLink from '../../base/link/ServiceLink';

import imgSliderIconArrowLeft from '../../../assets/img/slider-icon-arrow-left.png';
import imgSliderIconArrowRight from '../../../assets/img/slider-icon-arrow-right.png'

const Services = inject('rootStore')(observer(
	class Services extends Component {

		constructor(props) {
			super(props);

			this.mounted = false;

			this.state = {
				ssoToken: null
			};

			this.goPrev = this.goPrev.bind(this);
			this.goNext = this.goNext.bind(this);
			
    		this.swiperRef = React.createRef();
		}

		componentDidMount() {
			this.mounted = true;

			const { rootStore } = this.props;
			const { authenticated, isProfileComplete } = rootStore.userStore;

			// Load SSO token
			if (authenticated && isProfileComplete) {
				rootStore.userStore.ssoTokenRH().then(token => {
					if (token && this.mounted) {
						this.setState({
							ssoToken: token
						});
					}
				});
			}
		}

		componentWillUnmount() {
			this.mounted = false;
		}

		goPrev() {
			if (document.documentElement.classList.contains('--rtl')) {
				this.swiperRef.current.swiper.slideNext();
			} else {
				this.swiperRef.current.swiper.slidePrev();
			}
		}

		goNext() {
			if (document.documentElement.classList.contains('--rtl')) {
				this.swiperRef.current.swiper.slidePrev();
			} else {
				this.swiperRef.current.swiper.slideNext();
			}
		}

		render() {
			const { locale } = this.props.rootStore.commonStore;
			const { loading, services } = this.props.rootStore.serviceStore;
			const swiperParams = {
				slidesPerView: 'auto',
				spaceBetween: 10,
				autoplay: {
					delay: 5000
				}
			};

			return (
				!loading && services.size > 0 ? (
				<section className="slider slider--services slider--white">
					{false && (
						<div className="slider__header">
							<h2 className="slider__title">
								<span className="slider__title-text">
									<Localized code="home.exclusiveServices.title" />
								</span>
							</h2>

							<div className="slider__buttons">
								<Link to={`/search?language=${locale}&types=SERVICE`} className="slider__button button button--gold button--small">
									<Localized code="home.button.viewMore" />
								</Link>
							</div>
						</div>
					)}

					<div className="slider__wrapper">
						<Swiper {...swiperParams} ref={this.swiperRef} rebuildOnUpdate>
							{Array.from(services.values()).map(service => (
							<div key={service.id} className="slider__slide">
								<div className="slider__item">
									<ServiceLink service={service} className="slider__link" noBlank={true} ssoToken={this.state.ssoToken}>
										<div className="slider__image-wrap">
											<img className="slider__image" src={`${Config.UPLOADS_ENDPOINT}${service.serviceImageUri}`} alt={service.localData[locale].name} />

											{false ? (
												<div className="slider__image-desc">
													<p className="slider__image-desc-text">PLACEHOLDER</p>
												</div>
											) : null}
										</div>

										<div className="slider__meta">
											<div className="slider__info">
												<h5 className="slider__name">{service.localData[locale].name}</h5>
											</div>
										</div>
									</ServiceLink>
								</div>
							</div>
							))}
						</Swiper>

						<div className="slider__left" onClick={this.goPrev}>
							<img className="slider__arrow" src={imgSliderIconArrowLeft} alt="Slide left" />
						</div>

						<div className="slider__right" onClick={this.goNext}>
							<img className="slider__arrow" src={imgSliderIconArrowRight} alt="Slide right" />
						</div>
					</div>
				</section>
				) : null
			);
		}

	}
));

export default Services;
