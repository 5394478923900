import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import { Link } from 'react-router-dom';
import Loader from 'react-loaders';
import moment from 'moment-timezone';

moment.tz.setDefault('Asia/Dubai');

const Transactions = inject('rootStore')(observer(
	class Transactions extends Component {

		constructor(props) {
			super(props);

			this.scrollListener = this.scrollListener.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.orders.title.transactions');
			});
		}

		componentDidMount() {
			const { orderStore } = this.props.rootStore;

			orderStore.loadExtraOffersTransactions(false);

			// Add scroll event listener
			window.addEventListener('scroll', this.scrollListener);
		}

		componentWillUnmount() {
			// Remove scroll event listener
			window.removeEventListener('scroll', this.scrollListener);

			this.mobxAutorunDisposer();
		}

		scrollListener(e) {
			const { rootStore } = this.props;
			const { footerRef } = rootStore.commonStore;
			const { orderStore } = rootStore;
			const { loading } = orderStore;

			if (orderStore.lastScrollPosition > window.pageYOffset) {
				return;
			}

			if (footerRef == null) {
				return;
			}

			if (window.pageYOffset + window.innerHeight <= document.body.scrollHeight - footerRef.scrollHeight) {
				return;
			}

			if (orderStore.total === orderStore.extraOffersTransactions.length) {
				return;
			}

			if (!loading) {
				orderStore.lastScrollPosition = window.pageYOffset;

				orderStore.loadExtraOffersTransactions(true);
			}
		}

		render() {
			const { commonStore, orderStore } = this.props.rootStore;
			const { locale } = commonStore;
			const { loading: transactionsLoading, extraOffersTransactions } = orderStore;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.orders.title.transactions" />
					</h2>

					<div className="section__wrapper">
						<div className="account">
							{extraOffersTransactions.length > 0 ? (
								<div className="account__transactions">
									<div className="account__transactions-header">
										<div className="account__transactions-header-column account__transactions-header-column--transaction-ref">
											<Localized code="page.account.text.transactionRef" />
										</div>
										<div className="account__transactions-header-column account__transactions-header-column--transaction-amount">
											<Localized code="page.account.text.transactionAmount" />
										</div>
										<div className="account__transactions-header-column account__transactions-header-column--discount-amount">
											<Localized code="page.account.text.discountAmount" />
										</div>
										<div className="account__transactions-header-column account__orders-header-column--quantity">
											<Localized code="page.account.text.transactionQty" />
										</div>
									</div>

									<div className="account__transactions-body">
										{extraOffersTransactions.map(transaction => (
											<div key={transaction.transactionRef} className="account__transactions-row">
												<div className="account__transactions-body-column account__transactions-body-column--transaction-ref">
													{transaction.transactionRef}
													<br />
													<small>{moment(transaction.createdAt).format('DD/MM/YY')} - {transaction.offer.localData[locale].title}</small>
												</div>
												<div className="account__transactions-body-column account__transactions-body-column--transaction-amount">
													{transaction.transactionAmount} AED
												</div>
												<div className="account__transactions-body-column account__transactions-body-column--discount-amount">
													{transaction.discountAmount} AED
												</div>
												<div className="account__transactions-body-column account__transactions-body-column--quantity">
													{transaction.quantity}
												</div>
											</div>
										))}
									</div>
								</div>
							) : (
								<p className="section__text section__text--center">
									<Localized code="page.account.text.noExtraOffersTransactions" __links={{
										link1: <Link className="section__link" to={`/search?language=${locale}&types=EXTRA_OFFERS`}></Link>
									}} />
								</p>
							)}

							{transactionsLoading ? (
								<div className="account__transactions-loading">
									<Loader type="line-scale-pulse-out-rapid" color="#002652" />
								</div>
							) : null}
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Transactions;
