import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { reportExceptionToSentry } from '../utils/Utils';

class ServiceStore {

	loading = false;
	services = new Map();

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	setLoading(loading) {
		this.loading = loading;
	}

	async loadAllServices() {
		this.loading = true;

		this.services.clear();

		try {
			const response = await FazaaAPI.Services.all();

			runInAction(() => {
				response.data.forEach(service => this.services.set(service.serviceSlug, service));
			});
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async loadFeaturedServices() {
		this.loading = true;

		this.services.clear();

		try {
			const response = await FazaaAPI.Services.featured();

			runInAction(() => {
				response.data.forEach(service => this.services.set(service.serviceSlug, service));
			});
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async loadService(slug) {
		if (this.services.has(slug)) {
			this.loading = false;

			return;
		}

		this.loading = true;

		try {
			const response = await FazaaAPI.Services.get(slug);
			const service = response.data;

			runInAction(() => {
				this.services.set(service.serviceSlug, service);
			});
		} catch (e) {
			reportExceptionToSentry(e, [404]);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	setService(slug, service) {
		this.services.set(slug, service);
	}

	getService(slug) {
		return this.services.get(slug);
	}

	clearServices() {
		this.services.clear();
	}

}

decorate(ServiceStore, {
	loading: observable,
	services: observable,
	setLoading: action,
	loadAllServices: action,
	loadFeaturedServices: action,
	loadService: action,
	setService: action,
	clearServices: action
});

export default ServiceStore;
