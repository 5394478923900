import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import { Link } from 'react-router-dom';
import Loader from 'react-loaders';
import ReactLoading from 'react-loading';
import CurrencyInput from 'react-currency-masked-input';

const PrepaidCardTransfer = inject('rootStore')(observer(
	class PrepaidCardTransfer extends Component {

		constructor(props) {
			super(props);

			this.state = {
				success: false,
				result: null
			};

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.prepaidCardTransfer.title');
			});
		}

		componentDidMount() {
			const { prepaidCardStore } = this.props.rootStore;

			prepaidCardStore.loadInfoBalance();

			prepaidCardStore.setFromWalletId('');
			prepaidCardStore.setToWalletId('');
			prepaidCardStore.setAmount('0.00');
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		handleFromWalletIdChange = e => {
			const { prepaidCardStore } = this.props.rootStore;

			prepaidCardStore.setFromWalletId(e.target.value);

			if (prepaidCardStore.toWalletId === e.target.value) {
				prepaidCardStore.setToWalletId('');
			}
		};

		handleToWalletIdChange = e => {
			const { prepaidCardStore } = this.props.rootStore;

			prepaidCardStore.setToWalletId(e.target.value);

			if (prepaidCardStore.fromWalletId === e.target.value) {
				prepaidCardStore.setFromWalletId('');
			}
		};

		handleAmountChange = e => {
			const { prepaidCardStore } = this.props.rootStore;

			prepaidCardStore.setAmount(e.target.value);
		};

		handleTransferClick = e => {
			const { prepaidCardStore } = this.props.rootStore;
			
			e.preventDefault();

			prepaidCardStore.transfer().then(result => {
				if (result) {
					this.setState({
						success: true,
						result: result
					});
				}
			});
		};

		render() {
			const { commonStore, prepaidCardStore } = this.props.rootStore;
			const { translateMessage } = commonStore;
			const { loading, error, working, cardInfo, balance, fromWalletId, toWalletId, amount } = prepaidCardStore;
			const canUseTransfer = cardInfo && cardInfo.status === 'ACTIVE' && balance && balance.reduce((a, c) => a + c.balance, 0) > 0;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.prepaidCardTransfer.title" />
					</h2>

					<div className="section__wrapper">
						<div className="prepaid-card">
							<div className="prepaid-card__transfer">
								<div className="prepaid-card__transfer-form">
									<form className="form">
										{!!this.state.success ? (
											<Fragment>
												<p className="form__text form__text--center">
													<Localized
														code="page.prepaidCardTransfer.text.success"
														fromCurrency={this.state.result.fromCurrency.AlphabeticCode}
														toCurrency={this.state.result.toCurrency.AlphabeticCode}
														fromBalance={this.state.result.fromBalance}
														toBalance={this.state.result.toBalance}
														amount={this.state.result.transactionAmount}
													/>
												</p>

												<p className="form__text form__text--center">
													<Link to="/account/prepaid-card" className="button button--gold">
														<Localized code="page.prepaidCardTransfer.link.goBack" />
													</Link>
												</p>
											</Fragment>
										) : (
											<Fragment>
												<p className="form__text form__text--center">
													<Localized code="page.prepaidCardTransfer.text.transferIntro" />
												</p>

												{loading ? (
													<div className="prepaid-card__transfer-form-loading">
														<Loader type="line-scale-pulse-out-rapid" color="#002652" />
													</div>
												) : (
													canUseTransfer ? (
														<Fragment>
															{!!error ? (
																<div className="form__message form__message--center form__message--error">{error}</div>
															) : null}

															<div className="form__row">
																<div className="form__column">
																	<div className="form__group">
																		<label className="form__label" htmlFor="fromWalletId">
																			<Localized code="page.prepaidCardTransfer.label.fromWallet" />
																		</label>
																		<div className="form__select-wrap">
																			<select className="form__select" id="fromWalletId" value={fromWalletId} onChange={this.handleFromWalletIdChange}>
																				<option>{translateMessage('generic.label.pleaseSelect')}</option>
																				{balance.filter(b => b.balance > 0).map(b => (
																					<option key={b.walletId} value={b.walletId}>{b.currencyCode.AlphabeticCode + ' (' + b.currencyCode.Currency + ')'}</option>
																				))}
																			</select>
																		</div>
																	</div>
																</div>
															
																<div className="form__column">
																	<div className="form__group">
																		<label className="form__label" htmlFor="toWalletId">
																			<Localized code="page.prepaidCardTransfer.label.toWallet" />
																		</label>
																		<div className="form__select-wrap">
																			<select className="form__select" id="toWalletId" value={toWalletId} onChange={this.handleToWalletIdChange}>
																				<option>{translateMessage('generic.label.pleaseSelect')}</option>
																				{balance.filter(b => b.walletId !== fromWalletId).map(b => (
																					<option key={b.walletId} value={b.walletId}>{b.currencyCode.AlphabeticCode + ' (' + b.currencyCode.Currency + ')'}</option>
																				))}
																			</select>
																		</div>
																	</div>
																</div>
															</div>

															<div className="form__row">
																<div className="form__column form__column--half">
																	<div className="form__group">
																		<label className="form__label" htmlFor="amount">
																			<Localized code="page.prepaidCardTransfer.label.amount" />
																		</label>
																		<CurrencyInput
																			className="form__input"
																			type="text"
																			id="amount"
																			defaultValue={amount}
																			onChange={this.handleAmountChange}
																			separator="."
																			style={{ textAlign: "right" }}
																		/>
																	</div>
																</div>
															</div>

															<div className="form__buttons">
																<button disabled={!fromWalletId || !toWalletId || !amount || working} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleTransferClick}>
																	{working ? (
																		<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
																	) : null}
																	<Localized code="page.prepaidCardTransfer.button.transfer" />
																</button>
															</div>
														</Fragment>
													) : (
														<Fragment>
															<p className="form__text form__text--center">
																<Localized code="page.prepaidCardTransfer.text.cantUseTransfer" />
															</p>

															<p className="form__text form__text--center">
																<Link to="/account/prepaid-card" className="button button--gold">
																	<Localized code="page.prepaidCardTransfer.link.prepaidCard" />
																</Link>
															</p>
														</Fragment>
													)
												)}
											</Fragment>
										)}
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default PrepaidCardTransfer;
