import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import CategoriesStrip from '../base/categories/CategoriesStrip';
import Swiper from '@eredessil/react-id-swiper';
import HTTP404 from '../base/error/HTTP404';
import Config from '../../config/Config';
import { autorun } from 'mobx';
import LeaseToOwn from './components/LeaseToOwn';
import FazaaHealth from './components/FazaaHealth';
import Insurance from './components/Insurance';
import Estore from './components/Estore';
import TempEstore from './components/TempEstore';
import UsedCars from './components/UsedCars';
import Skeleton from 'react-loading-skeleton';

import imgSliderIconArrowLeft from '../../assets/img/slider-icon-arrow-left.png'
import imgSliderIconArrowRight from '../../assets/img/slider-icon-arrow-right.png'

const Service = inject('rootStore')(observer(
	class Service extends Component {

		constructor(props) {
			super(props);

			this.state = {
				fazaaStoresToken: null
			};

			this.swiperRef = React.createRef();

			const { serviceStore } = props.rootStore;

			serviceStore.setLoading(true);

			this.mobxAutorunDisposer = autorun(() => {
				const { commonStore, serviceStore } = props.rootStore;
				const { locale, pageTitlePrefix } = commonStore;
				const { slug } = this.props.match.params;
				const service = serviceStore.getService(slug);

				if (service) {
					document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + service.localData[locale].name;
				} else {
					document.title = pageTitlePrefix;
				}
			});
		}

		componentDidMount() {
			const { userStore, serviceStore } = this.props.rootStore;
			const { authenticated, isProfileComplete } = userStore;
			const { slug } = this.props.match.params;

			serviceStore.loadService(slug);

			// Load SSO token for Fazaastores
			if (authenticated && isProfileComplete) {
				userStore.ssoTokenRH().then(token => {
					if (token) {
						this.setState({
							fazaaStoresToken: token
						});
					}
				});
			}
		}

		componentDidUpdate(prevProps) {
			const { serviceStore } = this.props.rootStore;
			const { slug: prevSlug } = prevProps.match.params;
			const { slug } = this.props.match.params;

			if (prevSlug && slug && prevSlug !== slug) {
				serviceStore.loadService(slug);
			}
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		render() {
			const { locale } = this.props.rootStore.commonStore;
			const { userStore, serviceStore } = this.props.rootStore;
			const { authenticated, me } = userStore;
			const { loading } = serviceStore;
			const { slug } = this.props.match.params;
			const service = serviceStore.getService(slug);
			const swiperParams = {
				slidesPerView: 1,
				navigation: {
					prevEl: '.service-page__gallery-left',
					nextEl: '.service-page__gallery-right'
				},
				renderPrevButton: () => (
					<div className="service-page__gallery-left">
						<img className="service-page__gallery-arrow" src={imgSliderIconArrowLeft} alt="Slide left" />
					</div>
				),
  				renderNextButton: () => (
					<div className="service-page__gallery-right">
						<img className="service-page__gallery-arrow" src={imgSliderIconArrowRight} alt="Slide right" />
					</div>
				)
			};

			let descriptionContent = null;

			if (!loading && service) {
				descriptionContent = service.localData[locale].description;

				if (authenticated) {
					// Add SSO token to Amakin link
					descriptionContent = descriptionContent.replace('https://amakin.fazaa.ae', 'https://amakin.fazaa.ae/agent_login_hash.php?hashValue=' + me.ssoToken)

					// Add SSO token to Fazaastores link
					if (this.state.fazaaStoresToken) {
						descriptionContent = descriptionContent.replace('https://fazaastores.com', 'https://fazaastores.com/fazaa?token=' + this.state.fazaaStoresToken)
					}
				}
			}

			return (
				!loading ? (
					service ? (
						service.serviceType === 1 && service.serviceComponent === 'Store' ? (
							<Estore service={service} />
						) : (
							<Fragment>
								<section className={"section section--gray" + (parseInt(service.serviceType) === 1 ? " section--with-component" : "") + (parseInt(service.serviceType) === 1 && (service.serviceComponent === 'Insurance' || service.serviceComponent === 'TemporaryStore') ? " section--with-component-weld" : "")}>
									<h2 className="section__title section__title--center">
										{service.localData[locale].name}
									</h2>

									<div className="section__wrapper">
										<div className="service-page">
											<div className="service-page__gallery">
												{service.images.length > 0 ? (
													<Swiper {...swiperParams} ref={this.swiperRef} rebuildOnUpdate>
														{service.images.map(image => (
															<div key={image.id} className="service-page__slide">
																<img className="service-page__image" src={`${Config.UPLOADS_ENDPOINT}${image.imageUri}`} alt="" />
															</div>
														))}
													</Swiper>
												) : (
													<img className="service-page__image" src={`${Config.UPLOADS_ENDPOINT}${service.serviceImageUri}`} alt={service.localData[locale].title} />
												)}
											</div>

											<div className="service-page__description">
												<CategoriesStrip categories={service.categories} />

												<div className="cms-generated" dangerouslySetInnerHTML={{__html: descriptionContent}} />
											</div>
										</div>
									</div>
								</section>

								{parseInt(service.serviceType) === 1 ? (
									<Fragment>
										{service.serviceComponent === 'LeaseToOwn' ? (
											<LeaseToOwn service={service} />
										) : null}

										{service.serviceComponent === 'FazaaHealth' ? (
											<FazaaHealth service={service} locale={locale} />
										) : null}

										{service.serviceComponent === 'Insurance' ? (
											<Insurance service={service} />
										) : null}

										{service.serviceComponent === 'TemporaryStore' ? (
											<TempEstore service={service} />
										) : null}

										{service.serviceComponent === 'UsedCars' ? (
											<UsedCars service={service} />
										) : null}
									</Fragment>
								) : null}
							</Fragment>
						)
					) : (
						<HTTP404 />
					)
				) : (
					<section className="section section--gray">
						<h2 className="section__title section__title--center">
							<Skeleton width={300} />
						</h2>

						<div className="section__wrapper">
							<div className="cms-page">
								<div className="cms-generated">
									<h3>
										<Skeleton width={300} />
									</h3>

									<p>
										<Skeleton count={3} />
									</p>

									<p>
										<Skeleton count={3} />
									</p>
								</div>
							</div>
						</div>
					</section>
				)
			);
		}

	}
));

export default Service;
