import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import EstoreCategoriesSlider from '../../base/categories/EstoreCategoriesSlider';
import Localized from '../../base/i18n/Localized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faThList } from '@fortawesome/free-solid-svg-icons';
import Product from '../../base/estore/Product';
import Dropdown from '../../base/estore/Dropdown';
import Qs from 'qs';
import Loader from 'react-loaders';
import FilterMultiSelect from '../../base/filter/FilterMultiSelect';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import imgEstoreBanner from '../../../assets/img/estore-banner.jpg';

const Estore = inject('rootStore')(withRouter(observer(
	class Estore extends Component {

		constructor(props) {
			super(props);

			const { estoreStore } = props.rootStore;

			estoreStore.setLoading(true);
			estoreStore.setError(false);
			estoreStore.clearResults();

			this.scrollListener = this.scrollListener.bind(this);
			this.triggerSearch = this.triggerSearch.bind(this);
		}

		componentDidMount() {
			const { commonStore, estoreStore } = this.props.rootStore;
			const { search } = this.props.location;

			estoreStore.setStoreActive(true);

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			estoreStore.loadCategories().then(() => {
				const { categories } = estoreStore;

				if (params.language && params.category) {
					const category = categories.find(c => c.localData[params.language].name === params.category);

					estoreStore.loadSubCategories(category.id);
				}
			});

			let paramsError = false;

			if (!params.language) {
				paramsError = true;

				params.language = commonStore.locale;
			}

			if (params.subcategories && params.subcategories.length > 0 && !params.category) {
				paramsError = true;

				delete params.subcategories;
			}

			if (!paramsError) {
				this.triggerSearch();
			} else {
				const query = Qs.stringify(params, {
					arrayFormat: 'repeat'
				});

				this.props.history.push(`?${query}`);
			}

			// Add scroll event listener
			window.addEventListener('scroll', this.scrollListener);

			window.addEventListener('resize', this.handleWindowResize);
		}

		componentDidUpdate(prevProps) {
			const { commonStore, estoreStore } = this.props.rootStore;
			const { search } = this.props.location;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			if (!params.language) {
				params.language = commonStore.locale;

				const query = Qs.stringify(params, {
					arrayFormat: 'repeat'
				});

				this.props.history.push(`?${query}`);
			} else {
				// Load subcategories on category link click while on store page
				if (params.category && params.category !== estoreStore.selectedCategory) {
					const { categories } = estoreStore;
					const category = categories.find(c => c.localData[params.language].name === params.category);
	
					estoreStore.loadSubCategories(category.id);
				}

				if (prevProps.location.search !== this.props.location.search) {
					this.triggerSearch();
				}

				if (params.language !== commonStore.locale) {
					// Translate category name
					if (params.category && params.category.length > 0) {
						const category = estoreStore.categories.find(c => c.localData[params.language].name === params.category);

						if (category) {
							params.category = category.localData[commonStore.locale].name;
						}
					}

					// Translate subcategory names
					if (params.subcategories && params.subcategories.length > 0) {
						const translatedSubCategories = [];

						if (Array.isArray(params.subcategories)) {
							for (let subCategoryName of params.subcategories) {
								const subCategory = estoreStore.subCategories.find(sc => sc.localData[params.language].name === subCategoryName);

								if (subCategory) {
									translatedSubCategories.push(subCategory.localData[commonStore.locale].name);
								}
							}
						} else {
							const subCategory = estoreStore.subCategories.find(sc => sc.localData[params.language].name === params.subcategories);

							if (subCategory) {
								translatedSubCategories.push(subCategory.localData[commonStore.locale].name);
							}
						}

						params.subcategories = translatedSubCategories;
					}

					params.language = commonStore.locale;

					const query = Qs.stringify(params, {
						arrayFormat: 'repeat'
					});

					this.props.history.push(`?${query}`);
				}
			}
		}

		componentWillUnmount() {
			const { estoreStore } = this.props.rootStore;

			estoreStore.setStoreActive(false);

			// Remove scroll event listener
			window.removeEventListener('scroll', this.scrollListener);

			window.removeEventListener('resize', this.handleWindowResize);
		}

		handleWindowResize = () => {
			const { estoreStore } = this.props.rootStore;

			if (window.innerWidth <= 650 && estoreStore.listingType !== 'grid') {
				estoreStore.setListingType('grid');
			}
		}

		handleSortingTypeChange = type => {
			const { estoreStore } = this.props.rootStore;

			estoreStore.setSortingType(type);

			this.triggerSearch();
		}

		handlePerPageChange = count => {
			const { estoreStore } = this.props.rootStore;

			estoreStore.setPerPage(count);

			this.triggerSearch();
		}

		handleListingTypeChange = type => {
			const { estoreStore } = this.props.rootStore;

			estoreStore.setListingType(type);
		}

		handleListingTypeChangeGrid = () => {
			this.handleListingTypeChange('grid');
		}

		handleListingTypeChangeList = () => {
			this.handleListingTypeChange('list');
		}

		handleCategoryChange = value => {
			const { search } = this.props.location;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			params.category = value;

			if (!value && params.subcategories) {
				delete params.subcategories;
			}

			const query = Qs.stringify(params, {
				arrayFormat: 'repeat'
			});

			this.props.history.push(`?${query}`);

			const { estoreStore } = this.props.rootStore;

			if (value) {
				const { categories } = estoreStore;
				const category = categories.find(c => c.localData[params.language].name === value);

				estoreStore.loadSubCategories(category.id);
			} else {
				estoreStore.setSubCategories([]);
			}
		}

		handleSubCategoriesChange = values => {
			const { search } = this.props.location;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			params.subcategories = values;

			const query = Qs.stringify(params, {
				arrayFormat: 'repeat'
			});

			this.props.history.push(`?${query}`);
		}

		scrollListener(e) {
			const { rootStore } = this.props;
			const { footerRef } = rootStore.commonStore;
			const { estoreStore } = rootStore;
			const { loading } = estoreStore;

			if (estoreStore.lastScrollPosition > window.pageYOffset) {
				return;
			}

			if (footerRef == null) {
				return;
			}

			if (window.pageYOffset + window.innerHeight <= document.body.scrollHeight - footerRef.scrollHeight) {
				return;
			}

			if (estoreStore.total === estoreStore.results.length) {
				return;
			}

			if (!loading) {
				estoreStore.lastScrollPosition = window.pageYOffset;

				this.triggerSearch(true);
			}
		}

		triggerSearch(nextPage = false) {
			const { search } = this.props.location;
			const { estoreStore } = this.props.rootStore;
			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			estoreStore.doSearch(params, nextPage);
		}

		render() {
			const { commonStore, estoreStore } = this.props.rootStore;
			const { locale, translateMessage } = commonStore;
			const { listingType } = estoreStore;
			const { service } = this.props;

			const categories = estoreStore.categories.map(category => ({
				value: category.localData[locale].name,
				label: category.localData[locale].name
			}));
			
			categories.unshift({
				value: undefined,
				label: commonStore.translateMessage('estore.search.label.allCategories')
			});

			const subCategories = estoreStore.subCategories.map(subCategory => ({
				value: subCategory.localData[locale].name,
				label: subCategory.localData[locale].name
			}));

			const sortingOptions = [{
				value: 'newest',
				name: 'Newest First'
			}, {
				value: 'oldest',
				name: 'Oldest First'
			}, {
				value: 'cheapest',
				name: 'Price Lowest First'
			}, {
				value: 'expensive',
				name: 'Price Highest First'
			}];

			const perPageOptions = [{
				value: 6,
				name: 'Show 6 Per Page'
			}, {
				value: 12,
				name: 'Show 12 Per Page'
			}];

			const { loading, previousResultCount, results } = estoreStore;

			return (
				<section className="section section--white">
					<div className="section__wrapper">
						<div className="estore-page">
							<EstoreCategoriesSlider service={service} />

							<div className="estore-page__header">
								<img className="estore-page__banner" src={imgEstoreBanner} alt={translateMessage('estore.text.banner.generic')} title={translateMessage('estore.text.banner.generic')} />

								<h2 className="estore-page__title">
									{service.localData[locale].name}
								</h2>

								<div className="cms-generated" dangerouslySetInnerHTML={{__html: service.localData[locale].description}} />
							</div>

							<div className={`estore-page__listing estore-page__listing--${listingType}`}>
								<div className="estore-page__listing-header">
									<h3 className="estore-page__listing-title">
										<Localized code="estore.title.products" />
									</h3>

									<div className="estore-page__listing-controls">
										<Dropdown className="estore-page__listing-control-dropdown" initial="newest" options={sortingOptions} onChange={this.handleSortingTypeChange} />

										<Dropdown className="estore-page__listing-control-dropdown" initial={6} options={perPageOptions} onChange={this.handlePerPageChange} />

										<button className={`estore-page__listing-control-type${listingType === 'grid' ? ' estore-page__listing-control-type--active' : ''}`} onClick={this.handleListingTypeChangeGrid}>
											<FontAwesomeIcon className="estore-page__listing-control-type-icon" icon={faTh} />
										</button>

										<button className={`estore-page__listing-control-type${listingType === 'list' ? ' estore-page__listing-control-type--active' : ''}`} onClick={this.handleListingTypeChangeList}>
											<FontAwesomeIcon className="estore-page__listing-control-type-icon" icon={faThList} />
										</button>
									</div>
								</div>

								<div className="search-page__filters estore-page__filters">
									<div className="search-page__filter">
										<FilterMultiSelect single singleText items={categories} selected={estoreStore.selectedCategory} onChange={this.handleCategoryChange} placeholder={commonStore.translateMessage('estore.search.label.allCategories')} />
									</div>

									{subCategories && subCategories.length > 0 ? (
										<div className="search-page__filter">
											<FilterMultiSelect items={subCategories} selected={estoreStore.selectedSubCategories} onChange={this.handleSubCategoriesChange} placeholder={commonStore.translateMessage('estore.search.label.allSubCategories')} />
										</div>
									) : null}
								</div>

								{results.length > 0 ? (
									<TransitionGroup className="estore-page__listing-content">
										{results.map((product, index) => {
											const delay = (index * 50) - (previousResultCount * 50);

											return (
												<CSSTransition key={product.id} timeout={300 + delay} classNames="estore-page__product">
													<Product partial product={product} delay={delay} listingType={listingType} />	
												</CSSTransition>
											);
										})}
									</TransitionGroup>
								) : null}

								{results.length === 0 && !loading ? (
									<div className="estore-page__message">
										<p className="estore-page__message-text estore-page__message-text--center">
											<Localized code="estore.search.text.noResults" />
										</p>
									</div>
								) : null}

								{loading ? (
									<div className="search-page__loading">
										<Loader type="line-scale-pulse-out-rapid" color="#002652" />
									</div>
								) : null}
							</div>
						</div>
					</div>
				</section>
			);
		}

	}
)));

export default Estore;
