const CardImages = [{
	levelId: 1,
	name: 'Platinum',
	image: 'https://fazaaweb.s3.ap-south-1.amazonaws.com/website-banners/Updated+banner+-+plastic+card/Fazaa-Platinum-Card.png'
}, {
	levelId: 2,
	name: 'Gold',
	image: 'https://fazaaweb.s3.ap-south-1.amazonaws.com/website-banners/Updated+banner+-+plastic+card/Fazaa-Gold-Card.png'
}, {
	levelId: 3,
	name: 'Silver',
	image: 'https://fazaaweb.s3.ap-south-1.amazonaws.com/website-banners/Updated+banner+-+plastic+card/Fazaa-Silver-Card.png'
}, {
	levelId: 4,
	name: 'Discount',
	image: 'https://s3.ap-south-1.amazonaws.com/fazaaweb/membership-benefits/discount.png'
}, ];

Object.freeze(CardImages);

export default CardImages;
