import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import HTTP404 from '../base/error/HTTP404';
import { autorun } from 'mobx';
import Skeleton from 'react-loading-skeleton';
import Localized from '../base/i18n/Localized';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import CreditCardInput from 'react-credit-card-input';
import FormError from '../base/form/FormError';

const VALID_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Payment = inject('rootStore')(observer(
	class Payment extends Component {

		constructor(props) {
			super(props);

			this.paymentInProgress = false;

			this.loadPaymentRequest = this.loadPaymentRequest.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.paymentRequest.title');
			});
		}

		componentDidMount() {
			const { userStore, paymentStore } = this.props.rootStore;

			paymentStore.clearForm();

			this.loadPaymentRequest();

			userStore.loadCountries().then(result => {
				if (!result) {
					paymentStore.setError('Unable to get list of countries/nationalities, please reload the page and try again.');
				}
			});
		}

		componentDidUpdate(prevProps) {
			if (prevProps.match.params[0] !== this.props.match.params[0]) {
				this.loadPaymentRequest();
			}
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		loadPaymentRequest() {
			const { paymentStore } = this.props.rootStore;
			const { id } = this.props.match.params;

			paymentStore.loadPaymentRequest(id);
		}

		handleFirstNameChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.paymentStore.setFirstName(e.target.value);
			}
		}

		handleLastNameChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.paymentStore.setLastName(e.target.value);
			}
		}

		handleAddressChange = e => {
			if (new RegExp('^[a-zA-Z\\d\\-\\s/\\.]*$').test(e.target.value)) {
				this.props.rootStore.paymentStore.setAddress(e.target.value);
			}
		}

		handleCityChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.paymentStore.setCity(e.target.value);
			}
		}

		handleCountryChange = e => this.props.rootStore.paymentStore.setCountry(e.target.value);
		handleEmailChange = e => this.props.rootStore.paymentStore.setEmail(e.target.value);
		handleCcNumberChange = e => this.props.rootStore.paymentStore.setCcNumber(e.target.value);
		handleCcExpiryChange = e => this.props.rootStore.paymentStore.setCcExpiry(e.target.value);
		handleCcCvvChange = e => this.props.rootStore.paymentStore.setCcCvv(e.target.value);

		handlePaymentClick = async e => {
			e.preventDefault();

			if (this.paymentInProgress) return;

			const { commonStore, paymentStore } = this.props.rootStore;
			const { email } = paymentStore;
			const { translateMessage } = commonStore;

			this.paymentInProgress = true;

			paymentStore.clearErrors();

			if (!VALID_EMAIL.test(email)) {
				paymentStore.addError('email', translateMessage('generic.error.invalidEmail'));
			}

			if (paymentStore.errors.length === 0) {
				const { id } = this.props.match.params;

				await paymentStore.makePayment(id);
			} else {
				paymentStore.setError(translateMessage('generic.error.checkFields'));
			}

			this.scrollToTop();

			this.paymentInProgress = false;
		};

		render() {
			const { commonStore, userStore, paymentStore } = this.props.rootStore;
			const { countries } = userStore;
			const { working, loading, notFound, paymentRequest, error, success, errors, firstName, lastName, address, city, country, email, ccNumber, ccExpiry, ccCvv } = paymentStore;
			const { locale, translateMessage } = commonStore;

			return (
				!loading ? (
					paymentRequest && paymentRequest.status !== 'DELETED' ? (
						<section className="section section--gray">
							<h2 className="section__title section__title--center">
								<Localized code="page.paymentRequest.title" />
							</h2>

							<div className="section__wrapper">
								<div className="payment-request">
									<div className="payment-request__form">
										<form className="form">
											{!!error ? (
												<div className="form__message form__message--error">{error}</div>
											) : null}

											{success || paymentRequest.status === 'PROCESSED' ? (
												<div className="form__section">
													{paymentRequest.status === 'PROCESSED' ? (
														<Fragment>
															<h4 className="form__title">
																<Localized code="page.paymentRequest.subtitle.alreadyPaid" />
															</h4>

															<p className="form__text">
																<Localized code="page.paymentRequest.text.alreadyPaid" />
															</p>
														</Fragment>
													) : (
														<Fragment>
															<h4 className="form__title">
																<Localized code="page.paymentRequest.subtitle.success" />
															</h4>

															<p className="form__text">
																<Localized code="page.paymentRequest.text.success" />
															</p>
														</Fragment>
													)}

													<p className="form__text">
														<Link to="/" className="button button--gold">
															<Localized code="page.paymentRequest.link.home" />
														</Link>
													</p>
												</div>
											) : (
												<Fragment>
													<h4 className="form__title">
														<Localized code="page.paymentRequest.subtitle.requestDetails" />
													</h4>

													<div className="payment-request__details">
														<div className="payment-request__name">
															<div className="payment-request__label">
																<Localized code="page.paymentRequest.text.name" />
															</div>
															<div className="payment-request__value">{paymentRequest.name}</div>
														</div>

														<div className="payment-request__amount">
															<div className="payment-request__label">
																<Localized code="page.paymentRequest.text.amount" />
															</div>
															<div className="payment-request__value">{paymentRequest.amount} AED</div>
														</div>
													</div>

													<h4 className="form__title">
														<Localized code="page.signup.subtitle.billingDetails" />
													</h4>

													<div className="form__row">
														<div className="form__column">
															<div className="form__group">
																<label className="form__label" htmlFor="email">
																	<Localized code="page.cart.label.email" />
																</label>
																<input className="form__input" type="email" id="email" value={email} onChange={this.handleEmailChange} />
																<FormError errors={errors} field="email" />
															</div>
														</div>

														<div className="form__column">
															<div className="form__group">
																<label className="form__label" htmlFor="address">
																	<Localized code="page.cart.label.address" />
																</label>
																<input className="form__input" type="text" id="address" value={address} onChange={this.handleAddressChange} />
															</div>
														</div>
													</div>

													<div className="form__row">
														<div className="form__column">
															<div className="form__group">
																<label className="form__label" htmlFor="country">
																	<Localized code="page.cart.label.country" />
																</label>
																<div className="form__select-wrap">
																	<select className="form__select" id="country" value={country} onChange={this.handleCountryChange}>
																		<option value="">{translateMessage('generic.label.pleaseSelect')}</option>
																		{countries.map(country => (
																			<option key={country.countryCode2c} value={country.countryCode2c}>{locale === 'ar' ? country.countryNameAr : country.countryName}</option>
																		))}
																	</select>
																</div>
															</div>
														</div>

														<div className="form__column">
															<div className="form__group">
																<label className="form__label" htmlFor="city">
																	<Localized code="page.cart.label.city" />
																</label>
																<input className="form__input" type="text" id="city" value={city} onChange={this.handleCityChange} />
															</div>
														</div>
													</div>

													<h4 className="form__title">
														<Localized code="page.signup.subtitle.paymentDetails" />
													</h4>

													<div className="form__row">
														<div className="form__column form__column--quarter">
															<div className="form__group">
																<label className="form__label" htmlFor="firstName">
																	<Localized code="page.cart.label.firstName" />
																</label>
																<input className="form__input" type="text" id="firstName" value={firstName} onChange={this.handleFirstNameChange} />
															</div>
														</div>

														<div className="form__column form__column--quarter">
															<div className="form__group">
																<label className="form__label" htmlFor="lastName">
																	<Localized code="page.cart.label.lastName" />
																</label>
																<input className="form__input" type="text" id="lastName" value={lastName} onChange={this.handleLastNameChange} />
															</div>
														</div>

														<div className="form__column">
															<div className="form__group">
																<label className="form__label">
																	<Localized code="page.signup.label.ccDetails" />
																</label>
																<CreditCardInput
																	containerClassName="form__cc-container"
																	fieldClassName="form__cc-field"
																	inputClassName="form__cc-input"
																	dangerTextClassName="form__field-error"
																	cardNumberInputProps={{ value: ccNumber, onChange: this.handleCcNumberChange }}
																	cardExpiryInputProps={{ value: ccExpiry, onChange: this.handleCcExpiryChange }}
																	cardCVCInputProps={{ value: ccCvv, onChange: this.handleCcCvvChange }}
																/>
															</div>
														</div>
													</div>

													<div className="form__buttons">
														<button disabled={working || !firstName || !lastName || !address || !city || !country || !email || !ccNumber || !ccExpiry || !ccCvv} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handlePaymentClick}>
															{loading ? (
																<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
															) : null}
															<Localized code="page.paymentRequest.button.payment" />
														</button>
													</div>
												</Fragment>
											)}
										</form>
									</div>
								</div>
							</div>
						</section>
					) : (
						notFound || (paymentRequest && paymentRequest.status === 'DELETED') ? (
							<HTTP404 />
						) : null
					)
				) : (
					<section className="section section--gray">
						<h2 className="section__title section__title--center">
							<Skeleton width={300} />
						</h2>

						<div className="section__wrapper">
							<div className="payment-request">
								<div className="payment-request__form">
									<form className="form">
										<h4 className="form__title">
											<Skeleton width={300} />
										</h4>

										<p className="form__text">
											<Skeleton count={1} />
										</p>

										<h4 className="form__title">
											<Skeleton width={300} />
										</h4>

										<p className="form__text">
											<Skeleton count={3} />
										</p>

										<h4 className="form__title">
											<Skeleton width={300} />
										</h4>

										<p className="form__text">
											<Skeleton count={3} />
										</p>
									</form>
								</div>
							</div>
						</div>
					</section>
				)
			);
		}

	}
));

export default Payment;
