import CommonStore from './CommonStore';
import UserStore from './UserStore';
import CategoryStore from './CategoryStore';
import ServiceStore from './ServiceStore';
import OfferStore from './OfferStore';
import VoucherStore from './VoucherStore';
import PageStore from './PageStore';
import SearchStore from './SearchStore';
import FazaaAPI from '../api/FazaaAPI';
import CartStore from './CartStore';
import ContentStore from './ContentStore';
import OrderStore from './OrderStore';
import HamadStore from './HamadStore';
import CarStore from './CarStore';
import EstoreStore from './EstoreStore';
import PaymentStore from './PaymentStore';
import { throttle, shuffle } from 'lodash';
import { reportExceptionToSentry } from '../utils/Utils';
import PrepaidCardStore from './PrepaidCardStore';
import UsedCarStore from './UsedCarStore';

import { reaction } from 'mobx';

class RootStore {

	lastOfferLoadPosition = null;
	lastOfferLoadAuthStatus = null;

	refreshOffersReaction = null;

	constructor() {
		this.commonStore = new CommonStore(this);
		this.userStore = new UserStore(this);
		this.categoryStore = new CategoryStore(this);
		this.serviceStore = new ServiceStore(this);
		this.offerStore = new OfferStore(this);
		this.voucherStore = new VoucherStore(this);
		this.pageStore = new PageStore(this);
		this.searchStore = new SearchStore(this);
		this.cartStore = new CartStore(this);
		this.contentStore = new ContentStore(this);
		this.orderStore = new OrderStore(this);
		this.hamadStore = new HamadStore(this);
		this.carStore = new CarStore(this);
		this.estoreStore = new EstoreStore(this);
		this.prepaidCardStore = new PrepaidCardStore(this);
		this.paymentStore = new PaymentStore(this);
		this.usedCarStore = new UsedCarStore(this);

		this.refreshOffers = throttle(this.refreshOffers, 1000 * 60 * 1); // Call at max every 1 minute (previously used debounce)
	}

	async loadHomeData() {
		if (this.refreshOffersReaction) {
			this.refreshOffersReaction();
		}

		const position = this.userStore.position && this.userStore.position.latitude && this.userStore.position.longitude ? {
			latitude: Number(this.userStore.position.latitude.toFixed(3)),
			longitude: Number(this.userStore.position.longitude.toFixed(3))
		} : null;
		let ignoreOffers = false;

		// Ignore offer load if position and user auth status didn't change
		if (position !== null && this.lastOfferLoadPosition !== null && position.latitude === this.lastOfferLoadPosition.latitude && position.longitude === this.lastOfferLoadPosition.longitude && this.lastOfferLoadAuthStatus === this.userStore.authenticated) {
			ignoreOffers = true;
		}

		this.serviceStore.setLoading(true);

		if (!ignoreOffers) {
			this.offerStore.setLoading(true);
		}

		this.voucherStore.setLoading(true);
		this.voucherStore.setLoadingPackage(true);

		this.serviceStore.clearServices();

		if (!ignoreOffers) {
			this.offerStore.clearOffers();
			this.offerStore.clearDynamicOffers();
			this.offerStore.clearDynamicOfferTokens();
		}

		this.voucherStore.clearVouchers();
		this.voucherStore.clearVoucherPackages();

		try {
			if (!ignoreOffers) {
				this.lastOfferLoadPosition = position;
				this.lastOfferLoadAuthStatus = this.userStore.authenticated;
			}

			const response = await FazaaAPI.Home.featured(position);

			if (response.data.services) {
				for (const service of response.data.services) {
					this.serviceStore.setService(service.serviceSlug, service);
				}
			}

			if (!ignoreOffers) {
				if (response.data.offers) {
					// Randomize offers unless they were obtained with near me data
					if (position === null) {
						response.data.offers = shuffle(response.data.offers);
					}

					for (const offer of response.data.offers) {
						this.offerStore.setOffer(offer.offerSlug, offer);
					}
				}

				if (response.data.dynamicOffers) {
					// Randomize offers unless they were obtained with near me data
					if (position === null) {
						response.data.dynamicOffers = shuffle(response.data.dynamicOffers);
					}
					
					for (const dynamicOffer of response.data.dynamicOffers) {
						this.offerStore.setDynamicOffer(dynamicOffer.offerSlug, dynamicOffer);
					}
				}
			}

			if (response.data.vouchers) {
				for (const voucher of response.data.vouchers) {
					this.voucherStore.setVoucher(voucher.voucherSlug, voucher);
				}
			}

			if (response.data.voucherPackages) {
				for (const voucherPackage of response.data.voucherPackages) {
					this.voucherStore.setVoucherPackage(voucherPackage.voucherPackage.packageSlug, voucherPackage);
				}
			}

			// Setup refersh offers reaction
			this.refreshOffersReaction = reaction(
				() => ({
					latitude: this.userStore.position ? Number(this.userStore.position.latitude.toFixed(3)) : null,
					longitude: this.userStore.position ? Number(this.userStore.position.longitude.toFixed(3)): null,
					authenticated: this.userStore.authenticated
				}),
				() => this.refreshOffers(),
				{
					equals: (a, b) => {
						if (a.latitude !== b.latitude || a.longitude !== b.longitude || a.authenticated !== b.authenticated) return false;
	
						return true;
					}
				}
			);
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			this.serviceStore.setLoading(false);

			if (!ignoreOffers) {
				this.offerStore.setLoading(false);
			}

			this.voucherStore.setLoading(false);
			this.voucherStore.setLoadingPackage(false);
		}
	}

	async refreshOffers() {
		if (!this.commonStore.appReady.userReady) return;

		// Don't refresh offers while we're in undefined midphase state of signin with UAE PASS
		if (this.userStore.uaePassFlowInProgress) return;

		// Don't refresh offers while we are viewing an offer as this may affect page rendering
		// as the view offer component depends on the data in the loaded offers map!
		if (this.offerStore.viewingOffer) return;

		const position = this.userStore.position && this.userStore.position.latitude && this.userStore.position.longitude ? {
			latitude: Number(this.userStore.position.latitude.toFixed(3)),
			longitude: Number(this.userStore.position.longitude.toFixed(3))
		} : null;

		// Don't reload if position or auth status didn't change
		if (position !== null && this.lastOfferLoadPosition !== null && position.latitude === this.lastOfferLoadPosition.latitude && position.longitude === this.lastOfferLoadPosition.longitude && this.lastOfferLoadAuthStatus === this.userStore.authenticated) {
			return;
		}

		this.offerStore.clearOffers();
		this.offerStore.clearDynamicOffers();
		this.offerStore.clearDynamicOfferTokens();

		try {
			this.lastOfferLoadPosition = position;
			this.lastOfferLoadAuthStatus = this.userStore.authenticated;

			const response = await FazaaAPI.Home.offers(position);

			if (response.data) {
				if (response.data.offers) {
					for (const offer of response.data.offers) {
						this.offerStore.setOffer(offer.offerSlug, offer);
					}
				}

				if (response.data.dynamicOffers) {
					for (const dynamicOffer of response.data.dynamicOffers) {
						this.offerStore.setDynamicOffer(dynamicOffer.offerSlug, dynamicOffer);
					}
				}
			}
		} catch (e) {
			reportExceptionToSentry(e);
		}
	}

	cleanupSessionData(switchingContext = false) {
		this.userStore.cleanupSessionData(switchingContext);
		this.cartStore.cleanupSessionData();
		this.orderStore.cleanupSessionData();
		this.prepaidCardStore.cleanupSessionData();
		this.paymentStore.cleanupSessionData();
	}

}

export default new RootStore();
