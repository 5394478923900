import React, { Component, Fragment } from 'react';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import Localized from '../base/i18n/Localized';
import { autorun } from 'mobx';
import FormCheckboxButton from '../base/form/FormChecboxButton';
import FormError from '../base/form/FormError';
import Qs from 'qs';

const VALID_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Chat = inject('rootStore')(observer(
	class Chat extends Component {

		constructor(props) {
			super(props);

			this.formRef = React.createRef();

			this.handleInitiateChatClick = this.handleInitiateChatClick.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.chat.title');
			});
		}

		componentDidMount() {
			const { commonStore, userStore } = this.props.rootStore;
			const { authenticated, me } = userStore;
			const { locale } = commonStore;

			userStore.setError(false);
			userStore.setSuccess(false);
			
			userStore.clearChatForm();

			runInAction(() => {
				const searchParams = Qs.parse(window.location.search, {
					ignoreQueryPrefix: true
				});

				if (authenticated && me) {
					userStore.chat.accountNumber = !searchParams.accountNumber ? me.membershipNumber : searchParams.accountNumber;
					userStore.chat.name = !searchParams.name ? (locale === 'en' ? me.fullNameEn : me.fullNameAr) : searchParams.name;
					userStore.chat.email = !searchParams.email ? me.email : searchParams.email;
				} else {
					userStore.chat.accountNumber = searchParams.accountNumber ? searchParams.accountNumber : '';
					userStore.chat.name = searchParams.name ? searchParams.name : '';
					userStore.chat.email = searchParams.email ? searchParams.email : '';
				}
			});
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		handleAccountNumberChange = e => {
			if (new RegExp('^[0-9]*$').test(e.target.value)) {
				runInAction(() => {
					this.props.rootStore.userStore.chat.accountNumber = e.target.value;
				});
			}
		}

		handleNameChange = e => {
			runInAction(() => {
				this.props.rootStore.userStore.chat.name = e.target.value;
			});
		}

		handleEmailChange = e => {
			runInAction(() => {
				this.props.rootStore.userStore.chat.email = e.target.value;
			});
		}

		handleQuestionChange = e => {
			runInAction(() => {
				this.props.rootStore.userStore.chat.question = e.target.value;
			});
		}

		handleTranscriptChange = e => {
			runInAction(() => {
				this.props.rootStore.userStore.chat.transcript = e.target.checked;
			});
		}

		handleInitiateChatClick(e) {
			const { userStore } = this.props.rootStore;

			userStore.clearErrors();

			if (!this.validateForm()) {
				e.preventDefault();
			}
		}

		validateForm = () => {
			const { commonStore, userStore } = this.props.rootStore;
			const { errors, chat: { email, transcript } } = userStore;
			const { translateMessage } = commonStore;

			if ((email && email.length > 0) || transcript) {
				if (!VALID_EMAIL.test(email)) {
					userStore.addError('email', translateMessage('generic.error.invalidEmail'));
				}
			}

			if (errors.length > 0) {
				userStore.setError(translateMessage('generic.error.checkFields'));

				return false;
			}

			return true;
		}

		render() {
			const { commonStore, userStore } = this.props.rootStore;
			const { locale, translateMessage } = commonStore;
			const { error, errors, chat: { accountNumber, name, email, question, transcript } } = userStore;
			const formDisabled = !accountNumber || !name || !question;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.chat.title" />
					</h2>

					<div className="section__wrapper">
						<div className="chat">
							<div className="chat__form">
								<form ref={this.formRef} className="form" method="post" action="https://ccclive.contactcentre.ae/Fazaa/chat.asp">
									<p className="form__text form__text--center">
										<Localized code="page.chat.text.initiateChat" />
									</p>

									{!!error ? (
									<div className="form__message form__message--error">{error}</div>
									) : null}

									<input type="hidden" name="lang" value={locale} />
									<input type="hidden" name="ChatType" value="Fazaa app" />

									<div className="form__row">
										<div className="form__column form__column--half">
											<div className="form__group">
												<label className="form__label" htmlFor="accountNumber">
													<Localized code="page.chat.label.accountNumber" />
												</label>
												<input className="form__input" name="account_number" type="text" id="accountNumber" value={accountNumber} onChange={this.handleAccountNumberChange} />
											</div>
										</div>
									</div>

									<div className="form__row">
										<div className="form__column">
											<div className="form__group">
												<label className="form__label" htmlFor="name">
													<Localized code="page.chat.label.name" />
												</label>
												<input className="form__input" name="name" type="text" id="name" value={name} onChange={this.handleNameChange} />
											</div>
										</div>

										<div className="form__column">
											<div className="form__group">
												<label className="form__label" htmlFor="email">
													<Localized code="page.chat.label.email" />
												</label>
												<input className="form__input" name="mail" type="email" id="email" value={email} onChange={this.handleEmailChange} />
												<FormError errors={errors} field="email" />
											</div>
										</div>
									</div>

									<div className="form__group">
										<label className="form__label" htmlFor="question">
											<Localized code="page.chat.label.question" />
										</label>
										<textarea className="form__textarea" rows="5" id="question" value={question} onChange={this.handleQuestionChange} />
									</div>

									<div className="form__group">
										<div className="form__checkboxes">
											<FormCheckboxButton name="EmailSendTranscript" defaultValue="false" value="true" label={translateMessage('page.chat.label.transcript')} checked={transcript} onChange={this.handleTranscriptChange} />
										</div>
									</div>

									<div className="form__buttons">
										<button disabled={formDisabled} className="form__button form__button--gold" type="submit" onClick={this.handleInitiateChatClick}>
											<Localized code="page.chat.button.initiateChat" />
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Chat;
