import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { reportExceptionToSentry } from '../utils/Utils';

class UsedCarStore {

	loading = false;
	usedCars = [];
	total = 0;
	page = 0;

	previousResultCount = 0;

	lastScrollPosition = 0;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	setLoading(loading) {
		this.loading = loading;
	}

	async loadUsedCars(preserve = false) {
		this.setLoading(true);

		if (!preserve) {
			runInAction(() => {
				this.usedCars = [];
				this.total = 0;
				this.page = 0;
				this.previousResultCount = 0;
			});
		} else {
			runInAction(() => {
				this.page++;
			});
		}

		try {
			const response = await FazaaAPI.UsedCars.list(this.page);

			runInAction(() => {
				this.previousResultCount = this.usedCars.length;
				this.usedCars.push(...response.data.content);
				this.total = response.data.totalRows;
			});
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}
	}

	async loadUsedCar(slug) {
		this.setLoading(true);

		try {
			const response = await FazaaAPI.UsedCars.get(slug);

			return response.data;
		} catch (e) {
			reportExceptionToSentry(e, [404]);

			return null;
		} finally {
			this.setLoading(false);
		}
	}

}

decorate(UsedCarStore, {
	loading: observable,
	usedCars: observable,
	total: observable,
	page: observable,
	previousResultCount: observable,
	setLoading: action,
	loadUsedCars: action
});

export default UsedCarStore;
