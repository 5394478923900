import React, { PureComponent } from 'react';

class FileUpload extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			content: ''
		};

		this.fileInputRef = React.createRef();
	}

	componentDidMount() {
		const { placeholder } = this.props;

		this.setState({
			content: placeholder
		});
	}

	handleFileWrapClick = e => {
		this.fileInputRef.current.click();
	}

	handleFileChange = e => {
		const { placeholder, onChange } = this.props;
		const file = e.target.files[0];
		const accept = e.target.accept;

		if (accept) {
			const allowed = accept.split(',').map(type => type.trim());

			if (!allowed.includes(file.type) && !allowed.includes(file.type.split('/')[0] + '/*')) {
				alert('Selected file type is not of accepted type: ' + accept);

				return;
			}
		}

		this.setState({
			content: file ? file.name : placeholder
		});

		onChange(e);
	}

	render() {
		const { name, placeholder, onChange, ...rest } = this.props;

		return (
			<div className="form__file-wrap" data-content={this.state.content} onClick={this.handleFileWrapClick}>
				<input ref={this.fileInputRef} className="form__file" type="file" id={name} name={name} onChange={this.handleFileChange} {...rest} />
			</div>
		);
	}

}

export default FileUpload;
