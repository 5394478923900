import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

class Accordion extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			open: false
		};
	}

	toggle = () => {
		this.setState(state => ({
			open: !state.open
		}));
	};

	render() {
		const { open } = this.state;

		return (
			<div className={`accordion accordion--${open ? 'open' : 'closed'}`}>
				<div className="accordion__header" onClick={this.toggle}>
					<h5 className="accordion__title">{this.props.title}</h5>
					{open ? (
						<FontAwesomeIcon className="accordion__icon" icon={faChevronDown} />
					) : (
						this.props.locale === 'en' ? (
							<FontAwesomeIcon className="accordion__icon" icon={faChevronRight} />
						) : (
							<FontAwesomeIcon className="accordion__icon" icon={faChevronLeft} />
						)
					)}
				</div>

				<div className="accordion__content">
					{this.props.content}
				</div>
			</div>
		);
	}

};

export default Accordion;
