import Config from "../config/Config";

const SearchType = [];

SearchType.push({
	value: 'SERVICE', name: 'Service'
});

SearchType.push({
	value: 'OFFER', name: 'Offer'
});

if (!Config.HIDE_DYNAMIC_OFFERS) {
	SearchType.push({
		value: 'OFFER_EXTRA', name: 'Extra offer'
	});
}

SearchType.push({
	value: 'VOUCHER', name: 'Voucher'
});

Object.freeze(SearchType);

export default SearchType;
