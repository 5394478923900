import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Swiper from '@eredessil/react-id-swiper';
import Config from '../../../config/Config';
import Localized from '../../base/i18n/Localized';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import imgSliderIconArrowLeft from '../../../assets/img/slider-icon-arrow-left.png';
import imgSliderIconArrowRight from '../../../assets/img/slider-icon-arrow-right.png'

const Offers = inject('rootStore')(observer(
	class Offers extends Component {

		constructor(props) {
			super(props);

			this.goPrev = this.goPrev.bind(this);
			this.goNext = this.goNext.bind(this);

			this.swiperRef = React.createRef();
			this.swiperExtraRef = React.createRef();
		}

		goPrev(swiperRef) {
			if (document.documentElement.classList.contains('--rtl')) {
				swiperRef.current.swiper.slideNext();
			} else {
				swiperRef.current.swiper.slidePrev();
			}
		}

		goNext(swiperRef) {
			if (document.documentElement.classList.contains('--rtl')) {
				swiperRef.current.swiper.slidePrev();
			} else {
				swiperRef.current.swiper.slideNext();
			}
		}

		render() {
			const { commonStore, offerStore, lastOfferLoadPosition } = this.props.rootStore;
			const { locale } = commonStore;
			const { loading, offers, dynamicOffers } = offerStore;

			const swiperParams = {
				slidesPerView: 'auto',
				spaceBetween: 10,
				autoplay: {
					delay: 5000
				},
			};

			return (
				<Fragment>
					{!loading && offers.size > 0 ? (
						<section className="slider slider--offers slider--hover slider--white">
							<div className="slider__header">
								<h2 className="slider__title">
									<span className="slider__title-text">
										{lastOfferLoadPosition ? (
											<Localized code="home.offersNearMe.title" />
										) : (
											<Localized code="home.offers.title" />
										)}
									</span>
								</h2>

								<div className="slider__buttons">
									<Link to={`/search?language=${locale}&types=OFFER`} className="slider__button button button--gold button--small">
										<Localized code="home.button.viewMore" />
									</Link>
								</div>
							</div>

							<div className="slider__wrapper">
								<Swiper {...swiperParams} ref={this.swiperRef} rebuildOnUpdate>
									{Array.from(offers.values()).map(offer => (
										<div key={offer.id} className="slider__slide">
											<div className="slider__item">
												<Link to={`/offers/view/${offer.offerSlug}`} className="slider__link">
													<img className="slider__image" src={`${Config.UPLOADS_ENDPOINT}${offer.offerImageUri}`} alt={offer.localData[locale].title} />

													<div className="slider__meta">
														<div className="slider__info">
															<h5 className="slider__partner">{offer.localData[locale].subTitle}</h5>

															<p className="slider__text">{offer.localData[locale].title}</p>
														</div>

														{offer.discountType === 'PERCENTAGE' ? (
															<div className="slider__discount">{offer.discount}%</div>
														) : (
															<div className="slider__discount">{offer.discount}AED</div>
														)}
													</div>
												</Link>
											</div>
										</div>
									))}

									<div className="slider__slide">
										<div className="slider__item">
											<Link to={`/search?language=${locale}&types=OFFER`} className="slider__link slider__link--more">
												<p className="slider__more-text">
													<Localized code="home.button.viewMore" />
												</p>
											</Link>
										</div>
									</div>
								</Swiper>

								<div className="slider__left" onClick={() => this.goPrev(this.swiperRef)}>
									<img className="slider__arrow" src={imgSliderIconArrowLeft} alt="Slide left" />
								</div>

								<div className="slider__right" onClick={() => this.goNext(this.swiperRef)}>
									<img className="slider__arrow" src={imgSliderIconArrowRight} alt="Slide right" />
								</div>
							</div>
						</section>
					) : null}

					{!Config.HIDE_DYNAMIC_OFFERS && !loading && dynamicOffers.size > 0 ? (
						<section className="slider slider--offers slider--hover slider--white">
							<div className="slider__header">
								<h2 className="slider__title">
									<span className="slider__title-text">
										<Localized code="home.offersExtra.title" />
									</span>
								</h2>

								<div className="slider__buttons">
									<Link to={`/search?language=${locale}&types=OFFER_EXTRA`} className="slider__button button button--gold button--small">
										<Localized code="home.button.viewMore" />
									</Link>
								</div>
							</div>

							<div className="slider__wrapper">
								<Swiper {...swiperParams} ref={this.swiperExtraRef} rebuildOnUpdate>
									{Array.from(dynamicOffers.values()).map(dynamicOffer => (
										<div key={dynamicOffer.id} className="slider__slide">
											<div className="slider__item">
												<Link to={`/extra-offers/view/${dynamicOffer.offerSlug}`} className="slider__link">
													<img className="slider__image" src={`${Config.UPLOADS_ENDPOINT}${dynamicOffer.offerImageUri}`} alt={dynamicOffer.localData[locale].title} />

													<div className="slider__meta">
														<div className="slider__info">
															<h5 className="slider__partner">{dynamicOffer.localData[locale].subTitle}</h5>

															<p className="slider__text">{dynamicOffer.localData[locale].title}</p>
														</div>

														{dynamicOffer.discountType === 'PERCENTAGE' ? (
															<div className="slider__discount">{dynamicOffer.discount}%</div>
														) : (
															<div className="slider__discount">{dynamicOffer.discount}AED</div>
														)}
													</div>

													<div className="slider__icon-wrapper">
														<FontAwesomeIcon className="slider__icon" icon={faStar} />
													</div>
												</Link>
											</div>
										</div>
									))}

									<div className="slider__slide">
										<div className="slider__item">
											<Link to={`/search?language=${locale}&types=OFFER_EXTRA`} className="slider__link slider__link--more">
												<p className="slider__more-text">
													<Localized code="home.button.viewMore" />
												</p>
											</Link>
										</div>
									</div>
								</Swiper>

								<div className="slider__left" onClick={() => this.goPrev(this.swiperExtraRef)}>
									<img className="slider__arrow" src={imgSliderIconArrowLeft} alt="Slide left" />
								</div>

								<div className="slider__right" onClick={() => this.goNext(this.swiperExtraRef)}>
									<img className="slider__arrow" src={imgSliderIconArrowRight} alt="Slide right" />
								</div>
							</div>
						</section>
					) : null}
				</Fragment>
			);
		}

	}
));

export default Offers;
