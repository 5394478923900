import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { reportExceptionToSentry } from '../utils/Utils'

class EstoreStore {

	storeActive = false;

	listingType = 'grid';
	sortingType = 'newest';
	perPage = 6;

	loading = false;
	error = false;
	query = '';
	page = 0;
	categories = [];
	selectedCategory = undefined;
	subCategories = [];
	selectedSubCategories = [];
	total = 0;
	results = [];
	previousResultCount = 0;
	product = undefined;
	currentProductImage = undefined;

	lastScrollPosition = 0;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	setStoreActive(storeActive) {
		this.storeActive = storeActive;
	}

	setListingType(listingType) {
		this.listingType = listingType;
	}

	setSortingType(sortingType) {
		this.sortingType = sortingType;
	}

	setPerPage(perPage) {
		this.perPage = perPage;
	}

	setLoading(loading) {
		this.loading = loading;
	}

	setError(error) {
		this.error = error;
	}

	setQuery(query) {
		this.query = query;
	}

	setCategories(categories) {
		this.categories = categories;
	}

	setSelectedCategory(selectedCategory) {
		this.selectedCategory = selectedCategory;
	}

	setSubCategories(subCategories) {
		this.subCategories = subCategories;
	}

	setSelectedSubCategories(selectedSubCategories) {
		this.selectedSubCategories = selectedSubCategories;
	}

	setProduct(product) {
		this.product = product;
	}

	setCurrentProductImage(image) {
		this.currentProductImage = image;
	}

	clearResults() {
		this.page = 0;
		this.selectedCategory = undefined;
		this.selectedSubCategories = [];
		this.sortingType = 'newest';
		this.total = 0;
		this.results = [];
		this.previousResultCount = 0;

		this.lastScrollPosition = 0;
	}

	async loadCategories() {
		try {
			const response = await FazaaAPI.Estore.categories();

			runInAction(() => {
				this.categories = response.data;
			});

			return true;
		} catch (e) {
			// TODO Refactor needed as it will not affect any error display
			this.rootStore.commonStore.setGeneralError('Unable to load store categories data, general application error, please reload the page.');

			reportExceptionToSentry(e);

			return false;
		}
	}

	async loadSubCategories(id) {
		try {
			const response = await FazaaAPI.Estore.subCategories(id);

			runInAction(() => {
				this.subCategories = response.data;
			});

			return true;
		} catch (e) {
			// TODO Refactor needed as it will not affect any error display
			this.rootStore.commonStore.setGeneralError('Unable to load store subcategories data, general application error, please reload the page.');

			reportExceptionToSentry(e);

			return false;
		}
	}

	async doSearch(params, preserve = false) {
		// TODO Maybe validate params?
		this.setLoading(true);

		if (!preserve) {
			this.clearResults();
		}

		runInAction(() => {
			params.page = preserve ? ++this.page : this.page;

			if (this.query !== params.q) {
				this.query = params.q ? params.q : '';
			}

			if (params.q === '') {
				delete params.q;
			}

			if (!!params.category) {
				this.setSelectedCategory(params.category);
			}

			if (!!params.subcategories) {
				this.setSelectedSubCategories(Array.isArray(params.subcategories) ? params.subcategories : [params.subcategories]);
			}

			// Sort
			//params.sortField = this.sortingType === 'cheapest' || this.sortingType === 'expensive' ? 'PRICE' : 'CREATED_AT';
			//params.sortDirection = this.sortingType === 'cheapest' || this.sortingType === 'oldest' ? 'ASC' : 'DESC';

			params.size = this.perPage;
			
			// Make sure language is always present
			if (!params.language) {
				if (this.rootStore.commonStore.locale) {
					params.language = this.rootStore.commonStore.locale;
				} else {
					params.language = 'en';
				}
			}
		});

		try {
			const response = await FazaaAPI.Estore.search(params);

			runInAction(() => {
				this.previousResultCount = this.results.length;
				this.results.push(...response.data.content);
				this.total = response.data.total;
			});
		} catch (e) {
			this.setError(true);

			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}
	}

	async loadProduct(slug, ret = false) {
		this.setLoading(true);

		try {
			const response = await FazaaAPI.Estore.get(slug, this.rootStore.commonStore.locale);

			if (ret) {
				return response.data;
			} else {
				runInAction(() => {
					this.product = response.data;
				});

				return true;
			}
		} catch (e) {
			// TODO Refactor needed as it will not affect any error display
			this.rootStore.commonStore.setGeneralError('Unable to load product data, general application error, please reload the page.');

			reportExceptionToSentry(e, [404]);

			return false;
		} finally {
			this.setLoading(false);
		}
	}

	async checkStock(id, qty) {
		try {
			const response = await FazaaAPI.Estore.stock(id, qty);

			return response.data;
		} catch (e) {
			reportExceptionToSentry(e);

			return false;
		}
	}

}

decorate(EstoreStore, {
	storeActive: observable,
	listingType: observable,
	sortingType: observable,
	perPage: observable,
	loading: observable,
	error: observable,
	query: observable,
	page: observable,
	categories: observable,
	selectedCategory: observable,
	subCategories: observable,
	selectedSubCategories: observable,
	total: observable,
	results: observable,
	previousResultCount: observable,
	product: observable,
	currentProductImage: observable,
	lastScrollPosition: observable,
	setStoreActive: action,
	setListingType: action,
	setSortingType: action,
	setPerPage: action,
	setLoading: action,
	setError: action,
	setQuery: action,
	setCategories: action,
	setSelectedCategory: action,
	setSubCategories: action,
	setSelectedSubCategories: action,
	setProduct: action,
	setCurrentProductImage: action,
	loadCategories: action,
	loadSubCategories: action,
	doSearch: action
});

export default EstoreStore;
