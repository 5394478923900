import React, { PureComponent } from 'react';

export default class DateInput extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			readOnly: false
		};
	}

  	render() {
    	return (
      		<input
        		{...this.props}
        		onFocus={() => this.setState({readOnly: true})}
        		onBlur={() => this.setState({readOnly: false})}
        		readOnly={this.state.readOnly}
      		/>
    	);
	}
	  
}
