import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { chunk } from 'lodash';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loaders';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Localized from '../../base/i18n/Localized'
import SearchLink from '../../base/link/SearchLink';
import Config from '../../../config/Config';

const FazaaHealth = inject('rootStore')(withRouter(observer(
	class FazaaHealth extends Component {

		constructor(props) {
			super(props);

			const { searchStore } = props.rootStore;

			searchStore.setLoading(true);
			searchStore.setError(false);
			searchStore.clearResults();
	
			this.scrollListener = this.scrollListener.bind(this);
			this.triggerSearch = this.triggerSearch.bind(this);
		}

		componentDidMount() {
			this.triggerSearch();

			// Add scroll event listener
			window.addEventListener('scroll', this.scrollListener);
		}

		componentDidUpdate(prevProps) {
			const { locale } = this.props;

			// If locale is changed trigger search again with proper category name
			if (prevProps.locale !== locale) {
				this.triggerSearch();
			}
		}

		componentWillUnmount() {
			// Remove scroll event listener
			window.removeEventListener('scroll', this.scrollListener);
		}

		scrollListener(e) {
			const { rootStore } = this.props;
			const { footerRef } = rootStore.commonStore;
			const { searchStore } = rootStore;
			const { loading } = searchStore;

			if (searchStore.lastScrollPosition > window.pageYOffset) {
				return;
			}

			if (footerRef == null) {
				return;
			}

			if (window.pageYOffset + window.innerHeight <= document.body.scrollHeight - footerRef.scrollHeight) {
				return;
			}

			if (searchStore.total === searchStore.results.length) {
				return;
			}

			if (!loading) {
				searchStore.lastScrollPosition = window.pageYOffset;

				this.triggerSearch(true);
			}
		}

		triggerSearch(nextPage = false) {
			const { commonStore, searchStore } = this.props.rootStore;
			const { locale } = commonStore;
			const fazaaHealthCategory = searchStore.availableCategories.find(ac => ac.categoryInternalName === 'Fazaa Health');

			searchStore.doSearch({
				language: locale,
				categories: fazaaHealthCategory.localData[locale].name,
				types: ['OFFER', 'VOUCHER']
			}, nextPage);
		}

		render() {
			const { commonStore, searchStore } = this.props.rootStore;
			const { loading, previousResultCount } = searchStore;
			const results = searchStore.total > 0 ? chunk(searchStore.results, 4) : [];

			return (
				<section className="section section--gray" style={{ paddingTop : 0 }}>
					<div className="section__wrapper">
						<div className="search-page">
							{results.length > 0 ? (
								<div className="search-page__listing">
									{results.map((row, rowIndex) => (
										<TransitionGroup key={rowIndex} appear className="search-page__listing-row">
											{row.map((result, index) => {
												const delay = (rowIndex * 200) + (index * 50) - (previousResultCount * 50);

												return (
													<CSSTransition key={result.id} timeout={300 + delay} classNames="search-page__result">
														<div className="search-page__result" style={{transitionDelay: `${delay}ms`}}>
															<SearchLink className="search-page__result-link" result={result}>
																<div className="search-page__result-image-wrapper">
																	<img className="search-page__result-image" src={`${Config.UPLOADS_ENDPOINT}${result.imageUri}`} alt={result.title} />

																	<div className="search-page__result-type">{commonStore.translateMessage('page.search.label.' + result.type.toLowerCase())}</div>
																</div>

																<div className="search-page_result-meta">
																	{!!result.partnerName ? (
																		<h3 className="search-page__result-partner">{result.partnerName}</h3>
																	) : null}
																	
																	<p className="search-page__result-title">{result.title}</p>
																</div>
															</SearchLink>
														</div>
													</CSSTransition>
												)
											})}
										</TransitionGroup>
									))}
								</div>
							) : null}

							{results.length === 0 && !loading ? (
							<div className="search-page__message">
								<p className="search-page__message-text">
									<Localized code="page.search.text.noResults" />
								</p>
							</div>
							) : null}

							{loading ? (
								<div className="search-page__loading">
									<Loader type="line-scale-pulse-out-rapid" color="#002652" />
								</div>
							) : null}
						</div>
					</div>
				</section>
			);
		}

	}
)));

export default FazaaHealth;
