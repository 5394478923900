import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Localized from '../../base/i18n/Localized';
import Loader from 'react-loaders';
import Config from '../../../config/Config';
import { chunk } from 'lodash';

const UsedCars = inject('rootStore')(observer(
	class UsedCars extends Component {

		constructor(props) {
			super(props);
	
			this.scrollListener = this.scrollListener.bind(this);
		}

		componentDidMount() {
			const { usedCarStore } = this.props.rootStore;

			usedCarStore.loadUsedCars(false);

			// Add scroll event listener
			window.addEventListener('scroll', this.scrollListener);
		}

		componentWillUnmount() {
			// Remove scroll event listener
			window.removeEventListener('scroll', this.scrollListener);
		}

		scrollListener(e) {
			const { commonStore, usedCarStore } = this.props.rootStore;
			const { footerRef } = commonStore;
			const { loading } = usedCarStore;

			if (usedCarStore.lastScrollPosition > window.pageYOffset) {
				return;
			}

			if (footerRef == null) {
				return;
			}

			if (window.pageYOffset + window.innerHeight <= document.body.scrollHeight - footerRef.scrollHeight) {
				return;
			}

			if (usedCarStore.total === usedCarStore.usedCars.length) {
				return;
			}

			if (!loading) {
				usedCarStore.lastScrollPosition = window.pageYOffset;

				usedCarStore.loadUsedCars(true);
			}
		}

		render() {
			const { service } = this.props;
			const { commonStore, usedCarStore } = this.props.rootStore;
			const { locale } = commonStore;
			const { loading, previousResultCount } = usedCarStore;
			const results = usedCarStore.total > 0 ? chunk(usedCarStore.usedCars, 4) : [];

			return (
				<section className="section section--gray" style={{ paddingTop : 0 }}>
					<div className="section__wrapper">
						<div className="search-page">
							{results.length > 0 ? (
								<div className="search-page__listing">
									{results.map((row, rowIndex) => (
										<TransitionGroup key={rowIndex} appear className="search-page__listing-row">
											{row.map((result, index) => {
												const delay = (rowIndex * 200) + (index * 50) - (previousResultCount * 50);

												// TODO key should be id
												return (
													<CSSTransition key={result.carSlug} timeout={300 + delay} classNames="search-page__result">
														<div className="search-page__result" style={{transitionDelay: `${delay}ms`}}>
															<Link className="search-page__result-link" to={`/services/view/${service.serviceSlug}/${result.carSlug}`}>
																<div className="search-page__result-image-wrapper">
																	<img className="search-page__result-image" src={`${Config.UPLOADS_ENDPOINT}${result.carImageUri}`} alt={result.localData[locale].name} />

																	{false ? (
																		<div className="search-page__result-type">{commonStore.translateMessage('page.search.label.service')}</div>
																	) : null}

																	{result.carStatus === 'SOLD' ? (
																		<div className="search-page__result-subtype search-page__result-subtype--sold">
																			<Localized code="page.usedCars.text.sold" />
																		</div>
																	) : null}

																	{result.carStatus === 'RESERVED' ? (
																		<div className="search-page__result-subtype search-page__result-subtype--reserved">
																			<Localized code="page.usedCars.text.reserved" />
																		</div>
																	) : null}
																</div>

																<div className="search-page_result-meta">										
																	<h3 className="search-page__result-main-title">{result.localData[locale].name}</h3>
																	<p className="search-page__result-price">{new Intl.NumberFormat().format(result.price)} AED</p>
																</div>
															</Link>
														</div>
													</CSSTransition>
												)
											})}
										</TransitionGroup>
									))}
								</div>
							) : null}

							{results.length === 0 && !loading ? (
								<div className="search-page__message">
									<p className="search-page__message-text">
										<Localized code="page.search.text.noResults" />
									</p>
								</div>
							) : null}

							{loading ? (
								<div className="search-page__loading">
									<Loader type="line-scale-pulse-out-rapid" color="#002652" />
								</div>
							) : null}
						</div>
					</div>
				</section>
			);
		}

	}
));

export default UsedCars;
