import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CategoriesStrip from '../base/categories/CategoriesStrip';
import Swiper from '@eredessil/react-id-swiper';
import GoogleMap from '../base/map/GoogleMap';
import HTTP404 from '../base/error/HTTP404';
import Config from '../../config/Config';
import moment from 'moment-timezone';
import Localized from '../base/i18n/Localized';
import { autorun } from 'mobx';
import DaysOfWeek from '../../types/DaysOfWeek';
import CardImages from '../../types/CardImages';
import QrCode from 'react-qrcode-svg';
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, FacebookMessengerShareButton, FacebookMessengerIcon, TwitterShareButton, TwitterIcon, TelegramShareButton, TelegramIcon, WhatsappShareButton, WhatsappIcon, ViberShareButton, ViberIcon, LinkedinShareButton, LinkedinIcon } from 'react-share';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faStar } from '@fortawesome/free-solid-svg-icons';

import imgSliderIconArrowLeft from '../../assets/img/slider-icon-arrow-left.png'
import imgSliderIconArrowRight from '../../assets/img/slider-icon-arrow-right.png'

moment.tz.setDefault('Asia/Dubai');

const Offer = inject('rootStore')(observer(
	class Offer extends Component {

		constructor(props) {
			super(props);

			this.swiperRef = React.createRef();

			const { offerStore } = props.rootStore;

			offerStore.setLoading(true);

			this.mobxAutorunDisposer = autorun(() => {
				const { commonStore, offerStore } = props.rootStore;
				const { locale, pageTitlePrefix } = commonStore;
				const { slug } = this.props.match.params;

				let offer = undefined;

				if (props.dynamic) {
					offer = offerStore.getDynamicOffer(slug);
					
					if (offer) {
						offer = offer.offer;
					}
				} else {
					offer = offerStore.getOffer(slug);
				}

				if (offer) {
					document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + offer.partner.partnerName + ' - ' + offer.localData[locale].title;
				} else {
					document.title = pageTitlePrefix;
				}
			});
		}

		componentDidMount() {
			const { slug } = this.props.match.params;

			if (this.props.dynamic) {
				this.props.rootStore.offerStore.loadDynamicOffer(slug, true);
			} else {
				this.props.rootStore.offerStore.loadOffer(slug);
			}

			this.props.rootStore.offerStore.setViewingOffer(true);
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();

			this.props.rootStore.offerStore.setViewingOffer(false);
		}

		render() {
			const { locale } = this.props.rootStore.commonStore;
			const { offerStore, userStore } = this.props.rootStore;
			const { loading } = offerStore;
			const { me } = userStore;
			const { slug } = this.props.match.params;

			let offer = undefined;
			let offerToken = undefined;

			if (this.props.dynamic) {
				offer = offerStore.getDynamicOffer(slug);
				offerToken = offerStore.getDynamicOfferToken(slug);
			} else {
				offer = offerStore.getOffer(slug);
			}

			const swiperParams = {
				slidesPerView: 1,
				navigation: {
					prevEl: '.offer-page__gallery-left',
					nextEl: '.offer-page__gallery-right'
				},
				renderPrevButton: () => (
					<div className="offer-page__gallery-left">
						<img className="offer-page__gallery-arrow" src={imgSliderIconArrowLeft} alt="Slide left" />
					</div>
				),
  				renderNextButton: () => (
					<div className="offer-page__gallery-right">
						<img className="offer-page__gallery-arrow" src={imgSliderIconArrowRight} alt="Slide right" />
					</div>
				)
			};

			const shareUrl = window.location;

			return (
				!loading ? (
					offer ? (
						<section className="section section--gray">
							<div className="section__wrapper">
								<div className="offer-page">
									<div className="offer-page__main">
										<div className="offer-page__gallery">
											{offer.images && offer.images.length > 0 ? (
												<Swiper {...swiperParams} ref={this.swiperRef} rebuildOnUpdate>
													{offer.images.map(image => (
														<div key={image.id} className="offer-page__slide">
															<img className="offer-page__image" src={`${Config.UPLOADS_ENDPOINT}${image.imageUri}`} alt="" />
														</div>
													))}
												</Swiper>
											) : (
												<img className="offer-page__image" src={`${Config.UPLOADS_ENDPOINT}${offer.offerImageUri}`} alt={offer.localData[locale].title} />
											)}
										</div>

										<div className="offer-page__description">
											{offer.categories && (
												<CategoriesStrip categories={offer.categories} />
											)}

											<h3 className="offer-page__partner">{offer.localData[locale].subTitle}</h3>

											<h2 className="offer-page__title">{offer.localData[locale].title}</h2>

											<div className="offer-page__description-text cms-generated" dangerouslySetInnerHTML={{__html: offer.localData[locale].shortDescription}} />

											<p className="offer-page__expiry">
												<Localized code="page.offer.text.availableUntil" date={moment(offer.offerExpiry).format('DD/MM/YY')} />
											</p>

											{this.props.dynamic && (
												<div className="offer-page__extra">
													<div className="offer-page__subtitle">
														<FontAwesomeIcon className="offer-page__extra-icon" icon={faStar} />
														<Localized code="page.offer.extra.title" />
													</div>

													{offer.validThruDays && offer.validThruDays.length > 0 && (
														<div className="offer-page__extra-wrapper">
															<div className="offer-page__extra-subtitle">
																<Localized code="page.offer.extra.validity" />
															</div>

															<div className="offer-page__extra-content">
																<div className="offer-page__validity">
																	{DaysOfWeek.map(dow => (
																		<div key={dow} className={`offer-page__dow ${offer.validThruDays.includes(dow) ? 'offer-page__dow--valid' : ''}`}>
																			<FontAwesomeIcon className="offer-page__dow-circle" icon={faCircle} />
																			<div className="offer-page__dow-name">{dow[0]}</div>
																		</div>
																	))}
																</div>
															</div>
														</div>
													)}

													{offer.tier && offer.tier.length > 0 && (
														<div className="offer-page__extra-wrapper">
															<div className="offer-page__extra-subtitle">
																<Localized code="page.offer.extra.tiers" />
															</div>

															<div className="offer-page__extra-content">
																<div className="offer-page__cards">
																	{CardImages.filter(ci => offer.tier.map(t => t.levelId).includes(ci.levelId)).map(ci => (
																		<div className="offer-page__card">
																			<img src={ci.image} className="offer-page__card-image" />
																		</div>
																	))}
																</div>
															</div>
														</div>
													)}

													{offer.hasStock && offer.stockView && (
														<div className="offer-page__extra-wrapper">
															<div className="offer-page__extra-content">
																<div className="offer-page__stock">
																	<Localized code="page.offer.extra.stock" />: <span className="offer-page__stock-unused">{offer.stockView.stockCountUnused}</span> / {offer.stockView.stockCountUnused + offer.stockView.stockCountUsed}
																</div>
															</div>
														</div>
													)}

													<div className="offer-page__subtitle">
														<Localized code="page.offer.extra.redeem" />
													</div>

													{offerToken ? (
														<Fragment>
															<p className="section__text">
																<Localized code="page.offer.extra.instructions" />
															</p>

															<div className="offer-page__redeem">
																<div className="offer-page__member">
																	<div className="offer-page__extra-wrapper">
																		<div className="offer-page__extra-subtitle">
																			<Localized code="page.offer.extra.memberId" />
																		</div>

																		<div className="offer-page__extra-content">
																			<div className="offer-page__member-id">{me && me.membershipNumber}-{me && (parseInt(me.membershipNumber) % 97)}</div>
																		</div>
																	</div>
																</div>

																<div className="offer-page__qr">
																	<QrCode
																		data={Config.CASHIER_ENDPOINT + offerToken}
																		height="300"
																		width="300"
																		fgColor="#000"
																		bgColor="#FFF"
																		margin={0}
																	/>
																</div>
															</div>
														</Fragment>
													) : (
														<p className="section__text">
															<Localized code="page.offer.extra.noRedeem" />
														</p>
													)}
												</div>
											)}

											<div className="offer-page__share">
												<EmailShareButton url={shareUrl} className="offer-page__share-button">
													<EmailIcon size={32} round className="offer-page__share-icon" />
												</EmailShareButton>

												<FacebookShareButton url={shareUrl} className="offer-page__share-button">
													<FacebookIcon size={32} round className="offer-page__share-icon" />
												</FacebookShareButton>

												<FacebookMessengerShareButton url={shareUrl} className="offer-page__share-button">
													<FacebookMessengerIcon size={32} round className="offer-page__share-icon" />
												</FacebookMessengerShareButton>

												<TwitterShareButton url={shareUrl} className="offer-page__share-button">
													<TwitterIcon size={32} round className="offer-page__share-icon" />
												</TwitterShareButton>

												<TelegramShareButton url={shareUrl} className="offer-page__share-button">
													<TelegramIcon size={32} round className="offer-page__share-icon" />
												</TelegramShareButton>

												<WhatsappShareButton url={shareUrl} className="offer-page__share-button">
													<WhatsappIcon size={32} round className="offer-page__share-icon" />
												</WhatsappShareButton>

												<ViberShareButton url={shareUrl} className="offer-page__share-button">
													<ViberIcon size={32} round className="offer-page__share-icon" />
												</ViberShareButton>

												<LinkedinShareButton url={shareUrl} className="offer-page__share-button">
													<LinkedinIcon size={32} round className="offer-page__share-icon" />
												</LinkedinShareButton>
											</div>
										</div>
									</div>

									<div className="offer-page__extended">
										<Tabs>
											<TabList>
												<Tab>
													<Localized code="page.offer.tab.description" />
												</Tab>
												<Tab>
													<Localized code="page.offer.tab.locations" />
												</Tab>
											</TabList>

											<TabPanel>
												{offer.partner.partnerLink ? (
													<div className="offer-page__book-now">
														<a href={offer.partner.partnerLink} className="button button--gold button--small offer-page__book-now-button">
															<Localized code="page.offer.button.bookNow" />
														</a>
													</div>
												) : null}

												<div className="cms-generated" dangerouslySetInnerHTML={{__html: offer.localData[locale].description}} />
											</TabPanel>

											<TabPanel className="react-tabs__tab-panel react-tabs__tab-panel--map">
												{offer.locations.length > 0 ? (
												<div className="offer-page__map">
													<GoogleMap locations={offer.locations} locale={locale} />
												</div>
												) : (
												<p className="offer-page__no-locations">
													<Localized code="page.offer.text.noLocations" />
												</p>
												)}
											</TabPanel>
										</Tabs>
									</div>
								</div>
							</div>
						</section>
					) : (
						<HTTP404 />
					)
				) : null
			);
		}

	}
));

export default Offer;
