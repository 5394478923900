import { Component } from 'react';
import { inject, observer } from 'mobx-react';

const Localized = inject('rootStore')(observer(
	class Localized extends Component {

		render() {
			const { rootStore, code, ...rest } = this.props;
			const { commonStore } = rootStore;

			return commonStore.translateMessage(code, rest);
		}

	}
));

export default Localized;
