import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import FormError from '../../base/form/FormError';
import ReactLoading from 'react-loading';

//const VALID_EMAIL = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
const VALID_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const VALID_MOBILE_NO_SPACE = /^\+9715[024568][0-9]{7}$/;

const Profile = inject('rootStore')(observer(
	class Profile extends Component {

		constructor(props) {
			super(props);

			this.profileRef = React.createRef();

			this.handleSendSmsVerificationCode = this.handleSendSmsVerificationCode.bind(this);
			this.handleSmsVerificationClick = this.handleSmsVerificationClick.bind(this);
			this.handleUpdateProfileClick = this.handleUpdateProfileClick.bind(this);

			this.scrollToTop = this.scrollToTop.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.profile.title');
			});
		}

		componentDidMount() {
			const { userStore } = this.props.rootStore;

			userStore.resetRegistrationFields();
			userStore.loadProfileFields();
			userStore.verifyProfile();
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		handleEmailChange = e => {
			this.props.rootStore.userStore.setEmail(e.target.value);
			this.props.rootStore.userStore.clearError('email');
		};

		handleMobileChange = e => { 
			if (e.target.validity.valid) {
				this.props.rootStore.userStore.setMobile(e.target.value);
			}
		};

		handleLanguageChange = e => this.props.rootStore.userStore.setLanguage(e.target.value);

		handleSmsCodeChange = e => this.props.rootStore.userStore.setSmsCode(e.target.value);

		handleNameArabicChange = e => {
			return;

			if (e.target.validity.valid) {
				this.props.rootStore.userStore.setNameAr(e.target.value);
			}
		};

		handleNameEnglishChange = e => {
			return;

			if (e.target.validity.valid) {
				this.props.rootStore.userStore.setNameEn(e.target.value);
			}
		};

		handleSendSmsVerificationCode(e) {
			e.preventDefault();

			const { userStore }	= this.props.rootStore;

			userStore.clearErrors();
			userStore.sendSms();
		}

		async handleSmsVerificationClick(e) {
			e.preventDefault();

			const { userStore }	= this.props.rootStore;

			userStore.clearErrors();

			if (!await userStore.verifySms()) {
				this.scrollToTop();
			}
		}

		async handleUpdateProfileClick(e) {
			e.preventDefault();

			const { commonStore, userStore } = this.props.rootStore;
			const { translateMessage } = commonStore;
			const { email, mobile, language, nameAr, nameEn } = userStore;

			userStore.clearErrors();

			// Validate
			// - Email (RFC2822)
			if (!VALID_EMAIL.test(email)) {
				userStore.addError('email', translateMessage('generic.error.invalidEmail'));
			}

			// - Email (check if it's in use) (was commented out ? why?)
			if (VALID_EMAIL.test(email)) {
				if (await userStore.checkEmail(true) === false) {
					userStore.addError('email', translateMessage('generic.error.emailInUse'));
				}
			}
			
			// - Mobile (UAE numbers)
			if (!VALID_MOBILE_NO_SPACE.test(mobile.replace(new RegExp(' ', 'g'), ''))) {
				userStore.addError('mobile', translateMessage('generic.error.invalidMobile'));
			}

			// - Mobile (check if it's in use)
			if (VALID_MOBILE_NO_SPACE.test(mobile.replace(new RegExp(' ', 'g'), ''))) {
				if (await userStore.checkMobile(true) === false) {
					userStore.addError('mobile', translateMessage('generic.error.mobileInUse'));
				}
			}

			// - Preferred language
			if (!language) {
				userStore.addError('language', translateMessage('generic.error.languageNotSelected'));
			}

			// - Full names (check if it contains a space)
			if (nameAr && !nameAr.trim().includes(' ')) {
				userStore.addError('nameAr', translateMessage('generic.error.nameMustContainSpace'));
			}

			if (!nameEn.trim().includes(' ')) {
				userStore.addError('nameEn', translateMessage('generic.error.nameMustContainSpace'));
			}

			if (userStore.errors.length === 0) {
				userStore.updateProfile().then(() => {
					this.scrollToTop();

					const { redirectUrl, redirectRequiresProfile, isProfileComplete } = userStore;

					if (redirectUrl && (!redirectRequiresProfile || (redirectRequiresProfile && isProfileComplete))) {
						userStore.ssoTokenRH().then(token => {
							if (token) {
								window.location = redirectUrl + token;
	
								return;
							} else {
								userStore.setRedirectUrl('');
								userStore.setRedirectRequiresProfile(false);
							}
						});
					}
				});
			} else {
				this.scrollToTop();
			}
		}

		scrollToTop() {
			if (this.profileRef.current) {
				requestAnimationFrame(() => {
					this.profileRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				});
			}
		}

		render() {
			const { commonStore, userStore } = this.props.rootStore;
			const { languages, translateMessage } = commonStore;
			const { email, mobile, mobileVerified, language, smsCode, smsResendTimer, nameAr, nameEn, error, success, loading, errors, isProfileComplete } = userStore;
			const updateDisabled = !(email && mobile && mobileVerified && language /*&& nameAr*/ && nameEn);

			return (
				<section ref={this.profileRef} className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.profile.title" />
					</h2>

					<div className="section__wrapper">
						<div className="account-profile">
							<div className="account-profile__form">
								<form className="form">
									{!!success ? (
										<div className="form__section">
											<h4 className="form__title">
												<Localized code="page.profile.subtitle.success" />
											</h4>

											<p className="form__text">
												<Localized code="page.profile.text.success" />
											</p>

											<p className="form__text">
												<Link to="/account" className="button button--gold">
													<Localized code="page.profile.link.goBack" />
												</Link>
											</p>
										</div>
									) : null}

									{!success ? (
										<Fragment>
											{!isProfileComplete ? (
												<p className="form__text form__text--notice form__text--center">
													<Localized code="page.profile.text.updateProfileRequired" />
												</p>
											) : null}

											<p className="form__text form__text--center">
												<Localized code="page.profile.text.updateProfile" />
											</p>

											{!!error ? (
												<div className="form__message form__message--error">{error}</div>
											) : null}

											<div className="form__section">
												<h4 className="form__title">
													<Localized code="page.signup.subtitle.personalInformation" />
												</h4>

												<div className="form__row">
													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="email">
																<Localized code="page.signup.label.email" />
															</label>
															<input dir="ltr" className="form__input" type="email" id="email" value={email} onChange={this.handleEmailChange} />
															<FormError errors={errors} field="email" />
														</div>
													</div>

													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="mobile">
																<Localized code="page.signup.label.mobile" />
															</label>
															<InputMask dir="ltr" className="form__input" type="text" id="mobile" value={mobile} onChange={this.handleMobileChange} mask="+\971 5a 999 9999" formatChars={{9: '[0-9]', a: '[0|2|4|5|6|8]'}} />
															<FormError errors={errors} field="mobile" />
														</div>
													</div>
												</div>

												<div className="form__row">
													<div className="form__column form__column--half">
														<div className="form__group">
															<label className="form__label" htmlFor="language">
																<Localized code="page.signup.label.preferredLanguage" />
															</label>
															<div className="form__select-wrap">
																<select className="form__select" id="language" value={language} onChange={this.handleLanguageChange}>
																	<option value="">{translateMessage('generic.label.pleaseSelect')}</option>
																	{languages.map(language => (
																		<option key={language.id} value={language.id}>{language.languageName}</option>
																	))}
																</select>
																<FormError errors={errors} field="language" />
															</div>
														</div>
													</div>
												</div>
											</div>

											{!mobileVerified || (mobileVerified && smsCode) ? (
											<div className="form__section">
												<h4 className="form__title">
													<Localized code="page.signup.subtitle.smsVerification" />
												</h4>

												{mobileVerified && smsCode ? (
													<p className="form__text">
														<Localized code="page.profile.text.newMobileVerified" />
													</p>
												) : (
													<Fragment>
														<p className="form__text">
															<Localized code="page.profile.text.verifyNewMobile" />
														</p>

														<div className="form__row">
															<div className="form__column">
																<div className="form__group">
																	<label className="form__label" htmlFor="smsCode">
																		<Localized code="page.signup.label.smsCode" />
																	</label>
																	<input className="form__input" type="text" id="smsCode" value={smsCode} onChange={this.handleSmsCodeChange} />
																</div>
															</div>

															<div className="form__column form__column--button-only">
																<button disabled={smsResendTimer > 0} className="form__button form__button--small form__button--blue" type="button" onClick={this.handleSendSmsVerificationCode}>
																	<Localized code="page.profile.button.sendSMS" />
																	{smsResendTimer > 0 ? ' (' + smsResendTimer + ')' : '' }
																</button>

																<button disabled={!smsCode} className="form__button form__button--small form__button--gold" type="submit" onClick={this.handleSmsVerificationClick}>
																	<Localized code="page.profile.button.verifyMobileNumber" />
																</button>
															</div>
														</div>
													</Fragment>
												)}
											</div>
											) : null}

											<div className="form__section">
												<h4 className="form__title">
													<Localized code="page.signup.subtitle.additionalInformation" />
												</h4>

												<div className="form__row">
													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="nameAr">
																<Localized code="page.signup.label.nameInArabic" />
															</label>
															<input disabled={true} pattern="[أ-ي ]+" className="form__input" type="text" dir="rtl" id="nameAr" value={nameAr} onChange={this.handleNameArabicChange} />
															<FormError errors={errors} field="nameAr" />
														</div>
													</div>

													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="nameEn">
																<Localized code="page.signup.label.nameInEnglish" />
															</label>
															<input disabled={true} pattern="[a-zA-Z ]+" dir="ltr" className="form__input" type="text" id="nameEn" value={nameEn} onChange={this.handleNameEnglishChange} />
															<FormError errors={errors} field="nameEn" />
														</div>
													</div>
												</div>
											</div>

											<div className="form__buttons">
												<button disabled={updateDisabled || loading} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleUpdateProfileClick}>
													{loading ? (
														<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
													) : null}
													<Localized code="page.profile.button.updateProfile" />
												</button>

												<Link to="/account" className="form__button form__button--small form__button--gray">
													<Localized code="page.profile.link.cancel" />
												</Link>
											</div>
										</Fragment>
									) : null}
								</form>
							</div>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Profile;
