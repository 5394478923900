import { decorate, observable, action, runInAction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import { reportExceptionToSentry } from '../utils/Utils';

class ContentStore {

	loading = false;
	content = new Map();

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	setLoading(loading) {
		this.loading = true;
	}

	async loadContent(...keys) {
		this.loading = true;

		for (const key of keys) {
			if (this.content.has(key)) {
				continue;
			}

			try {
				const response = await FazaaAPI.Content.get(key);

				runInAction(() => {
					this.content.set(key, response.data);
				});
			} catch (e) {
				reportExceptionToSentry(e, [404]);
				
				continue;
			}
		}

		this.loading = false;
	}

	getContent(key) {
		return this.content.get(key);
	}

	setContent(key, content) {
		this.content.set(key, content);
	}

	clearContent() {
		this.content.clear();
	}

}

decorate(ContentStore, {
	loading: observable,
	content: observable,
	setLoading: action,
	loadContent: action,
	setContent: action,
	clearContent: action
});

export default ContentStore;
