import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

// TODO Refactor to always return Route
const ProtectedRoute = inject('rootStore')(observer(
	class ProtectedRoute extends Component {

		render() {
			const { userStore, prepaidCardStore } = this.props.rootStore;
			const { authenticated } = userStore;
			const { secure } = prepaidCardStore;
			const { render, component, prepaidContext, ...rest } = this.props;

			if (!authenticated) {
				return <Redirect to="/account/signin" />
			}

			if (prepaidContext && !secure && rest.path !== '/account/prepaid-card') {
				return <Redirect to="/account/prepaid-card" />
			}

			// TODO add role checks and show HTTP403 component on fail

			if (component) {
				return <Route {...rest} component={component} />
			}

			if (render) {
				return <Route {...rest} render={props => render(props)} />
			}

			return <Redirect to="/" />
		}

	}
));

ProtectedRoute.defaultProps = {
	prepaidContext: false
};

export default ProtectedRoute;
