import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const PASSWORD_RULE_LOWERCASE = /(?=.*[a-z])/;
const PASSWORD_RULE_UPPERCASE = /(?=.*[A-Z])/;
const PASSWORD_RULE_NUMBER = /(?=.*[0-9])/;
const PASSWORD_RULE_SPECIAL = /(?=.*[^A-Za-z0-9])/;
const PASSWORD_RULE_LENGTH = /(?=.{8,})/;

const Password = inject('rootStore')(observer(
	class Password extends Component {

		constructor(props) {
			super(props);

			this.state = {
				passwordMismatch: false,
				passwordRuleLowercase: false,
				passwordRuleUppercase: false,
				passwordRuleNumber: false,
				passwordRuleSpecial: false,
				passwordRuleLength: false
			};

			this.handleChangePasswordClick = this.handleChangePasswordClick.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.password.title');
			});
		}

		componentDidMount() {
			const { userStore } = this.props.rootStore;

			userStore.setError(false);
			userStore.setSuccess(false);
			userStore.setOldSecret('');
			userStore.setSecret('');
			userStore.setSecretConfirm('');

			const uaePassFlowDataJson = localStorage.getItem('uaePassFlowData');

			// Prevent change password for UAE PASS signed in user
			if (uaePassFlowDataJson) {
				const uaePassFlowData = JSON.parse(uaePassFlowDataJson);

				if (uaePassFlowData && uaePassFlowData.status) {
					window.location = '/account';
				}
			}
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		handleOldSecretChange = e => this.props.rootStore.userStore.setOldSecret(e.target.value);

		handleSecretChange = e => {
			const { userStore } = this.props.rootStore;

			userStore.setSecret(e.target.value);

			this.setState({
				passwordRuleLowercase: PASSWORD_RULE_LOWERCASE.test(userStore.secret),
				passwordRuleUppercase: PASSWORD_RULE_UPPERCASE.test(userStore.secret),
				passwordRuleNumber: PASSWORD_RULE_NUMBER.test(userStore.secret),
				passwordRuleSpecial: PASSWORD_RULE_SPECIAL.test(userStore.secret),
				passwordRuleLength: PASSWORD_RULE_LENGTH.test(userStore.secret),
			});
		};

		handleSecretConfirmChange = e => {
			const { userStore } = this.props.rootStore;

			userStore.setSecretConfirm(e.target.value);

			this.setState({
				passwordMismatch: userStore.secret !== userStore.secretConfirm
			});
		};

		handleChangePasswordClick(e) {
			e.preventDefault();

			const { userStore } = this.props.rootStore;

			userStore.changePassword();
		}

		getPasswordRuleClass = rule => {
			return rule ? 'password-strength__rule--success' : '';
		};

		getPasswordRuleIcon = rule => {
			return rule ? faCheckCircle : faTimesCircle;
		};

		isPasswordStrong = () => {
			const { passwordRuleLowercase, passwordRuleUppercase, passwordRuleNumber, passwordRuleSpecial, passwordRuleLength } = this.state;

			return passwordRuleLowercase && passwordRuleUppercase && passwordRuleNumber && passwordRuleSpecial && passwordRuleLength;
		};

		render() {
			const { userStore } = this.props.rootStore;
			const { error, success, oldSecret, secret, secretConfirm, loading, isPasswordChangeNeeded } = userStore;
			const { passwordRuleLowercase, passwordRuleUppercase, passwordRuleNumber, passwordRuleSpecial, passwordRuleLength } = this.state;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.password.title" />
					</h2>

					<div className="section__wrapper">
						<div className="account-password">
							<div className="account-password__form">
								<form className="form">
									{!!success ? (
									<div className="form__section">
										<h4 className="form__title">
											<Localized code="page.password.title.success" />
										</h4>

										<p className="form__text">
											<Localized code="page.password.text.success" />
										</p>

										<p className="form__text">
											<Link to="/account" className="button button--gold">
												<Localized code="page.password.link.goBack" />
											</Link>
										</p>
									</div>
									) : null}

									{!success ? (
										<Fragment>
											{isPasswordChangeNeeded ? (
												<p className="form__text form__text--notice form__text--center">
													<Localized code="page.password.text.changePasswordRequired" />
												</p>
											) : null}

											<p className="form__text form__text--center">
												<Localized code="page.password.text.changePassword" />
											</p>

											{!!error ? (
											<div className="form__message form__message--error">{error}</div>
											) : null}

											<div className="form__row">
												<div className="form__column form__column--half">
													<div className="form__group">
														<label className="form__label" htmlFor="oldPassword">
															<Localized code="page.password.label.oldPassword" />
														</label>
														<input className="form__input" type="password" id="oldPassword" value={oldSecret} onChange={this.handleOldSecretChange} />
													</div>
												</div>
											</div>

											<div className="form__row">
												<div className="form__column">
													<div className="form__group">
														<label className="form__label" htmlFor="newPassword">
															<Localized code="page.password.label.newPassword" />
														</label>
														<input className="form__input" type="password" id="newPassword" value={secret} onChange={this.handleSecretChange} />
														<div className="password-strength">
															<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleLowercase)}`}>
																<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleLowercase)} />
																<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleLowercase" /></span>
															</div>
															<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleUppercase)}`}>
																<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleUppercase)} />
																<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleUppercase" /></span>
															</div>
															<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleNumber)}`}>
																<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleNumber)} />
																<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleNumber" /></span>
															</div>
															<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleSpecial)}`}>
																<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleSpecial)} />
																<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleSpecial" /></span>
															</div>
															<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleLength)}`}>
																<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleLength)} />
																<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleLength" /></span>
															</div>
														</div>
													</div>
												</div>

												<div className="form__column">
													<div className="form__group">
														<label className="form__label" htmlFor="newPasswordConfirm">
															<Localized code="page.password.label.newPasswordConfirm" />
														</label>
														<input className="form__input" type="password" id="newPasswordConfirm" value={secretConfirm} onChange={this.handleSecretConfirmChange} />
														{this.state.passwordMismatch && (
															<span className="form__field-error">
																<Localized code="page.password.text.passwordMismatch" />
															</span>
														)}
													</div>
												</div>
											</div>

											<div className="form__buttons">
												<button disabled={loading || !oldSecret || !secret || !secretConfirm || (secret !== secretConfirm) || !this.isPasswordStrong()} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleChangePasswordClick}>
													{loading ? (
														<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
													) : null}
													<Localized code="page.password.button.changePassword" />
												</button>
											</div>
										</Fragment>
									) : null}
								</form>
							</div>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Password;
