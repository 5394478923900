import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { chunk } from 'lodash';
import Config from '../../../config/Config';
import Localized from '../i18n/Localized';

const Categories = inject('rootStore')(observer(
	class Categories extends Component {

		constructor(props) {
			super(props);

			this.setCategoriesRef = this.setCategoriesRef.bind(this);
		}

		componentDidMount() {
			const { categoryStore } = this.props.rootStore;

			categoryStore.loadFeaturedCategories();
		}

		setCategoriesRef(element) {
			const { commonStore } = this.props.rootStore;

			commonStore.categoriesRef = element;
		}

		render() {
			const { locale } = this.props.rootStore.commonStore;
			const { loading } = this.props.rootStore.categoryStore;
			const categories = chunk(this.props.rootStore.categoryStore.categories, 5);

			return (
				!loading && categories.length > 0 ? (
				<section className="selection" ref={this.setCategoriesRef}>
					<div className="selection__items">
						{categories.map(row => (
							row.map(category => (
								<div key={category.id} className="selection__item">
									<Link to={`/search?language=${locale}&categories=${encodeURIComponent(category.localData[locale].name)}`} className="selection__link">
										<div className="selection__circle">
											<img className="selection__icon" src={`${Config.UPLOADS_ENDPOINT}${category.categoryImageUri}`} alt={category.localData[locale].name} title={category.localData[locale].name} />
										</div>
						
										<h4 className="selection__title">{category.localData[locale].name}</h4>
									</Link>
								</div>
							))
						))}
					</div>
				
					<div className="selection__buttons">
						<Link to="/categories" className="button button--white">
							<Localized code="home.button.viewAll" />
						</Link>
					</div>
				</section>
				) : null
			);
		}

	}
));

export default Categories;
