import React, { Component, Fragment } from 'react';
import { GoogleApiWrapper, Map, Marker, InfoWindow } from 'google-maps-react';
import Config from '../../../config/Config';

class GoogleMap extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showingInfoWindow: false,
			activeMarker: {},
			selectedPlace: {}
		}

		this.onMarkerClick = this.onMarkerClick.bind(this);
		this.onMapClick = this.onMapClick.bind(this);
	}

	/*
	componentWillUnmount() {
		delete window.google;
		window._scriptMap.delete('google');

		document.querySelectorAll('script').forEach(script => {
			if (script.src.startsWith('https://maps.googleapis.com/maps/api/js?key=')) {
				script.parentNode.removeChild(script);
			}
		});

	}*/

	onMarkerClick(props, marker, e) {
		this.setState({
			selectedPlace: props,
			activeMarker: marker,
			showingInfoWindow: true
		});
	}
	
	onMapClick(props) {
		if (this.state.showingInfoWindow) {
			this.setState({
				showingInfoWindow: false,
				activeMarker: null
			});
		}
	}

	render() {
		const { locations, google } = this.props;
		const points = locations.map(location => ({
			lat: location.location.y,
			lng: location.location.x
		}));
		const bounds = new google.maps.LatLngBounds();

		for (const point of points) {
			bounds.extend(point);
		}

		return (
			<Map
				google={google}
				zoom={Config.GOOGLE_MAPS_DEFAULT_ZOOM}
				onReady={(mapProps, map) => { 
					map.fitBounds(bounds)
					
					let listener = google.maps.event.addListener(map, 'idle', () => { 
						if (map.getZoom() > Config.GOOGLE_MAPS_DEFAULT_ZOOM) {
							map.setZoom(Config.GOOGLE_MAPS_DEFAULT_ZOOM);
						}

						google.maps.event.removeListener(listener); 
					});
				}}
				onClick={this.onMapClick}
			>
				{locations.map(location => (
					<Marker key={location.id}
						onClick={this.onMarkerClick}
						name={location.name}
						title={location.name}
						position={{lat: location.location.y, lng: location.location.x}}
						address={location.address}
						city={location.city}
					/>
				))}
				<InfoWindow
					marker={this.state.activeMarker}
          			visible={this.state.showingInfoWindow}
				>
					<p>
						<strong>{this.state.selectedPlace.title}</strong>
						<br />
						{this.state.selectedPlace.address}, {this.state.selectedPlace.city}
						{this.state.selectedPlace.position ? (
							<Fragment>
								<br />
								<a href={`https://maps.google.com/?daddr=${this.state.selectedPlace.position.lat},${this.state.selectedPlace.position.lng}`}>Navigate</a>
							</Fragment>
						) : null}
					</p>
				</InfoWindow>
			</Map>
		);
	}

}

export default GoogleApiWrapper((props) => ({
	apiKey: (Config.GOOGLE_MAPS_API_KEY),
	language: props.locale
}))(GoogleMap);
