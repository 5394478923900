import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import Localized from '../../base/i18n/Localized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const HTTP404 = inject('rootStore')(observer(
	class HTTP404 extends Component {

		constructor(props) {
			super(props);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.notFound.title');
			});
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		render() {
			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.notFound.title" />
					</h2>

					<div className="section__wrapper">
						<div className="error-page error-page--not-found">
							<p className="error-page__icon">
								<FontAwesomeIcon icon={faExclamationCircle} size="5x" />
							</p>

							<p className="error-page__text error-page__text--center">
								<Localized code="page.notFound.text" />
							</p>

							<p className="error-page__butons">
								<Link className="button button--gold" to="/">
									<Localized code="generic.link.goBack" />
								</Link>
							</p>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default HTTP404;
