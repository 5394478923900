import React, { PureComponent } from 'react';

class ProgressBar extends PureComponent {

	render() {
		const { value } = this.props;

		return (
			<div className="progress-bar">
				<div className="progress-bar__bar" style={{ width: value + "%"}}>
					<div className="progress-bar__text">
						{value}%
					</div>
				</div>
			</div>
		);
	}

}

export default ProgressBar;
