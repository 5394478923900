import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, Redirect } from 'react-router-dom';
import Header from './components/base/header/Header';
import Main from './components/base/main/Main';
import Footer from './components/base/footer/Footer';
import AppLoading from './components/base/loading/AppLoading';
import AppError from './components/base/error/AppError';
import { autorun } from 'mobx';
import CookieConsent from 'react-cookie-consent';
import Qs from 'qs';

// TODO Refactor to include stuff from index.js as well as use appReady from commonStore
const App = inject('rootStore')(withRouter(observer(
	class App extends Component {

		constructor(props) {
			super(props);

			const { userStore } = this.props.rootStore;

			this.mobxAutorunDisposer = autorun(() => {
				const { locale } = props.rootStore.commonStore;
				const { authenticated, isCategoryOnboardingDone, isProfileComplete, isPasswordChangeNeeded } = userStore;

				document.documentElement.lang = locale;
			
				if (locale === 'ar') {
					document.documentElement.dir = 'rtl';
					document.documentElement.classList.add('--rtl');
				} else {
					//document.documentElement.removeAttribute('dir');
					//document.documentElement.classList.remove('--rtl');
					//document.documentElement.removeAttribute('class');
					document.documentElement.dir = 'ltr';
					document.documentElement.classList.remove('--rtl');
				}

				if (authenticated && !isCategoryOnboardingDone && isCategoryOnboardingDone !== null && isProfileComplete && !isPasswordChangeNeeded) {
					// Disabled per request
					// userStore.showFavoriteCategories();
				}
			});

			// Intercept SSO params and store them
			// ssoReturnUrl
			const search = Qs.parse(this.props.location.search, {
				ignoreQueryPrefix: true
			});

			if (search.ssoReturnUrl) {
				const ssoReturnUrl = decodeURI(search.ssoReturnUrl);

				userStore.setRedirectUrl(search.ssoReturnUrl);
				
				// TODO Move this away from here
				if (ssoReturnUrl.includes('fazaastores.com')) {
					userStore.setRedirectRequiresProfile(true);
				}

				delete search.ssoReturnUrl;

				const query = Qs.stringify(search);

				this.props.history.replace(this.props.location.pathname + (query ? '?' + query : ''));
			}

			// Temporary workaround for redirecting to protected page after sign-in
			if (!userStore.authenticated && this.props.location.pathname === '/account/cancel') {
				userStore.setRedirectTo('/account/cancel');
			}
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		render() {
			const { commonStore, userStore } = this.props.rootStore;
			const { isAppReadyResolved, isAppReady, generalError, translateMessage } = commonStore;
			const { authenticated, isProfileComplete, isPasswordChangeNeeded, me } = userStore;
			const { pathname } = this.props.location;
			const showHamadChat = pathname === '/';

			if (authenticated) {
				if (isPasswordChangeNeeded && pathname !== '/account/password' && pathname !== '/account/signout') {
					return <Redirect to="/account/password" />
				}

				if (isProfileComplete === false && !isPasswordChangeNeeded && pathname !== '/account/profile' && pathname !== '/account/signout') {
					return <Redirect to="/account/profile" />
				}
			}

			if (!isAppReadyResolved) {
				return <AppLoading />
			}

			if (!isAppReady) {
				return <AppError error={generalError} />
			}

			if (authenticated && !me) {
				return <AppLoading />
			}

			return (
				<Fragment>
					<Header showHamadChat={showHamadChat} />
					<Main />
					<Footer />
					<CookieConsent
						buttonText={translateMessage('cookie.consent.button')}
						style={{ zIndex: 999999, background: "#002652", fontWeight: 300 }}
						buttonStyle={{ background: "#b48c24", padding: "10px 25px", fontSize: "16px", borderRadius: "25px", color: "#FFF", fontWeight: 300, textTransform: "uppercase" }}
					>
						{translateMessage('cookie.consent.text')}
					</CookieConsent>
				</Fragment>
			);
		}
	}
)));

export default App;
