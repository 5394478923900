import React, { Component, Fragment } from 'react';
import { inject } from 'mobx-react';
import Services from './services/Services';
import Offers from './offers/Offers';
import Vouchers from './vouchers/Vouchers';
import '@eredessil/react-id-swiper/src/styles/css/swiper.css';
import CategoriesSlider from '../base/categories/CategoriesSlider';

const Home = inject('rootStore')(
	class Home extends Component {

		componentDidMount() {
			const { rootStore } = this.props;
			const { pageTitlePrefix } = rootStore.commonStore;

			document.title = pageTitlePrefix;

			rootStore.loadHomeData();
		}

		render() {
			return (
				<Fragment>
					<CategoriesSlider />
					<Services />
					<Offers />
					<Vouchers />
				</Fragment>
			);
		}

	}
);

export default Home;
