import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import Localized from '../base/i18n/Localized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faThList } from '@fortawesome/free-solid-svg-icons';
import Product from '../base/estore/Product';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import HTTP404 from '../base/error/HTTP404';
import Skeleton from 'react-loading-skeleton';

const EstoreProduct = inject('rootStore')(observer(
	class EstoreProduct extends Component {

		constructor(props) {
			super(props);

			const { estoreStore } = props.rootStore;

			estoreStore.setLoading(true);
			estoreStore.setProduct(null);

			this.mobxAutorunDisposer = autorun(() => {
				const { commonStore, estoreStore } = props.rootStore;
				const { locale, pageTitlePrefix } = commonStore;
				const { product } = estoreStore;

				if (product) {
					document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + product.product.localData[locale].title;
				} else {
					document.title = pageTitlePrefix;
				}
			});
		}

		componentDidMount() {
			const { estoreStore } = this.props.rootStore;
			const { slug } = this.props.match.params;

			estoreStore.setStoreActive(true);
			estoreStore.loadProduct(slug);

			window.addEventListener('resize', this.handleWindowResize);
		}

		componentDidUpdate(prevProps) {
			const { estoreStore } = this.props.rootStore;
			const { slug } = this.props.match.params;

			if (slug !== prevProps.match.params.slug) {
				estoreStore.loadProduct(slug);
			}
		}

		componentWillUnmount() {
			const { estoreStore } = this.props.rootStore;

			estoreStore.setStoreActive(false);
			estoreStore.setProduct(null);

			this.mobxAutorunDisposer();

			window.removeEventListener('resize', this.handleWindowResize);
		}

		handleWindowResize = () => {
			const { estoreStore } = this.props.rootStore;

			if (window.innerWidth <= 650 && estoreStore.listingType !== 'grid') {
				estoreStore.setListingType('grid');
			}
		}

		handleListingTypeChange = type => {
			const { estoreStore } = this.props.rootStore;

			estoreStore.setListingType(type);
		}

		handleListingTypeChangeGrid = () => {
			this.handleListingTypeChange('grid');
		}

		handleListingTypeChangeList = () => {
			this.handleListingTypeChange('list');
		}

		render() {
			const { commonStore, estoreStore } = this.props.rootStore;
			const { listingType } = estoreStore;
			const { loading, product } = estoreStore;
			const { locale } = commonStore;

			return (
				!loading ? (
					product ? (
						<section className="section section--white">
							<div className="section__wrapper">
								<div className="estore-page estore-page--product">
									<div className="estore-page__product-view">
										<div className="estore-page__listing-header">
											<h3 className="estore-page__listing-title">
												<Localized code="estore.title.productInformation" />
											</h3>
										</div>

										<div className="estore-page__listing estore-page__listing--list">
											<div className="estore-page__listing-content">
												<Product locale={locale} display product={product.product} listingType={'list'} />
											</div>
										</div>
									</div>

									<div className={`estore-page__listing estore-page__listing--${listingType}`}>
										<div className="estore-page__listing-header">
											<h3 className="estore-page__listing-title">
												<Localized code="estore.title.similarProducts" />
											</h3>

											<div className="estore-page__listing-controls">
												<button className={`estore-page__listing-control-type${listingType === 'grid' ? ' estore-page__listing-control-type--active' : ''}`} onClick={this.handleListingTypeChangeGrid}>
													<FontAwesomeIcon className="estore-page__listing-control-type-icon" icon={faTh} />
												</button>

												<button className={`estore-page__listing-control-type${listingType === 'list' ? ' estore-page__listing-control-type--active' : ''}`} onClick={this.handleListingTypeChangeList}>
													<FontAwesomeIcon className="estore-page__listing-control-type-icon" icon={faThList} />
												</button>
											</div>
										</div>

										{product.similarProducts.content.length > 0 ? (
											<TransitionGroup className="estore-page__listing-content">
												{product.similarProducts.content.map((similarProduct, index) => {
													const delay = index * 50;

													return (
														<CSSTransition key={similarProduct.id} timeout={300 + delay} classNames="estore-page__product">
															<Product partial product={similarProduct} delay={delay} listingType={listingType} />
														</CSSTransition>
													);
												})}
											</TransitionGroup>
										) : null}

										{product.similarProducts.content.length === 0 && !loading ? (
											<div className="estore-page__message">
												<p className="estore-page__message-text estore-page__message-text--center">
													<Localized code="estore.search.text.noSimilarResults" />
												</p>
											</div>
										) : null}
									</div>
								</div>
							</div>
						</section>
					) : (
						<HTTP404 />
					)
				) : (
					<section className="section section--white">
						<h2 className="section__title section__title--center">
							<Skeleton width={300} />
						</h2>

						<div className="section__wrapper">
							<div className="cms-page">
								<div className="cms-generated">
									<h3>
										<Skeleton width={300} />
									</h3>

									<p>
										<Skeleton count={3} />
									</p>

									<p>
										<Skeleton count={3} />
									</p>
								</div>
							</div>
						</div>
					</section>
				)
			);
		}

	}
));

export default EstoreProduct;
