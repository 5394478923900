import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Swiper from '@eredessil/react-id-swiper';
import Config from '../../../config/Config';
import Localized from '../../base/i18n/Localized';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/free-solid-svg-icons';

import imgSliderIconArrowLeft from '../../../assets/img/slider-icon-arrow-left.png';
import imgSliderIconArrowRight from '../../../assets/img/slider-icon-arrow-right.png'

const Vouchers = inject('rootStore')(observer(
	class Vouchers extends Component {

		constructor(props) {
			super(props);

			this.goPrev = this.goPrev.bind(this);
			this.goNext = this.goNext.bind(this);
			
    		this.swiperRef = React.createRef();
		}

		goPrev() {
			if (document.documentElement.classList.contains('--rtl')) {
				this.swiperRef.current.swiper.slideNext();
			} else {
				this.swiperRef.current.swiper.slidePrev();
			}
		}

		goNext() {
			if (document.documentElement.classList.contains('--rtl')) {
				this.swiperRef.current.swiper.slidePrev();
			} else {
				this.swiperRef.current.swiper.slideNext();
			}
		}

		render() {
			const { locale } = this.props.rootStore.commonStore;
			const { loading, vouchers, loadingPackage, voucherPackages } = this.props.rootStore.voucherStore;
			const swiperParams = {
				slidesPerView: 'auto',
				spaceBetween: 10,
				autoplay: {
					delay: 5000
				}
			};
			const vouchersArray = Array.from(vouchers.values());
			const voucherPackagesArray = Array.from(voucherPackages.values()).map(vp => vp.voucherPackage);
			const vouchersMergedArray = vouchersArray.concat(voucherPackagesArray).sort((a, b) => b.sortWeight - a.sortWeight);

			return (
				!loading && !loadingPackage && (vouchers.size > 0 || voucherPackages.size > 0) ? (
				<section className="slider slider--vouchers slider--hover slider--white">
					<div className="slider__header">
						<h2 className="slider__title">
							<span className="slider__title-text">
								<Localized code="home.vouchers.title" />
							</span>
						</h2>

						<div className="slider__buttons">
							<Link to={`/search?language=${locale}&types=VOUCHER`} className="slider__button button button--gold button--small">
								<Localized code="home.button.viewMore" />
							</Link>
						</div>
					</div>

					<div className="slider__wrapper">
						<Swiper {...swiperParams} ref={this.swiperRef} rebuildOnUpdate>
							{vouchersMergedArray.map(item => (
								item.packageSlug ? (
									<div key={item.id} className="slider__slide">
										<div className="slider__item">
											<Link to={`/voucher-packages/view/${item.packageSlug}`} className="slider__link">
												<img className="slider__image" src={`${Config.UPLOADS_ENDPOINT}${item.packageImage}`} alt={item.localData[locale].name} />

												<div className="slider__meta">
													<div className="slider__info">
														<p className="slider__text">{item.localData[locale].name}</p>
													</div>

													<div className="slider__discount">{item.packagePrice} AED</div>
												</div>

												<div className="slider__icon-wrapper">
													<FontAwesomeIcon className="slider__icon" icon={faArchive} />
												</div>
											</Link>
										</div>
									</div>
								) : (
									<div key={item.id} className="slider__slide">
										<div className="slider__item">
											<Link to={`/vouchers/view/${item.voucherSlug}`} className="slider__link">
												<img className="slider__image" src={`${Config.UPLOADS_ENDPOINT}${item.voucherImageUri}`} alt={item.localData[locale].title} />

												<div className="slider__meta">
													<div className="slider__info">
														<h5 dir="ltr" className="slider__partner">{item.partner.partnerName}</h5>

														<p className="slider__text">{item.localData[locale].title}</p>
													</div>

													<div className="slider__discount">{item.voucherPrice} AED</div>
												</div>
											</Link>
										</div>
									</div>
								)
							))}
						</Swiper>

						<div className="slider__left" onClick={this.goPrev}>
							<img className="slider__arrow" src={imgSliderIconArrowLeft} alt="Slide left" />
						</div>

						<div className="slider__right" onClick={this.goNext}>
							<img className="slider__arrow" src={imgSliderIconArrowRight} alt="Slide right" />
						</div>
					</div>
				</section>
				) : null
			);
		}

	}
));

export default Vouchers;
