import React, { Component } from 'react';
import { union, without } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare, faCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons';

class FilterMultiSelect extends Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false
		};

		this.showDropdown = this.showDropdown.bind(this);
		this.hideDropdown = this.hideDropdown.bind(this);
		this.handleOptionClick = this.handleOptionClick.bind(this);
	}

	showDropdown() {
		this.setState({
			open: true
		}, () => {
			document.addEventListener('click', this.hideDropdown);
		});
	}

	hideDropdown() {
		this.setState({
			open: false
		}, () => {
			document.removeEventListener('click', this.hideDropdown);
		});
	}

	handleOptionClick(value) {
		const { selected, onChange, single } = this.props;

		if (single) {
			onChange(value);
		} else {
			if (selected.includes(value)) {
				onChange(without(selected, value));
			} else {
				onChange(union(selected, [value]));
			}
		}
	}

	render() {
		const { items, selected, placeholder, single, singleText } = this.props;
		const { open } = this.state;

		return (
			<div className={`filter-multiselect ${open ? 'filter-multiselect--open' : ''}`} onClick={this.showDropdown}>
				<div className="filter-multiselect__selected">
					{!!!selected || selected.length === 0 ? (
						<p className="filter-multiselect__placeholder">{placeholder}</p>
					) : null}
					{!!single ? (
						items.filter(item => singleText ? !!selected && selected === item.value : parseInt(selected) === item.value).map(item => (
							<div className="filter-multiselect__selected-item filter-multiselect__selected-item--single" key={item.value}>{item.label}</div>
						))
					) : (
						items.filter(item => selected.includes(item.value)).map(item => (
							<div className="filter-multiselect__selected-item" key={item.value}>{item.label}</div>
						))
					)}
				</div>
				<div className="filter-multiselect__options">
					{items/*sort((a, b) => {
						if (!!single) return 0;

						if (selected.includes(a.value) && !selected.includes(b.value)) {
							return -1;
						}

						if (selected.includes(b.value) && !selected.includes(a.value)) {
							return 1;
						}

						return 0;
					}).*/.map(item => (
						<div className={"filter-multiselect__option" + (item.heading ? " filter-multiselect__option--heading" : "")} key={item.value !== undefined ? item.value : 'undefined'} onClick={() => item.heading ? {} : this.handleOptionClick(item.value)}>
							<div className="filter-miltiselect__icon">
							{!!single ? (
								singleText ? (
									selected === item.value || (selected === undefined && item.value === undefined) ? (
										<FontAwesomeIcon icon={faDotCircle} />
									) : (
										<FontAwesomeIcon icon={faCircle} />
									)
								) : (
									parseInt(selected) === item.value || (selected === undefined && item.value === undefined) ? (
										<FontAwesomeIcon icon={faDotCircle} />
									) : (
										<FontAwesomeIcon icon={faCircle} />
									)
								)
							) : (
								selected.includes(item.value) ? (
									<FontAwesomeIcon icon={faCheckSquare} />
								) : (
									<FontAwesomeIcon icon={faSquare} />
								)
							)}
							</div>
							{item.label}
						</div>
					))}
				</div>
			</div>
		);
	}

}

export default FilterMultiSelect;
